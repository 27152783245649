/* eslint no-return-assign:0, react/no-danger:0 */
import React, { Component } from 'react';
import memoize from 'memoize-one';
import classNames from 'classnames';
import includes from 'lodash.includes';
import pick from 'lodash.pick';

import './styles.scss';

const omit = (object, excludedKeys) =>
  pick(
    object,
    Object.keys(object).filter((key) => !includes(excludedKeys, key))
  );

export class TextAreaHighlight extends Component {
  static defaultProps = {
    value: '',
    wrapIn: 'mark',
    highlight: () => [],
    onChange: () => {},
    onScroll: () => {},
  };

  handleInputChange = (event) => {
    const { onChange } = this.props;
    return onChange(event);
  };

  handleScroll = (event) => {
    const { onScroll } = this.props;
    const scrollTop = event.target.scrollTop;
    this.backdrop.scrollTop = scrollTop;
    return onScroll(event);
  };

  handleRegexHighlight = (input, payload) => {
    const OPEN_MARK = `<${this.props.wrapIn}>`;
    const CLOSE_MARK = `</${this.props.wrapIn}>`;
    return input.replace(payload, `${OPEN_MARK}$&${CLOSE_MARK}`);
  };

  handleArrayHighlight = (input, payload) => {
    let offset = 0;
    const wrapIn = this.props.wrapIn;
    const OPEN_MARK = `<${wrapIn}>`;
    const CLOSE_MARK = `</${wrapIn}>`;

    payload.forEach(function (element) {
      // insert open tag
      const open = element[0] + offset;

      if (element[2]) {
        const OPEN_MARK_WITH_CLASS = `<${wrapIn} class=${element[2]}>`;
        // eslint-disable-next-line no-param-reassign
        input = input.slice(0, open) + OPEN_MARK_WITH_CLASS + input.slice(open);
        offset += OPEN_MARK_WITH_CLASS.length;
      } else {
        // eslint-disable-next-line no-param-reassign
        input = input.slice(0, open) + OPEN_MARK + input.slice(open);
        offset += OPEN_MARK.length;
      }

      // insert close tag
      const close = element[1] + offset;

      // eslint-disable-next-line no-param-reassign
      input = input.slice(0, close) + CLOSE_MARK + input.slice(close);
      offset += CLOSE_MARK.length;
    }, this);
    return input;
  };

  getHighlights = memoize((value) => {
    const CLOSE_MARK = `</${this.props.wrapIn}>`;

    // escape HTML
    let highlightedMarkup = value
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    const payload = this.props.highlight(highlightedMarkup);

    if (payload) {
      switch (payload.constructor.name) {
        case 'Array':
          highlightedMarkup = this.handleArrayHighlight(highlightedMarkup, payload);
          break;
        case 'RegExp':
          highlightedMarkup = this.handleRegexHighlight(highlightedMarkup, payload);
          break;
        default:
          throw new TypeError('props.highlight did not return RegExp or Array');
      }
    }

    // this keeps scrolling aligned when input ends with a newline
    highlightedMarkup = highlightedMarkup.replace(new RegExp(`\\n(${CLOSE_MARK})?$`), '\n\n$1');

    return highlightedMarkup;
  });

  render() {
    const { value } = this.props;
    const highlightedMarkup = this.getHighlights(value || '');

    const className = classNames(this.props.className, 'text-area-highligh', {
      'text-area-highligh--has-error': this.props.hasError,
    });

    return (
      <div className={className}>
        <div
          className="text-area-highligh__backdrop"
          ref={(backdrop) => (this.backdrop = backdrop)}
        >
          <div
            className="text-area-highligh__highlights text-area-highligh__content"
            dangerouslySetInnerHTML={{ __html: highlightedMarkup }}
          />
        </div>
        <textarea
          className="text-area-highligh__textarea text-area-highligh__content form-control--rounded"
          data-gramm
          ref={(textarea) => (this.textarea = textarea)}
          {...omit(this.props, ['highlight', 'wrapIn', 'value', 'className', 'hasError'])}
          value={value || ''}
          onChange={this.handleInputChange}
          onScroll={this.handleScroll}
        />
      </div>
    );
  }
}
