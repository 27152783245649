import { lensProp, lensPath } from 'ramda';

const getStepLensPath = (path = []) => (nodeId) => {
  return lensPath(['steps', nodeId, ...path]);
};

const getBgStructure = (path = [], includePoster) => {
  const bg = {
    root: getStepLensPath([...path, 'background']),
    name: getStepLensPath([...path, 'background', 'name']),
    url: getStepLensPath([...path, 'background', 'url']),
    unsplashFile: getStepLensPath([...path, 'background', 'unsplashFile']),
    contentType: getStepLensPath([...path, 'background', 'contentType']),
    verticalPosition: getStepLensPath([...path, 'background', 'verticalPosition']),
    horizontalPosition: getStepLensPath([...path, 'background', 'horizontalPosition']),
    containerFit: getStepLensPath([...path, 'background', 'containerFit']),
  };

  if (includePoster) {
    bg.poster = {
      root: getStepLensPath([...path, 'background', 'poster']),
      name: getStepLensPath([...path, 'background', 'poster', 'name']),
      url: getStepLensPath([...path, 'background', 'poster', 'url']),
      unsplashFile: getStepLensPath([...path, 'background', 'poster', 'unsplashFile']),
    };
  }
  return bg;
};

const getBgStructureWithPoster = (path = []) => {
  const bg = getBgStructure(path);
  return {
    ...bg,
    poster: {
      root: getStepLensPath([...path, 'background', 'poster']),
      name: getStepLensPath([...path, 'background', 'poster', 'name']),
      url: getStepLensPath([...path, 'background', 'poster', 'url']),
      unsplashFile: getStepLensPath([...path, 'background', 'poster', 'unsplashFile']),
    },
  };
};

const getOverlayStructure = (path = []) => {
  return {
    color: getStepLensPath([...path, 'overlay', 'color']),
    opacity: getStepLensPath([...path, 'overlay', 'opacity']),
  };
};

const getButtonStructure = (path = []) => {
  return {
    text: getStepLensPath([...path, 'text']),
    url: getStepLensPath([...path, 'url']),
    isExternal: getStepLensPath([...path, 'isExternal']),
  };
};
const getChartRawStructure = (path = []) => {
  return {
    nodes: lensPath([...path, 'nodes']),
    nodesPorts: (nodeId) => {
      return lensPath([...path, 'nodes', nodeId, 'ports']);
    },
    links: () => lensPath([...path, 'links']),
  };
};

export default {
  id: lensProp('id'),
  userId: lensProp('userId'),
  userEmail: lensProp('userEmail'),
  name: lensProp('name'),
  dateCreated: lensProp('dateCreated'),
  dateUpdated: lensProp('dateUpdated'),
  datePublished: lensProp('datePublished'),
  dateDeleted: lensProp('dateDeleted'),
  // Used to enable "preview" button
  // Internal only (does not go to DB)
  isSaved: lensProp('_isSaved'),
  fontFamily: lensProp('fontFamily'),
  chartRaw: lensProp('chartRaw'),
  chartRawDetails: getChartRawStructure(['chartRaw']),
  dynamicTemplateId: lensProp('dynamicTemplateId'),
  btn: {
    borderRadius: lensPath(['button', 'borderRadius']),
    colorPrimary: lensPath(['button', 'colorPrimary']),
    colorSecondary: lensPath(['button', 'colorSecondary']),
  },
  meta: {
    image: {
      root: lensPath(['meta', 'image']),
      name: lensPath(['meta', 'image', 'name']),
      url: lensPath(['meta', 'image', 'url']),
      unsplashFile: lensPath(['meta', 'image', 'unsplashFile']),
    },
    description: lensPath(['meta', 'description']),
    pageName: lensPath(['meta', 'pageName']),
  },

  steps: {
    welcome: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      btnText: getStepLensPath(['btnText']),
      logo: {
        name: getStepLensPath(['logo', 'name']),
        url: getStepLensPath(['logo', 'url']),
        alt: getStepLensPath(['logo', 'alt']),
        unsplashFile: getStepLensPath(['logo', 'unsplashFile']),
      },
      logoSize: getStepLensPath(['logoSize']),
    },
    schedule: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      btnText: getStepLensPath(['btnText']),
      submissionEmails: getStepLensPath(['submissionsEmail']),
      workingHours: getStepLensPath(['workingHours']),
    },

    optionPicker1: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      btnPrimary: getButtonStructure(['btnPrimary']),
      btnSecondary: getButtonStructure(['btnSecondary']),
    },

    optionPicker2: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      btnPrimary: getButtonStructure(['btnPrimary']),
      btnSecondary: getButtonStructure(['btnSecondary']),
    },
    splashVideo: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([], true),
      overlay: getOverlayStructure([]),
    },

    contactForm: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      comment: {
        isEnabled: getStepLensPath(['comment', 'isEnabled']),
        defaultText: getStepLensPath(['comment', 'defaultText']),
      },
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      contactListId: getStepLensPath(['contactListId']),
      btnText: getStepLensPath(['btnText']),
      submissionEmails: getStepLensPath(['submissionEmails']),
    },

    store: {
      type: getStepLensPath(['type']),
      iStore: getStepLensPath(['iStore']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),

      storeName: getStepLensPath(['storeName']),
      storeNameTextColor: getStepLensPath(['storeNameTextColor']),
      headerBgColor: getStepLensPath(['headerBgColor']),
      columnsNumber: getStepLensPath(['columnsNumber']),
      rowsNumber: getStepLensPath(['rowsNumber']),
      detailsBtnColor: getStepLensPath(['detailsBtnColor']),
      logo: {
        name: getStepLensPath(['logo', 'name']),
        url: getStepLensPath(['logo', 'url']),
        alt: getStepLensPath(['logo', 'alt']),
        unsplashFile: getStepLensPath(['logo', 'unsplashFile']),
      },
      logoSize: getStepLensPath(['logoSize']),
      logoPosition: getStepLensPath(['logoPosition']),
      storeNameSize: getStepLensPath(['storeNameSize']),
    },

    thankYou: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      bodyText: getStepLensPath(['bodyText']),
    },
    sTable: {
      type: getStepLensPath(['type']),
      bg: getBgStructureWithPoster([]),
      overlay: getOverlayStructure([]),
      textColor: getStepLensPath(['textColor']),
      headlineText: getStepLensPath(['headlineText']),
      bodyText: getStepLensPath(['bodyText']),
      options: getStepLensPath(['options']),
      btnPrimaryText: getStepLensPath(['btnPrimaryText']),
      btnSecondaryText: getStepLensPath(['btnSecondaryText']),
    },
  },
};
