import React from 'react';
import classNames from 'classnames';
import { SignedInUserContext } from 'utils/context';
import { getUserId } from 'utils/user';
import Modal from '../Modal';
import Button from '../Button';
import { ExistingMedia } from './ExistingMedia';
import { UploadMedia } from './UploadMedia';
import { StockImages } from './StockImages';
import { PredefinedCollections } from './PredefinedCollections';
import './styles.scss';

const TABS = {
  PREDEFINED: 0,
  STOCK: 1,
  UPLOAD: 2,
  EXISTING: 3,
};

class MediaModal extends React.Component {
  state = {
    openedTabId: TABS.PREDEFINED,
  };

  render() {
    const { openedTabId } = this.state;

    const btnPredefinedClassName = classNames('margin-right--sm', {
      'btn-grey': openedTabId === TABS.PREDEFINED,
    });

    const btnStockClassName = classNames('margin-right--sm', {
      'btn-grey': openedTabId === TABS.STOCK,
    });

    const btnUploadClassName = classNames('margin-right--sm', {
      'btn-grey': openedTabId === TABS.UPLOAD,
    });

    const btnExistingClassName = classNames({
      'btn-grey': openedTabId === TABS.EXISTING,
    });

    return (
      <Modal onClose={this.props.onClose}>
        <Modal.Header>
          <Button
            className={btnPredefinedClassName}
            disabled={openedTabId === TABS.PREDEFINED}
            type="button"
            onClick={() => this.setState({ openedTabId: TABS.PREDEFINED })}
          >
            Predefined Collections
          </Button>
          <Button
            className={btnStockClassName}
            disabled={openedTabId === TABS.STOCK}
            type="button"
            onClick={() => this.setState({ openedTabId: TABS.STOCK })}
          >
            Stock Images
          </Button>
          <Button
            className={btnUploadClassName}
            disabled={openedTabId === TABS.UPLOAD}
            type="button"
            onClick={() => this.setState({ openedTabId: TABS.UPLOAD })}
          >
            Upload New Media
          </Button>
          <Button
            className={btnExistingClassName}
            disabled={openedTabId === TABS.EXISTING}
            type="button"
            onClick={() => this.setState({ openedTabId: TABS.EXISTING })}
          >
            Use Existing Media
          </Button>
        </Modal.Header>

        <>
          {openedTabId === TABS.PREDEFINED && (
            <PredefinedCollections
              userId={this.props.userId}
              accept={this.props.accept}
              onSave={this.props.onSave}
              onCancel={this.props.onClose}
            />
          )}

          {openedTabId === TABS.STOCK && (
            <StockImages
              userId={this.props.userId}
              accept={this.props.accept}
              onSave={this.props.onSave}
              onCancel={this.props.onClose}
            />
          )}

          {openedTabId === TABS.UPLOAD && (
            <UploadMedia
              userId={this.props.userId}
              accept={this.props.accept}
              onSave={this.props.onSave}
              onError={this.props.onError}
              onCancel={this.props.onClose}
            />
          )}

          {openedTabId === TABS.EXISTING && (
            <ExistingMedia
              userId={this.props.userId}
              accept={this.props.accept}
              onSave={this.props.onSave}
              onCancel={this.props.onClose}
            />
          )}
        </>
      </Modal>
    );
  }
}

export default (props) => (
  <SignedInUserContext.Consumer>
    {(user) => <MediaModal {...props} userId={getUserId(user)} />}
  </SignedInUserContext.Consumer>
);
