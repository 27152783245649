import './styles.scss';
import React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { Button } from 'components/_common';

export const IconButton = ({ iconType, value, isSelected, onSelect }:
  {
    iconType: IconName;
    value: string;
    isSelected?: boolean;
    onSelect?: (value: string) => void;
  }) => {

  return (
    <Button
      className="icon-button"
      type="button"
      onClick={() => onSelect && onSelect(value)}
    >
      <Icon
        className={isSelected ? 'color-primary' : 'color-clickable'}
        icon={iconType}
      />
    </Button>
  )
}
