import _isEmpty from 'lodash.isempty';
import _pick from 'lodash.pick';

export function normalizeChart(chartRaw) {
  if (!chartRaw || _isEmpty(chartRaw)) {
    return chartRaw;
  }

  // `offset` and `nodes` are specific
  // as they have a circular structure inside
  // so it breaks DynamoDB and JSON.stringify :/
  const chart = _pick(chartRaw, [
    'links',
    'selected',
    'hovered',
    // 'offset',
    // 'nodes'
  ]);
  const nodes = Object.values(chartRaw.nodes || {});

  chart.offset = {
    x: 0,
    y: 0,
  };

  chart.nodes = {};

  nodes.forEach((node) => {
    const { id, position } = node;

    // TODO: use chartLens here
    chart.nodes[id] = {
      ...node,
      position: {
        x: position.x,
        y: position.y,
      },
    };
  });

  return chart;
}
