import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getStoreListByIUser, createStore, deleteStore, unpublishStore } from 'services/v2/store';
import { THookCallback, IStore } from 'models';

export const useStores = () => {
  const isDetached = useRef(false);
  const iUser = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState<IStore[]>([]);
  const dispatch = useDispatch();

  const stopLoading = useCallback(() => {
    if (isDetached.current) return;
    setIsLoading(false);
  }, []);

  const onErrorHandler = useCallback((error) => {
    console.error('[useStores]', error);
    if (isDetached.current) return;

    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [dispatch]);

  const loadStores = useCallback(async () => {
    setIsLoading(true);
    getStoreListByIUser(iUser)
      .then((list) => {
        if (isDetached.current) return null;
        setStores(list);
      })
      .catch(onErrorHandler)
      .finally(stopLoading);
  }, [iUser, stopLoading, onErrorHandler]);

  const getStoreById = useCallback(
    (iStore: IStore['iStore']) => {
      return stores.find((st) => st.iStore === +iStore);
    }, [stores]
  );

  const createStoreHandler = useCallback(
    (store: Partial<IStore>& { name: IStore["name"]},
      onError?: THookCallback,
      onSuccess?: (result: IStore) => void,
    ) => {
      setIsLoading(true);
      createStore(iUser, store)
        .then(async (result: IStore) => {
          if (isDetached.current) return null;

          await loadStores();

          onSuccess && onSuccess(result);
        })
        .catch((error) => {
          onErrorHandler(error);
          onError && onError(error);
        });
    },
    [iUser, loadStores, onErrorHandler]
  );

  const deleteStoreHandler = useCallback(
    (iStore: IStore['iStore'], onError?: THookCallback, onSuccess?: THookCallback) => {
      setIsLoading(true);
      deleteStore(iUser, iStore)
        .then(() => {
          if (isDetached.current) return null;

          loadStores();
          onSuccess && onSuccess();
        })
        .catch((error) => {
          onErrorHandler(error);
          onError && onError(error);
        });
    },
    [iUser, loadStores, onErrorHandler]
  );

  const unpublishStoreHandler = useCallback(
    (iStore: IStore['iStore'], onError?: THookCallback, onSuccess?: THookCallback) => {
      setIsLoading(true);
      unpublishStore(iUser, iStore)
        .then(() => {
          if (isDetached.current) return null;

          loadStores();
          onSuccess && onSuccess();
        })
        .catch((error) => {
          onErrorHandler(error);
          onError && onError(error);
        });
    },
    [iUser, loadStores, onErrorHandler]
  );

  useEffect(() => {
    if (!isDetached.current) {
      loadStores();
    }


    return () => {
      isDetached.current = true;
    };
  }, [iUser, loadStores]);

  return {
    stores,
    isLoading,
    loadStores,
    getStoreById: getStoreById,
    createStore: createStoreHandler,
    deleteStore: deleteStoreHandler,
    unpublishStore: unpublishStoreHandler,
  };
};
