import React from 'react';
import { Spinner } from '../Spinner';

export function Section({ isLoading, children }) {
  if (isLoading) {
    return <Spinner />;
  }

  return children;
}
