export const TEMPLATE_TYPE = {
  CUSTOM: 'CUSTOM',
  PREDEFINED: 'PREDEFINED',
};

export const UNLAYER = {
  PROJECT_ID: '2591',
  TEMPLATE: {
    BLANK: '7028',
    SIMPLE_CTA: '7023',
    BASIC_TEXT: '7022',
  },
  TEMPLATE_TYPE,
};
