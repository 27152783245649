import './styles.scss';

import React, { useState, useCallback } from 'react';
import Label from '../Label';
import Input from '../Input';
import Textarea from '../Textarea';
import Button from '../Button';
import { ProductConfigurationRow } from './ProductConfigurationRow';
import { IProduct } from 'models/Products/IProduct';
import { TProductConfiguration } from 'models/Products/TProductConfigurations';
import { ProductConfigurationTypeEnum } from 'models/Products/ProductConfigurationsTypeEnum';
import TemplateWithOverlay from 'components/Templates/TemplateWithOverlay';
import { ProductImages } from './ProductImages';
import { IMedia, IProductImage } from 'models';
import { useUserId } from 'hooks';
import { TagsInput } from './TagsInput';

export const ProductBuilder = ({
  initialProduct,
  onSave,
  onCancel,
}: {
  initialProduct?: IProduct;
  onSave: (prd: IProduct) => void;
  onCancel: () => void;
}) => {
  const iUser = useUserId();

  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: 0,
    ...initialProduct,
  });

  const [tags, setTags] = useState(initialProduct?.tags || []);
  const [configurations, setConfigurations] = useState<TProductConfiguration[]>(
    initialProduct?.configurations?.length > 0
      ? initialProduct.configurations
      : [{
        type: ProductConfigurationTypeEnum.TEXT,
      } as TProductConfiguration]
  );


  const updateProductInfo = useCallback((index: number, info: Partial<TProductConfiguration>) => {
    configurations[index] = {
      ...configurations[index],
      ...info,
    };

    setConfigurations([...configurations]);
  }, [configurations]);

  const onDeleteProductConfiguration = useCallback((index: number) => {
    configurations.splice(index, 1);
    setConfigurations([...configurations]);
  }, [configurations]);

  const addProductConfiguration = useCallback(() => {
    const newProductInfos = [...configurations];
    newProductInfos.push({ type: ProductConfigurationTypeEnum.TEXT } as TProductConfiguration);
    setConfigurations(newProductInfos);
  }, [configurations]);

  const onSaveProduct = useCallback(() => onSave({
    ...product,
    configurations,
    tags,
  }), [configurations, product, tags, onSave]);

  const onAddImage = useCallback((file: IMedia) => {
    const images = product.images || [];
    let prdImage: IProductImage;

    if (file?.id) {
      prdImage = {
        iMedia: file.id,
        iProduct: product.iProduct,
        isMain: false,
        imageUrl: file.url,
      }
    } else {
      prdImage = {
        iMedia: null,
        iProduct: product.iProduct,
        isMain: false,
        imageUrl: file.url,
      }
    }

    setProduct({
      ...product,
      configurations,
      tags,
      images: [...images, prdImage]
    })
  }, [configurations, product, tags]);

  const onDeleteImage = useCallback((_image: IProductImage, indx: number) => {
    const images = [...product.images];
    images.splice(indx, 1);

    setProduct({
      ...product,
      images,
    });
  }, [product]);


  const productImages = Array
    .from(new Array(8))
    .map((_, i) => product && product.images ? product.images[i] : {})
    .map((img: any, indx) => {
      return {
        ...img,
        uuid: `${img?.imageUrl}_${indx}`,
      };
    });

  return (
    <TemplateWithOverlay
      head="Product Details"
    >
      <div className="product-builder__body">
        <div className="product-builder__column--left">
          <div>
            <Label htmlFor="name">Product Name</Label>
            <Input
              id="name"
              required
              value={product.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setProduct({ ...product, name: e.target.value }) }}
            />
          </div>

          <div>
            <Label htmlFor="description">Details</Label>
            <Textarea
              id="description"
              required
              row={9}
              value={product.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setProduct({ ...product, description: e.target.value }) }}
            />
          </div>

          <div className="form-cell__wrapper">
            <Label htmlFor="price">Price</Label>
            <Input
              type="number"
              id="price"
              required
              className="product-builder__price-input"
              min="0"
              value={product.price}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setProduct({ ...product, price: +e.target.value }) }}
            />
          </div>

          <div>
            <Label>Tags</Label>
            <TagsInput
              iUser={iUser}
              initialTags={tags}
              updateTags={(tags: any) => { setTags(tags) }} />
          </div>

          <div className="product-builder__description margin-top--lg">
            {configurations.map((info, index) => (
              <ProductConfigurationRow
                key={'config_row_' + index}
                index={index}
                productConfiguration={info}
                onDeleteProductConfiguration={() => onDeleteProductConfiguration(index)}
                updateProductConfiguration={(value) => updateProductInfo(index, value)}
              />
            ))}

            <div className="margin-left--sm margin-bottom--sm">
              <Button
                isPrimary
                className="btn-upload-media btn-upload-media--icon-only product-builder__add-btn"
                onClick={addProductConfiguration} />
            </div>
          </div>

          <div className="margin-top--lg padding--xs">
            <Button isPrimary
              className="margin-right--sm"
              onClick={onSaveProduct}>
              Save
            </Button>

            <Button
              type="button"
              className="btn-grey margin-right--sm"
              onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>

        <ProductImages
          className="product-builder__column--right"
          productImages={productImages}
          onAddImage={onAddImage}
          onDelete={onDeleteImage}
        />
      </div>
    </TemplateWithOverlay>
  );
}