import { IUser } from 'models';
// TODO: check, could be unused code
export function getUserEmail(user: IUser) {
  return user?.attributes?.email;
}

export function getUserId(user: IUser) {
  return user?.attributes?.sub;
}

export function getUserUsername(user: IUser) {
  return user?.username;
}

export function getUserStripeCustomerId(user: IUser) {
  return user?.customAttributes?.stripeCustomerId;
}

export function getUserSubscriptionStatus(user: IUser) {
  return user?.customAttributes?.subscriptionStatus;
}
