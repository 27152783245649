import React from 'react';

export const Body = ({
  data = [],
  editingId = null,
  rowRender = (item, index) => {},
  rowEditor = (item, index) => {},
}) => {
  return (
    <tbody>
      {Array.isArray(data) &&
        data.map((item, index) => {
          if (editingId !== undefined && item && editingId === item.id && rowEditor) {
            return rowEditor(item, index);
          }
          return item && rowRender(item, index);
        })}
    </tbody>
  );
};
