import React from 'react';
import { Classes, Dialog as CoreDialog } from '@blueprintjs/core';
import './styles.scss';

// https://blueprintjs.com/docs/#core/components/dialog.props
export function Dialog({ children, footer, ...restProps }) {
  return (
    <CoreDialog portalClassName="dialog-portal" {...restProps}>
      <div className={Classes.DIALOG_BODY}>{children}</div>

      {footer ? (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>{footer}</div>
        </div>
      ) : null}
    </CoreDialog>
  );
}
