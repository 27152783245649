import { USAGE_SERVICES } from "./campaign";

export const FONT_FAMILY = "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif";
export const COLOR_PRIMARY = '#2699fb';
export const COLOR_SECONDARY = '#41cc8f';
export const COLOR_ERROR = '#721c24';
export const COLOR_GREY = '#969696';
export const SPACER = '15px';
export const SPACER_SM = '7.5px';


export const USAGE_COLORS = {
  [USAGE_SERVICES.story]: {
    ACTIVE: COLOR_PRIMARY,
    DIMMED: '#D9DCDF',
  },
  [USAGE_SERVICES.sms]: {
    ACTIVE: '#ffbb28',
    DIMMED: '#D9DCDF',
  },
  [USAGE_SERVICES.email]: {
    ACTIVE: COLOR_SECONDARY,
    DIMMED: '#D9DCDF',
  },
};
