import Unsplash, { toJson } from 'unsplash-js';

const DEFAULT_COLLECTION = '9805391';
const DEFAULT_ORDER = 'popular';
const USER_NAME = 'panelsai';

const unsplash = new Unsplash({ accessKey: '_evOm2H00SInbDqn_wczZmIfR52AF8fz0PGzDicf9Vs' });

export const getAllCollections = () => {
  return unsplash.users.collections(USER_NAME, 1, 25, 'updated').then(toJson);
};

export const getStockImagesByCollectionId = ({
  selectedCollectionId = DEFAULT_COLLECTION,
  page = 0,
  limit = 20,
}) => {
  // upslash counts pages from 1;
  return unsplash.collections
    .getCollectionPhotos(selectedCollectionId, page + 1, limit, DEFAULT_ORDER)
    .then(toJson);
};

export const getStockImagesByKeyword = ({ keyword = '', page = 0, limit = 20 }) => {
  // upslash counts pages from 1;
  return unsplash.search.photos(keyword, page + 1, limit, DEFAULT_ORDER).then(toJson);
};

export const triggerDownload = (file) => {
  unsplash.photos.downloadPhoto(file);
};
