import { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useUserId } from './useUserId';
import { ISorting, IProduct } from 'models';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getProductList, deleteProduct } from 'services/products';

export const useUserProducts = ({
  initPage = 0,
  initPageSize = 10,
  initSortingKey = '',
}: {
  initPage: number;
  initPageSize: number;
  initSortingKey?: string;
}) => {
  const isDetached = useRef(false);
  const dispatch = useDispatch();
  const iUser = useUserId();
  const [pageNumber, setPageNumber] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [sortingOptions, setSortingOptions] = useState<ISorting>({
    direction: 'asc',
    sortingKey: initSortingKey,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<IProduct[]>(null);
  const [total, setTotal] = useState<number>(null);

  const loadProducts = useCallback(async () => {
    if (isDetached.current) return;

    setIsLoading(true);

    try {
      const result = await getProductList({
        iUser: iUser,
        sortingKey: sortingOptions.sortingKey,
        direction: sortingOptions.direction,
        pageNumber,
        pageSize,
      });

      setProducts(result.items);
      setTotal(result.total);
    }
    catch(e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    }
    finally {
      setIsLoading(false);
    }
  }, [iUser, pageSize, pageNumber, sortingOptions, dispatch]);

  useEffect(() => {
    loadProducts();
  }, [iUser, pageSize, pageNumber, sortingOptions, loadProducts]);

  const removeProduct = useCallback(async (iProduct: number) => {
    setIsLoading(true);

    try {
      await deleteProduct({ iProduct, iUser });
      await loadProducts();
    }
    catch (e) {
      console.log(e);
      dispatch(addNotification({
        type: 'error',
        message: e as string,
      }));
    }
    finally {
      setIsLoading(false);
    }

  }, [iUser, dispatch, loadProducts]);

  return {
    isLoading,
    products,
    total,
    sortingOptions,
    pageNumber,
    pageSize,
    setPageSize,
    setPageNumber,
    setSortingOptions,
    removeProduct,
  };
}