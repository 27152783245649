import { useCallback, useEffect, useState, useRef } from 'react';
import { getCampaignById } from 'services/campaign';
import { getStoryById } from 'services/story';
import { useUserId } from './useUserId';

export function useCampaign(campaignId) {
  const isDetached = useRef(false);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [story, setStory] = useState(null);

  const loadCampaignAndStory = useCallback(async () => {
    setIsLoading(true);
    getCampaignById(campaignId)
      .then((campaign) => {
        if (isDetached.current) return;

        setCampaign(campaign);
        return getStoryById(campaign.storyId, userId);
      })
      .then((story) => {
        if (isDetached.current) return;
        setStory(story);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        if (isDetached.current) return;
        setIsLoading(false);
      });
  }, [campaignId, userId]);

  useEffect(() => {
    loadCampaignAndStory();

    return () => {
      isDetached.current = true;
    };
  }, [campaignId, loadCampaignAndStory]);

  return {
    campaign,
    isLoading,
    story,
    loadCampaignAndStory,
  };
}
