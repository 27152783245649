export const CREATE_ROUTE_ROOT = '/story';
export const SENDER_ROUTE_ROOT = '/sender';
export const CAMPAIGN_ROUTE_ROOT = '/campaign';
export const CONTACTS_ROUTE_ROOT = '/contacts';
export const REPORTS_ROUTE_ROOT = '/reports';
export const CAMPAIGN_EDIT_ROUTE_ROOT = '/campaign/edit';

export const ROUTES = {
  // create flow
  HOME: `${CREATE_ROUTE_ROOT}/list`,
  PICK_FONT: `${CREATE_ROUTE_ROOT}/designs`,
  FLOW_BUILDER: `${CREATE_ROUTE_ROOT}/flow-builder`,
  CHOOSE_TEMPLATE: `${CREATE_ROUTE_ROOT}/choose-template`,
  STEP_WELCOME: `${CREATE_ROUTE_ROOT}/step-welcome`,
  STEP_SPLASH_VIDEO: `${CREATE_ROUTE_ROOT}/step-splash-video`,
  STEP_OPTION_PICKER_1: `${CREATE_ROUTE_ROOT}/step-option-picker-1`,
  STEP_OPTION_PICKER_2: `${CREATE_ROUTE_ROOT}/step-option-picker-2`,
  STEP_CONTACT_FORM: `${CREATE_ROUTE_ROOT}/step-contact-form`,
  STEP_SCHEDULE: `${CREATE_ROUTE_ROOT}/step-schedule`,
  STEP_THANK_YOU: `${CREATE_ROUTE_ROOT}/step-thank-you`,
  STEP_OPTION_SELECTOR: `${CREATE_ROUTE_ROOT}/step-option-selector`,
  STEP_STORE: `${CREATE_ROUTE_ROOT}/step-store`,
  META_BUILDER: `${CREATE_ROUTE_ROOT}/meta-builder`,

  // campaign flow
  CAMPAIGN_CHANNEL: `${CAMPAIGN_ROUTE_ROOT}/:storyId`,
  CAMPAIGN_SCHEDULE: `${CAMPAIGN_ROUTE_ROOT}/:storyId/schedule`,
  CAMPAIGN_SCHEDULE_DELIVERY: `${CAMPAIGN_ROUTE_ROOT}/:storyId/delivery`,
  CAMPAIGN_DONE: `${CAMPAIGN_ROUTE_ROOT}/:storyId/done`,
  CAMPAIGN_SMS: `${CAMPAIGN_ROUTE_ROOT}/:storyId/sms`,
  CAMPAIGN_EMAIL_TEMPLATE: `${CAMPAIGN_ROUTE_ROOT}/:storyId/email-templates`,
  CAMPAIGN_EMAIL_BUILDER: `${CAMPAIGN_ROUTE_ROOT}/:storyId/email-builder`,
  CAMPAIGN_EMAIL_PARAMS: `${CAMPAIGN_ROUTE_ROOT}/:storyId/email-params`,
  CAMPAIGN_FLOW_BUILDER: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder`,
  CAMPAIGN_FLOW_TEMPLATES: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder-templates`,
  CAMPAIGN_FLOW_BUILDER_EMAIL_TEMPLATE: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder/:nodeId/email-templates`,
  CAMPAIGN_FLOW_BUILDER_EMAIL_BUILDER: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder/:nodeId/email-builder`,
  CAMPAIGN_FLOW_BUILDER_EMAIL_PARAMS: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder/:nodeId/email-params`,
  CAMPAIGN_FLOW_BUILDER_SMS: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder/:nodeId/sms`,
  CAMPAIGN_FLOW_BUILDER_SUCCESS: `${CAMPAIGN_ROUTE_ROOT}/:storyId/flow-builder/success`,

  // campaign edit flow
  CAMPAIGN_EDIT_CHANNEL: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId`,
  CAMPAIGN_EDIT_SCHEDULE: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/schedule`,
  CAMPAIGN_EDIT_SCHEDULE_DELIVERY: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/delivery`,
  CAMPAIGN_EDIT_DONE: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/done`,
  CAMPAIGN_EDIT_SMS: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/sms`,
  CAMPAIGN_EDIT_EMAIL_TEMPLATE: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/email-templates`,
  CAMPAIGN_EDIT_EMAIL_BUILDER: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/email-builder`,
  CAMPAIGN_EDIT_EMAIL_PARAMS: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/email-params`,
  CAMPAIGN_EDIT_FLOW_BUILDER: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/flow-builder`,
  CAMPAIGN_EDIT_FLOW_BUILDER_EMAIL_TEMPLATE: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/flow-builder/:nodeId/email-templates`,
  CAMPAIGN_EDIT_FLOW_BUILDER_EMAIL_BUILDER: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/flow-builder/:nodeId/email-builder`,
  CAMPAIGN_EDIT_FLOW_BUILDER_EMAIL_PARAMS: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/flow-builder/:nodeId/email-params`,
  CAMPAIGN_EDIT_FLOW_BUILDER_SMS: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/flow-builder/:nodeId/sms`,
  CAMPAIGN_EDIT_FLOW_BUILDER_SUCCESS: `${CAMPAIGN_ROUTE_ROOT}/:campaignId/flow-builder/success`,
  SHOW_CAMPAIGN_FLOW_BUILDER: `${CAMPAIGN_EDIT_ROUTE_ROOT}/:campaignId/show-flow-builder`,

  CONTACT_LIST_INFO: `${CONTACTS_ROUTE_ROOT}/:contactListId`,
  CONTACT_LISTS: `${CONTACTS_ROUTE_ROOT}`,

  DASHBOARD: '/dashboard',

  // reports flow
  REPORTS: `${REPORTS_ROUTE_ROOT}`,
  VIEWS_REPORT: `${REPORTS_ROUTE_ROOT}/views-report`,
  CONTACTS_REPORT: `${REPORTS_ROUTE_ROOT}/contacts-report`,
  EMAIL_REPORT: `${REPORTS_ROUTE_ROOT}/email-report`,
  CAMPAIGNS_REPORT: `${REPORTS_ROUTE_ROOT}/campaigns-report`,
  CAMPAIGNS_REPORT_EDIT: `${REPORTS_ROUTE_ROOT}/campaigns-report/:campaignId`,

  LOGIN: '/login',
  FEDERATION_LOGIN: '/federation-login',

  USER_PROFILE: '/account-settings',
  USER_PROFILE_ACCOUNT_SETTINGS: '/account-settings?tab=account_settings',
  USER_PROFILE_SUBSCRIPTIONS: '/account-settings?tab=subscriptions',

  MEDIA_LIB: '/media',
  SENDER: `${SENDER_ROUTE_ROOT}/type`,
  SENDER_EMAIL_TEMPLATE: `${SENDER_ROUTE_ROOT}/email-templates`,
  SENDER_EMAIL_BUILDER: `${SENDER_ROUTE_ROOT}/email-builder`,
  SENDER_EMAIL_PARAMS: `${SENDER_ROUTE_ROOT}/email-params`,
  SENDER_SCHEDULER: `${SENDER_ROUTE_ROOT}/scheduler`,
  SENDER_DELIVERY: `${SENDER_ROUTE_ROOT}/delivery`,
  SENDER_DONE: `${SENDER_ROUTE_ROOT}/done`,
  SENDER_SMS_PARAMS: `${SENDER_ROUTE_ROOT}/sms-params`,

  SENDER_FLOW_BUILDER: `${SENDER_ROUTE_ROOT}/:uid/flow-builder`,
  SENDER_FLOW_BUILDER_EMAIL_TEMPLATE: `${SENDER_ROUTE_ROOT}/:uid/flow-builder/:nodeId/email-templates`,
  SENDER_FLOW_BUILDER_EMAIL_BUILDER: `${SENDER_ROUTE_ROOT}/:uid/flow-builder/:nodeId/email-builder`,
  SENDER_FLOW_BUILDER_EMAIL_PARAMS: `${SENDER_ROUTE_ROOT}/:uid/flow-builder/:nodeId/email-params`,
  SENDER_FLOW_BUILDER_SMS: `${SENDER_ROUTE_ROOT}/:uid/flow-builder/:nodeId/sms`,
  SENDER_FLOW_BUILDER_SUCCESS: `${SENDER_ROUTE_ROOT}/flow-builder/success`,
  SENDER_FLOW_BUILDER_TEMPLATES: `${SENDER_ROUTE_ROOT}/:uid/flow-builder-templates`,

  STOREFRONT: `/storefront`,
  STOREFRONT_STORES: `/storefront/stores`,
  STOREFRONT_STORES_DESIGN: `/storefront/stores/:iStore/design`,
  STOREFRONT_STORES_PRODUCTS: `/storefront/stores/:iStore/products`,
  STOREFRONT_STORES_META_BUILDER: `/storefront/stores/meta-builder`,
  STOREFRONT_PRODUCTS: `/storefront/products`,
  STOREFRONT_PRODUCTS_NEW: `/storefront/products/new`,
  STOREFRONT_PRODUCTS_EDIT: `/storefront/products/:iProduct`,
  STOREFRONT_STATS: `/storefront/stats`,
};

export default ROUTES;
