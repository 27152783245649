import React from 'react';
import { Provider } from 'react-redux';
import { detect } from 'detect-browser';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { gaInitialize } from 'utils/googleAnalytics';
import { useMount } from 'hooks';
import AppRouter from './Router';
import configureStore from 'store/configureStore';

// TODO: create an .env variable for STRIPE_PUBLIC_KEY
// https://stripe.com/docs/payments/save-and-reuse#add-stripejs-and-elements-to-your-page
// const stripePromise = loadStripe('pk_live_zjjNdUSS2rZkXJdC9xe75vsw004TtMEvue');
const stripePromise = loadStripe('pk_test_Uz0m8uhs44wOBe9NuEAORFQb006HTIyhia');

let store = configureStore();

export default function App() {
  useMount(() => {
    gaInitialize();

    // This is used for .firefox styling of input-color
    // TODO: remove detect-browser library and use some CSS only hack
    // e.g. https://stackoverflow.com/questions/952861/targeting-only-firefox-with-css
    const browser = detect();
    const rootEl = document.getElementById('root');
    rootEl.classList.add((browser && browser.name) || '');
  });

  return (
    <Provider store={store}>
      <StripeElements stripe={stripePromise}>
        <AppRouter />
      </StripeElements>
    </Provider>
  );
}
