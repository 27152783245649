import React from 'react';
import classNames from 'classnames';
import { Switch } from '@blueprintjs/core';
import './styles.scss';

export const ComponentSwitcher = ({
  className = '',
  isEnabled,
  label = 'Comment Box',
  children,
  onChange,
}) => {
  const compClass = classNames(className, 'comment-box-switcher');
  const switcherClass = classNames('comment-box-switcher__top', {
    'comment-box-switcher__top--disabled': !isEnabled,
  });

  const onToggleChange = () =>
    onChange({
      isEnabled: !isEnabled,
    });

  return (
    <div className={compClass}>
      <div className={switcherClass}>
        <Switch
          label={label}
          large
          alignIndicator="right"
          checked={isEnabled}
          onChange={onToggleChange}
        />
      </div>

      {isEnabled && children}
    </div>
  );
};
