import React from 'react';
import ReactGA from 'react-ga';
import { isGaEnabled } from 'utils/googleAnalytics';

export default function OutboundLink({ className, href, target, rel, eventLabel, children }) {
  if (isGaEnabled) {
    return (
      <ReactGA.OutboundLink
        className={className}
        to={href}
        target={target}
        rel={rel}
        eventLabel={eventLabel}
      >
        {children}
      </ReactGA.OutboundLink>
    );
  }

  return (
    <a className={className} href={href} target={target} rel={rel}>
      {children}
    </a>
  );
}
