import { view, complement } from 'lens/utils';
import storyLens from 'lens/story';
import { IStory, ISubscriptionSummary } from 'models';
import { USAGE_SERVICES } from 'constants/campaign';
import { isLocalClientEnv } from 'app-config';
import { SITE_URL } from 'constants/app';


const getClientHost = () => {
  return isLocalClientEnv() ? 'http://localhost:3001' : SITE_URL;
}

export function getStoryFullUrl(storyId: string, isPreview: boolean) {
  const host = getClientHost();
  let url = `${host}/view/${storyId}`;

  if (isPreview) {
    return `${url}?isPreview=true`;
  }

  return url;
}

export function getStoryShortUrl(id: string) {
  return `https://short.panels.ai/${id}`;
}

export function isSaved(story: IStory) {
  const isPublished = isPublishedStory(story);

  if (isPublished) {
    return true;
  }

  return view(storyLens.isSaved, story) ? true : false;
}

export function isPublishedStory(story: IStory) {
  return view(storyLens.datePublished, story) ? true : false;
}

function isDeletedStory(story: IStory) {
  return view(storyLens.dateDeleted, story) ? true : false;
}

export const isDraftStory = complement(isPublishedStory);
export const isActiveStory = complement(isDeletedStory);

export function getPreviewUrl({ storyId, pageType, pageId, timeStamp = '' }:
  {
    storyId: string, pageType: string, pageId: string, timeStamp: string
  }) {
  const host = getClientHost();

  return storyId && pageType && pageId
    ? `${host}/view/${storyId}/${pageType}/${pageId}?isAdminView=true&t=${timeStamp}`
    : null;
}

export function isUsageSummaryQuantity(usageSummary: ISubscriptionSummary) {
  return usageSummary[USAGE_SERVICES.story]?.usedQuantity < usageSummary[USAGE_SERVICES.story]?.quantityToUse;
}

export function isPublishingAvailable(usageSummary: ISubscriptionSummary, story: IStory) {
  const isStoryChartHasSteps = Object.keys(story.chartRaw?.nodes).length > 0;

  return isStoryChartHasSteps && (
    isUsageSummaryQuantity(usageSummary) || isPublishedStory(story)
  );
}


export function sortByDateUpdated(stories: IStory[]) {
  if (!stories || !stories.length) {
    return stories;
  }

  const storiesToSort = stories.slice(0);

  return storiesToSort.sort((s1, s2) => {
    return s1.dateUpdated < s2.dateUpdated ? 1 : -1;
  });
}

