import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import story from 'store/actions/story';
import notification from 'store/actions/notification';
import campaign from 'store/actions/campaign';
import sender from 'store/actions/sender';
import reports from 'store/actions/reports/reportsReducer';
import campaignFlow from 'store/actions/campaignFlow/camapignFlowReducer';
import currentStore from 'store/actions/currentStore/currentStoreReducer';
import productReducer from 'store/actions/products/productReducer';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore() {
  const store = createStore(
    combineReducers({
      activeStory: story,
      campaign,
      campaignFlow,
      currentStore,
      notification,
      products: productReducer,
      reports,
      sender,
    }),
    composeSetup(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
