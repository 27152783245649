import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as ProductService from 'services/products';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { IProduct } from 'models/Products/IProduct';

export const useNewProduct = () => {
  const iUser = useUserId();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);

  const createProduct = useCallback(async (product: IProduct, onSuccess: Function) => {
    setIsCreating(true);

    try {
      await ProductService.saveProduct({
        iUser,
        product: {
          ...product,
          tags: product.tags.map(tag => ({ ...tag, iUser })),
        }
      });

      dispatch(addNotification({
        type: 'success',
        message: 'Product was updated!',
      }));

      if (onSuccess) onSuccess();
    }
    catch(e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR))
    }
    finally {
      setIsCreating(false);
    }
  }, [iUser, dispatch])

  return {
    isCreating,
    createProduct,
  }
}