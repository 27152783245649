import React from 'react';
import { getStockImagesByCollectionId, getAllCollections } from 'services/unsplash';
import { Paging, Modal, Button, Select, Section } from 'components/_common';
import { MediaTile } from '../MediaTile';
import { MEDIA_PAGE_SIZES } from 'constants/campaign';

export class PredefinedCollections extends React.Component {
  state = {
    mediaFiles: [],
    selectedFile: undefined,
    pageSize: MEDIA_PAGE_SIZES[0].value,
    currentPage: 0,
    isLoading: false,
    collections: [],
    selectedCollection: null,
  };

  getMediaFiles = () => {
    const { pageSize, currentPage, selectedCollection } = this.state;

    this.setState({ isLoading: true });

    getStockImagesByCollectionId({
      selectedCollectionId: selectedCollection?.value,
      page: currentPage,
      limit: pageSize,
    })
      .then(this.setMediaFiles)
      .catch((error) => console.error(error))
      .finally(() => this.setState({ isLoading: false }));
  };

  getCollections = () => {
    this.setState({
      isLoading: true,
    });

    getAllCollections()
      .then((collections) => {
        const list =
          collections &&
          collections.map((item) => ({
            label: item.title,
            value: String(item.id),
            total: item.total_photos,
          }));

        this.setState(
          {
            collections: list,
            selectedCollection: list[0],
          },
          this.getMediaFiles
        );
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  setMediaFiles = (files) => {
    const thumbnails = files?.map((file) => ({
      ...file,
      thumbUrl: file.urls.thumb,
    }));

    this.setState({
      mediaFiles: thumbnails,
    });
  };

  onNextClickHandler = () => {
    const { currentPage } = this.state;
    const newCurrentPage = currentPage + 1;

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      this.getMediaFiles
    );
  };

  onPrevClickHandler = () => {
    const { currentPage } = this.state;
    const newCurrentPage = Math.max(currentPage - 1, 0);

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      this.getMediaFiles
    );
  };

  onPageSizeChangeHandler = (pageSize) => {
    this.setState(
      {
        pageSize: parseInt(pageSize, 0),
        currentPage: 0,
        isLoading: true,
      },
      this.getMediaFiles
    );
  };

  onSelectCollection = (ev) => {
    const selectedId = ev.target.value;
    const { collections } = this.state;

    const selectedCollection = collections?.find((item) => item.value === selectedId);

    this.setState(
      {
        selectedCollection,
      },
      this.getMediaFiles
    );
  };

  componentDidMount() {
    this.getCollections();
  }

  saveImage() {
    const { selectedFile } = this.state;

    this.props.onSave({
      userId: this.props.userId,
      name: selectedFile.description || 'Unsplash Image',
      url: selectedFile.urls.full,
      s3Key: selectedFile.id,
      contentType: 'image/jpg',
      altText: selectedFile.alt_desctiption,
      unsplashFile: {
        id: selectedFile.id,
        links: { ...selectedFile.links },
        user: { ...selectedFile.user },
      },
    });
  }

  render() {
    const {
      isLoading,
      mediaFiles = [],
      currentPage,
      pageSize,
      selectedFile,
      collections,
      selectedCollection,
    } = this.state;

    return (
      <Section isLoading={isLoading}>
        <div className="media-modal__body">
          <Select
            className="margin-bottom--lg"
            isLoading={isLoading}
            options={collections}
            value={selectedCollection?.value}
            onChange={this.onSelectCollection}
          />

          {!mediaFiles.length ? (
            <div className="text-center stretch-block">No media files</div>
          ) : (
            mediaFiles.map((mediaFile) => (
              <MediaTile
                isDeletable={false}
                key={mediaFile.id}
                mediaFile={mediaFile}
                isSelected={selectedFile?.id === mediaFile.id}
                onSelect={() => this.setState({ selectedFile: mediaFile })}
                onChooseMedia={() =>
                  this.setState({ selectedFile: mediaFile }, () => this.saveImage())
                }
                onDelete={() => this.onDeleteHandler(mediaFile)}
              />
            ))
          )}
        </div>

        <Modal.Footer className="media-modal__footer">
          <Paging
            className="media-modal__paging"
            labelText="Files per page:"
            currentPage={currentPage}
            totalCount={selectedCollection?.total}
            pageSize={pageSize}
            options={MEDIA_PAGE_SIZES}
            onPageSizeChange={this.onPageSizeChangeHandler}
            onPrevClick={this.onPrevClickHandler}
            onNextClick={this.onNextClickHandler}
          />
          <div>
            <Button
              className="btn-grey margin-right--sm"
              type="button"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>

            <Button
              isPrimary
              type="button"
              onClick={() => {
                if (selectedFile) {
                  this.saveImage();
                }
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Section>
    );
  }
}
