import './styles.scss';
import React, { useCallback, useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

import { ImageSelector } from './ImageSelectorCard';
import { IMedia } from 'models/IMedia';
import { IProductImage } from 'models';

type IProductImageExt = IProductImage & {uuid: string};

export const ProductImages = ({
  className = '',
  productImages,
  onAddImage,
  onDelete,
}: {
  className?: string;
  productImages?: IProductImageExt[];
  onAddImage: (file: IMedia) => void;
  onDelete: (image: IProductImage, indx: number) => void;
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(productImages);
  }, [productImages]);

  const onChange = useCallback((_sourceId: any, sourceIndex: any, targetIndex: any) => {
    const result = swap(images, sourceIndex, targetIndex).filter(i => !!i);
    return setImages([
      ...result,
    ]);
  }, [images]);

  return (
    <div className={`product-images ${className}`}>
      <GridContextProvider onChange={onChange}>
        <div className="product-images__container">
          <GridDropZone
            className="product-images__dropzone"
            id="images"
            boxesPerRow={2}
            rowHeight={170}
          >
            {images.map((image, indx) => (
              <GridItem
                className="product-images__draggable"
                key={`${image?.uuid}`}
              >
                <ImageSelector
                  className="product-images__image"
                  image={image}
                  onSave={onAddImage}
                  onDelete={() => onDelete(image, indx)}
                />
              </GridItem>
            ))}
          </GridDropZone>
        </div>
      </GridContextProvider>
    </div>
  );
}
