import React from 'react';
import classNames from 'classnames';
import { getStaticResourceUrl } from 'utils/common';

export default function Logo({ className, alt = 'Panels logo' }) {
  const compClass = classNames(className);
  const logoSrc = getStaticResourceUrl('/images/logo-with-text.png');

  return (
    <a
      className={compClass}
      style={{ display: 'block', width: '300px', height: '79px' }}
      href="https://panels.ai/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="img-fluid" src={logoSrc} alt={alt} />
    </a>
  );
}
