import { TTemplate } from './camapignFlowReducer';

export enum CampaignFlowActionsEnum {
  INIT_FLOW = 'CAMPAIGN_FLOW/INIT_FLOW',
  UPDATE_CHART = 'CAMPAIGN_FLOW/UPDATE_CHART',
  UPDATE_TEMPLATE = 'CAMPAIGN_FLOW/UPDATE_TEMPLATE',
  UPDATE_TEMPLATES = 'CAMPAIGN_FLOW/UPDATE_TEMPLATES',
  REMOVE_CACHE = 'CAMPAIGN_FLOW/REMOVE_CACHE',
}

export interface ICustomAction<T, P> {
  (params: P): { type: T, params: P };
};

export const campaignFlowInit: ICustomAction<CampaignFlowActionsEnum, {
  campaignId: string,
}> = ({ campaignId }) => {
  return {
    type: CampaignFlowActionsEnum.INIT_FLOW,
    params: {
      campaignId,
    }
  }
}

export const campaignFlowUpdateChart: ICustomAction<CampaignFlowActionsEnum, {
  links: any,
  nodes: any,
  campaignFlowId?: number,
  isLoading?: boolean,
  campaignId?: string;
}> = ({ links, nodes, campaignFlowId, isLoading, campaignId }) => {
  return {
    type: CampaignFlowActionsEnum.UPDATE_CHART,
    params: {
      campaignId,
      links,
      nodes,
      campaignFlowId,
      isLoading,
    }
  }
}

export const campaignFlowUpdateTemplate: ICustomAction<CampaignFlowActionsEnum, {
  campaignId: string,
  nodeId: string,
  template: TTemplate,
}> = ({ nodeId, template, campaignId }) => {
  return {
    type: CampaignFlowActionsEnum.UPDATE_TEMPLATE,
    params: {
      nodeId,
      template,
      campaignId,
    }
  }
}

export const campaignFlowUpdateTemplates: ICustomAction<CampaignFlowActionsEnum, {
  templates: any,
  campaignId: string,
}> = ({ templates, campaignId }) => {
  return {
    type: CampaignFlowActionsEnum.UPDATE_TEMPLATES,
    params: {
      campaignId,
      templates,
    }
  }
}

export const removeCampaignFlowCache: ICustomAction<CampaignFlowActionsEnum, {
  campaignId: string;
}> = ({ campaignId }) => {
  return {
    type: CampaignFlowActionsEnum.REMOVE_CACHE,
    params: {
      campaignId,
    }
  }
}
