import React from 'react';
import Storage from '@aws-amplify/storage';
import { getFileKey, getFileName, getImagePath } from 'utils/media';
import { saveMediaFile } from 'services/media';
import { DEFAULT_ERROR } from 'constants/notifications';
import { Modal, Label, Input, InputFile, Button } from 'components/_common';

import PreviewFile from '../PreviewFile';
import './style.scss';

export class UploadMedia extends React.Component {
  state = {
    assetName: undefined,
    altText: '',
    file: undefined,
    previewSrc: undefined,
    isSaving: false,
  };

  onSaveHandler = (mediaFile) => {
    // Add small timeout for image processing
    setTimeout(() => this.setState({ isSaving: false }, () => this.props.onSave(mediaFile)), 3000);
  };

  handleMediaFormSubmission = (event) => {
    event.preventDefault();

    const { userId } = this.props;
    const { type: contentType } = this.state.file;
    const name = this.getAssetName();
    const s3Key = getFileKey(userId, name);
    this.setState({ isSaving: true });
    Storage.put(s3Key, this.state.file, { contentType })
      .then((response) => {
        const mediaFile = {
          name,
          contentType,
          altText: this.state.altText || undefined,
          url: getImagePath(response.key),
          s3Key: response.key,
          userId: userId,
        };

        return saveMediaFile(mediaFile);
      })
      .then(this.onSaveHandler)
      .catch((err) => {
        // TODO: Add notification here
        console.error(err);
        alert(DEFAULT_ERROR.message);
        this.setState({ isSaving: false });
      });
  };

  getAssetName = () => {
    const { assetName } = this.state;

    if (assetName || assetName === '') {
      return assetName;
    }

    return getFileName(this.state.file);
  };

  handlerFileError = (errorMsg) => {
    this.props.onError && this.props.onError(errorMsg);
  };

  render() {
    const { file, altText, isSaving } = this.state;

    return (
      <form onSubmit={this.handleMediaFormSubmission}>
        <div className="upload-media__new-body">
          <div className="upload-media__new-left">
            <div className="margin-bottom--lg">
              <InputFile
                required
                accept={this.props.accept}
                disabled={isSaving}
                label={isSaving ? 'Uploading...' : 'Upload Media'}
                onError={this.handlerFileError}
                onChange={(file) => this.setState({ file })}
              />
            </div>

            <div className="margin-bottom">
              <Label htmlFor="asset-name">Name</Label>
              <Input
                id="asset-name"
                required
                value={this.getAssetName()}
                onChange={(e) => this.setState({ assetName: e.target.value })}
              />
            </div>

            <div className="margin-bottom">
              <Label htmlFor="alt-text">Alt Text</Label>
              <Input
                id="alt-text"
                value={altText}
                onChange={(e) => this.setState({ altText: e.target.value })}
              />
            </div>
          </div>

          <div className="upload-media__new-right">
            <PreviewFile file={file} />
          </div>
        </div>

        <Modal.Footer>
          <Button
            className="btn-grey margin-right--sm"
            type="button"
            disabled={isSaving}
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>

          <Button isPrimary disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    );
  }
}
