import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from '@blueprintjs/core';
import { Select } from 'components/_common';
import './styles.scss';

const PAGE_SIZES = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const Paging = ({
  className,
  labelText = 'Items per page:',
  currentPage = 0,
  totalCount = 0,
  pageSize = PAGE_SIZES[0].value,
  options = PAGE_SIZES,
  onPrevClick,
  onNextClick,
  onPageSizeChange,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize) || 1; // if no pages at all
  const isPrevAvailable = !!currentPage;
  const isNextAvailable = currentPage + 1 < totalPages;
  const pagerText = `${currentPage + 1} / ${totalPages}`;

  const onPageSizeChangeHandler = useCallback(
    (ev) => {
      const pageSize = ev.target.value;
      onPageSizeChange && onPageSizeChange(parseInt(pageSize, 10));
    },
    [onPageSizeChange]
  );

  return (
    <div className={classNames('paging', className)}>
      <div className="display-flex align-items-center">
        <Button
          className="paging__page-btn bp3-minimal"
          icon="arrow-left"
          disabled={!isPrevAvailable}
          text="Previous"
          onClick={() => isPrevAvailable && onPrevClick()}
        />
        <span className="margin-right--sm margin-left--sm">{pagerText}</span>
        <Button
          className="paging__page-btn bp3-minimal"
          rightIcon="arrow-right"
          disabled={!isNextAvailable}
          text="Next"
          onClick={() => isNextAvailable && onNextClick()}
        />
      </div>

      <label className="display-flex align-items-center">
        {labelText}
        <Select
          className="paging_page-size margin-left--sm"
          options={options}
          value={pageSize}
          onChange={onPageSizeChangeHandler}
        />
      </label>
    </div>
  );
};
