import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export const UnsplashUserLink = ({ className, link, userName }) => {
  const clsNames = classNames('unsplash-user-link', className);
  return (
    <a className={clsNames} href={link} target="_blank" rel="noopener noreferrer">
      Photo by {userName} on Unsplash
    </a>
  );
};
