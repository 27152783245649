import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getPromoCodeByUserId } from 'services/v2/stripe';
import { IPromoCode } from 'models';
import { updateUser } from 'services/user';

export const usePromoCode = () => {
  const isDetached = useRef(false);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<IPromoCode | null>(null);
  const dispatch = useDispatch();

  const onErrorHandler = useCallback((error) => {
    console.error('[useUserInfo]', error);
    if (isDetached.current) return;

    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [dispatch]);


  const getUserCouponHandler = useCallback(() => {
    if (!userId) return;
    setIsLoading(true);

    getPromoCodeByUserId({ userId })
      .then((promoCode: IPromoCode) => {
        if (isDetached.current) return null;

        setPromoCode(promoCode);
      })
      .catch(onErrorHandler)
      .finally(() => {
        if (isDetached.current) return;
        setIsLoading(false);
      });
  },
    [userId, onErrorHandler]
  );

  const applyPromoCode = (code: string) => {
    updateUser({userId, promoCode: code})
      .then(() =>  getUserCouponHandler())
  };

  useEffect(() => {
    if (!isDetached.current)     {
      getUserCouponHandler();

    }

    return () => {
      isDetached.current = true;
    };
  }, [userId, getUserCouponHandler]);

  return {
    isPromoCodeLoading: isLoading,
    promoCode,
    applyPromoCode,
  };
};
