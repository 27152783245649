import React, { useState, useEffect } from 'react';
import _debounce from 'lodash.debounce';
import { TTag } from 'models';
import { WithContext as ReactTags } from 'react-tag-input';
import { searchTagsForUser } from 'services/products';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const TagsInput = ({
  iUser,
  initialTags,
  updateTags,
}: {
  iUser: string;
  initialTags: TTag[];
  updateTags?: any;
}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(initialTags.map(tag => ({ id: tag.iTag?.toString(), text: tag.tag })));
  }, [initialTags]);

  const [suggestions, setSuggestions] = useState([]);

  const handleDelete = (i: any) => {
    const tagsToUpdate = tags.filter((tag, index) => index !== i);
    setTags(tagsToUpdate);

    updateTags(tagsToUpdate.map(tag => ({ iTag: +tag.id, tag: tag.text })))
  };

  const handleAddition = (tag: any) => {
    const tagsToUpdate = [...tags, tag];

    setTags(tagsToUpdate);

    updateTags(tagsToUpdate.map(tag => ({ iTag: +tag.id, tag: tag.text })))
  };

  const handleDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleInputChange = _debounce(async (tag: any) => {
    const result: any[] = await searchTagsForUser({ tag, iUser });
    const suggestions = result.map(i => ({ id: i.iTag.toString(), text: i.tag }));

    setSuggestions(suggestions);
  }, 200);

  return (
    <ReactTags
      tags={tags}
      suggestions={suggestions}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      handleInputChange={handleInputChange}
      inputFieldPosition="top"
      autocomplete
    />
  );
};