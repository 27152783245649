import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export default function Input(props) {
  const { hasError, className, isRounded } = props;
  const compClass = classNames(className, 'form-control', {
    'form-control--rounded': isRounded,
    'form-control--has-error': hasError,
  });

  return (
    <input
      className={compClass}
      id={props.id}
      name={props.name}
      style={props.style}
      type={props.type || 'text'}
      value={props.value || ''}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      placeholder={props.placeholder}
      title={props.title}
      autoComplete={props.autoComplete}
      inputMode={props.inputMode}
      pattern={props.pattern}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onClick={props?.onClick}
    />
  );
}
