import React from 'react';
import classNames from 'classnames';
import Input from '../Input';
import Button from '../Button';
import ButtonUploadMedia from '../ButtonUploadMedia';
import MediaModal from '../MediaModal';
import { triggerDownload } from 'services/unsplash';
import './styles.scss';

export class InputMedia extends React.Component {
  static defaultProps = {
    readOnly: true,
  };

  state = {
    showModal: false,
  };

  onSaveMedia = (mediaFile) => {
    if (mediaFile?.unsplashFile) {
      triggerDownload(mediaFile.unsplashFile);
    }

    this.setState({ showModal: false }, () => this.props.onChange(mediaFile));
  };

  handlerFileError = (errorMsg) => {
    this.props.onError && this.props.onError(errorMsg);
  };

  handlerRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onRemove();
  };

  handleOpenModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const className = classNames(this.props.className, 'input-media-wrapper');

    return (
      <div className={className}>
        <Input
          className="margin-right--sm"
          id={this.props.id}
          value={this.props.value}
          readOnly={this.props.readOnly}
          onClick={this.handleOpenModal}
        />

        {this.props.value && this.props.onRemove && (
          <Button
            className="btn btn-remove-media margin-left--sm"
            type="button"
            onClick={this.handlerRemove}
          >
            &times;
          </Button>
        )}

        <ButtonUploadMedia
          iconOnly
          isPrimary
          ariaLabel="Upload Media"
          onClick={this.handleOpenModal}
        />

        {this.state.showModal && (
          <MediaModal
            accept={this.props.accept}
            onError={this.handlerFileError}
            onSave={this.onSaveMedia}
            onClose={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  }
}

export default InputMedia;
