import React from 'react';
import classNames from 'classnames';
import { LazyImage } from 'components/_common';
import { isVideo, getPreviewFileSrc } from 'utils/media';
import Video from '../Video';

const DEFAULT_PREVIEW_IMG = 'https://via.placeholder.com/407x213/f5f5f5?text=placeholder';

export default function PreviewFile({ className, file }) {
  if (!file) {
    return <PreviewImage className={className} src={DEFAULT_PREVIEW_IMG} />;
  }

  return <PreviewUploadedFile className={className} file={file} key={file.name} />;
}

class PreviewUploadedFile extends React.Component {
  state = {
    previewSrc: undefined,
  };

  componentDidMount() {
    getPreviewFileSrc(this.props.file)
      .then((previewSrc) => {
        this.setState({ previewSrc });
      })
      .catch((error) => console.error(error));
  }

  render() {
    const { previewSrc } = this.state;
    const { className, file, alt = '' } = this.props;

    return (
      <PreviewMediaFile className={className} src={previewSrc} contentType={file.type} alt={alt} />
    );
  }
}

export class PreviewMediaFile extends React.PureComponent {
  render() {
    const { src, contentType, alt = '' } = this.props;
    const className = classNames(this.props.className, 'preview-media-upload-media img-fluid');

    if (isVideo(contentType)) {
      return <PreviewVideo className={className} src={src} />;
    }

    return <PreviewImage className={className} src={src} alt={alt} />;
  }
}

class PreviewImage extends React.PureComponent {
  render() {
    const { className, src, alt = '' } = this.props;

    return <LazyImage className={className} src={src} alt={alt} />;
  }
}

class PreviewVideo extends React.PureComponent {
  render() {
    const { className, src } = this.props;

    return <Video className={className} src={src} />;
  }
}
