import { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as ProductService from 'services/products';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { IProduct } from 'models/Products/IProduct';

export const useEditProduct = ({
  iProduct,
}: {
  iProduct: number;
}) => {
  const isDetached = useRef(false);
  const iUser = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [product, setProduct] = useState<IProduct>(null);
  const dispatch = useDispatch();

  const loadProduct = useCallback(async (iProduct: number) => {
    if (isDetached.current) return;

    setIsLoading(true);

    try {
      const product = await ProductService.getProduct({ iProduct, iUser });
      setProduct(product);
    }
    catch(e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    }
    finally {
      setIsLoading(false);
    }
  }, [iUser, dispatch]);

  const updateProduct = useCallback(async (product: IProduct, onSuccess: Function) => {
    setIsUpdating(true);

    try {
      await ProductService.updateProduct({
        iUser,
        product: {
          ...product,
          tags: product.tags.map(tag => ({ ...tag, iUser })),
        }
      });

      dispatch(addNotification({
        type: 'success',
        message: 'Product was updated!',
      }));

      if (onSuccess) onSuccess();
    }
    catch(e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR))
    }
    finally {
      setIsUpdating(false);
    }
  }, [iUser, dispatch])

  useEffect(() => {
    loadProduct(iProduct);

    return () => {
      isDetached.current = true;
    };
  }, [iProduct, loadProduct]);

  return {
    isLoading,
    isUpdating,
    product,
    updateProduct,
  }
}