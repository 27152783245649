import { TAddNotificationData } from "models";

export const DEFAULT_SAVE_SUCCESS = {
  type: 'success',
  message: 'Data saved successfully.',
} as TAddNotificationData;

export const DEFAULT_ERROR = {
  type: 'error',
  message: 'Internal server error. Please try again later.',
} as TAddNotificationData;
