import { CAMPAIGN_TYPE } from 'constants/campaign';
import { applyChanges } from './utils';

export function campaignUpdate(params) {
  return {
    type: 'CAMPAIGN/UPDATE',
    params,
  };
}

export function campaignLoad(data) {
  return {
    type: 'CAMPAIGN/LOAD',
    data,
  };
}

const DEFAULT_CAMPAIGN = {
  type: CAMPAIGN_TYPE.SMS,
  name: 'Summer Campaign',
  message: `Hi {{ contact_first_name }},
We have a special offer for you.
Check out the page for details {{ story_url }}
`,
  emailParams: {
    subject: 'Greetings from Panels!',
  },
};

export default function campaign(state = DEFAULT_CAMPAIGN, action) {
  switch (action.type) {
    case 'CAMPAIGN/UPDATE':
      return applyChanges(state, action.params);
    case 'CAMPAIGN/LOAD':
      return {
        ...DEFAULT_CAMPAIGN,
        ...action.data,
      };
    default:
      return state;
  }
}
