import './styles.scss';
import React from 'react';
import classNames from 'classnames';
import { Spinner } from '@blueprintjs/core';
import { Select, TSelectProps, TSelectOption } from '../Select';


export type TSelectWithLoaderProps<T> = TSelectProps<T> & {
  isLoading: boolean
};


export const SelectWithLoader = <T extends TSelectOption>({ className, isLoading, ...rest }: TSelectWithLoaderProps<T>) => {
  const compClass = classNames(className, 'select-with-loader');

  return (
    <span className={compClass}>
      <Select {...rest} hideArrow={isLoading} />

      {isLoading && <Spinner className="select-with-loader__spinner" size={24} />}
    </span>
  );
}
