import './styles.scss';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Input, Button } from '..';

type TCreateNewItemProps = {
  className?: string,
  placeholder?: string,
  buttonTitle?: string,
  initialName?: string,
  onSubmit: (name: string) => void
};

export const CreateNewItem = ({
  className,
  placeholder = 'My Awesome New Project',
  buttonTitle = 'New Story',
  initialName,
  onSubmit }: TCreateNewItemProps) => {
  const [storyName, setStoryName] = useState(initialName || '');
  const compClass = classNames(className, 'display-flex create-new-item');

  const handleSubmit = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();

    onSubmit(storyName);
  }, [storyName, onSubmit]);

  return (
    <form className={compClass} onSubmit={handleSubmit}>
      <Input
        isRounded
        required
        placeholder={placeholder}
        value={storyName}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setStoryName(e.currentTarget.value)}
      />

      <Button className="margin-left" isPrimary>
        {buttonTitle}
      </Button>
    </form>
  );
}
