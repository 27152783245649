import { set } from 'ramda';

export function applyChanges(state, params) {
  return params.reduce((accumulator, currentItem) => {
    const lensPathFn = currentItem.lens;
    return set(lensPathFn, prepareValueForDynamo(currentItem.value), accumulator);
  }, state);
}

export function prepareValueForDynamo(value) {
  // Dynamo fails with the following error in case of empty strings
  // ValidationException: One or more parameter values were invalid: An AttributeValue may not contain an empty string
  if (value === '') {
    // returning null instead of undefined as
    // _.merge does not copy undefined values
    return null;
  }

  return value;
}
