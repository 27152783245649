import { useState, useEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { IAuthUser } from 'models/IAuthUser';
import { PROMO_CODE } from 'constants/app';
import { getUserId, isValidPromoCode } from 'utils';
import { updateUser } from 'services/user';

const storeOwnerId = (userId: string | undefined) => {
  const localStorage = window.localStorage;
  const code = localStorage.getItem(PROMO_CODE);

  if (isValidPromoCode(code) && userId) {
    // we need to save user's owner/promo code
    updateUser({ userId, ownerId: code })
      .catch((err) => {
        console.error('[Update User Promo]', err)
      })
      .finally(() => {
        localStorage.removeItem(PROMO_CODE);
      })
  }
}

export function useAuthUser() {
  let [user, setUser] = useState<IAuthUser>();

  useEffect(() => {
    let updateUserAuth = async () => {
      try {
        let user: IAuthUser = await Auth.currentAuthenticatedUser();
        setUser(user);

        storeOwnerId(getUserId(user));
      } catch {
        setUser(null);
      }


    };
    Hub.listen('auth', updateUserAuth); // listen for login/signup events

    // we are not using async to wait for updateUserAuth,
    // so there will be a flash of page where the user is assumed not to be logged in. If we use a flag
    updateUserAuth(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUserAuth); // cleanup
  }, []);

  return user;
}
