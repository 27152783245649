import { useState, useEffect, useCallback, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import {
  getProductsPrices,
  getPortalRedirectUrl,
  createCheckoutSession,
} from 'services/v2/stripe';
import {
  getCustomerSubscriptions,
} from 'services/v2/subscriptions';
import { sortProductsByTypes } from 'utils/stripe';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { IStripeProduct, IStripeSubscriptions, IStripeSubscriptionMap } from 'models';

const parseSubscriptions: (subscriptions: IStripeSubscriptions) => IStripeSubscriptions
  = (subscriptions) => {
    return subscriptions?.map((sub) => {
      return {
        ...sub,
        isActive: sub.status === 'active',
        priceId: sub?.plan?.id,
        product: sub?.plan?.product,
      };
    });
  };

const getSubscriptionMap = (subscriptions: IStripeSubscriptions) => {
  const mappedSubscriptions = subscriptions.reduce((acc, sub) => {
    if (sub.isActive) {
      return {
        ...acc,
        [sub?.plan?.id]: { ...sub },
      };
    }

    return acc;
  }, {});

  return mappedSubscriptions;
};

export const useProducts = ({ userId }: { userId: string }) => {
  const isDetached = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [products, setProducts] = useState<IStripeProduct[]>([]);
  const [subscriptions, setSubscriptions] = useState<IStripeSubscriptions>([]);
  const [subscriptionsMap, setSubscriptionsMap] = useState<IStripeSubscriptionMap>({});

  const stripe = useStripe();
  const dispatch = useDispatch();
  const onErrorHandler = useCallback(() => {
    if (isDetached.current) return;
    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [isDetached, dispatch]);

  const loadProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const productPriceList = await getProductsPrices();
      if (isDetached.current) return;

      productPriceList.sort(sortProductsByTypes);

      setIsLoading(false);
      setProducts(productPriceList);
      setTotalProducts(productPriceList.length);
    } catch (err) {
      console.error('loadProducts', err);
      onErrorHandler();
    }
  }, [isDetached, onErrorHandler]);

  const loadCustomerSubscriptions = useCallback(async () => {
    if (!userId) return;
    setIsLoadingSubscriptions(true);

    try {
      const subscriptions: IStripeSubscriptions = await getCustomerSubscriptions({ userId });
      if (isDetached.current) return;
      
      setIsLoadingSubscriptions(false);
 
      const parsed = parseSubscriptions(subscriptions);
      setSubscriptions(parsed);
      setSubscriptionsMap(getSubscriptionMap(parsed));

    } catch (err) {
      console.error('loadCustomerSubscriptions', err);
      onErrorHandler();
    }
  }, [userId, isDetached, onErrorHandler]);

  const redirectToCheckout = useCallback(
    async (products) => {
      setIsLoading(true);
      try {
        const session = await createCheckoutSession({ userId, products });
        if (isDetached.current) return;

        stripe.redirectToCheckout({ sessionId: session.id }).then((result) => { 
          // user will be redirected, nothing to do
        });
        
        setIsLoading(false);
      } catch (err) {
        console.error('redirectToCheckout', err);
        onErrorHandler();
      }
    },
    [userId, stripe, isDetached, onErrorHandler]
  );

  const getPortalUrl = useCallback(() => {
    setIsProcessing(true);

    return getPortalRedirectUrl({ userId })
      .then((res) => {
        if (isDetached.current) return;
        return res?.redirectUrl;
      })
      .catch((err) => {
        console.error('getPortalUrl', err);
        onErrorHandler();
      })
      .finally(() => setIsProcessing(false));
  }, [userId, onErrorHandler]);

  useEffect(() => {
    loadProducts();
    loadCustomerSubscriptions();

    return () => { 
      isDetached.current = true;
    };
  }, [userId, loadProducts, loadCustomerSubscriptions]);

  return {
    isLoading: isLoadingSubscriptions || isLoading,
    isProcessing,
    totalProducts,
    products,
    subscriptions,
    subscriptionsMap,
    getPortalUrl,
    redirectToCheckout,
  };
};
