import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export default function Label(props) {
  const compClass = classNames(props.className, 'label');

  return (
    <label className={compClass} style={props.style} htmlFor={props.htmlFor}>
      {props.children}
    </label>
  );
}
