import { postCall, deleteCall, getCall } from './service';

export function saveContactList(contactList) {
  return postCall('/api/contact/saveList', contactList);
}

export function getContactListById(id, userId) {
  return getCall('/api/contact/getContactListById', { id, userId });
}

export function getUserContactLists(userId) {
  return getCall('/api/contact/getUserContactLists', { userId });
}

export function getContactsByListId({ userId, contactListId, options }) {
  return getCall('/api/contact/getContactsByListId', { userId, contactListId, ...options });
}

export function saveContacts(userId, contactListId, contacts) {
  return postCall('/api/contact/saveContacts', { userId, contactListId, contacts });
}

export function updateContactListName(id, userId, name) {
  return postCall('/api/contact/updateContactListName', { id, userId, name });
}

export function deleteContact(userId, contactListId, contactId) {
  return deleteCall('/api/contact/deleteContact', { userId, contactListId, contactId });
}

export function addContact(userId, contactListId, contact) {
  const { id, ...newContact } = contact;
  return postCall('/api/contact/addContact', { userId, contactListId, contact: newContact });
}

export function updateContact(userId, contactListId, contact) {
  return postCall('/api/contact/updateContact', { userId, contactListId, contact });
}

export function deleteContactList(contactListId, userId) {
  return deleteCall('/api/contact/deleteContactList', { contactListId, userId });
}
