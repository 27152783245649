import { TServiceTypes } from 'models';
import { getCall } from '../service';

export function getSubscriptionUsageByUserId({ userId, isStatisticNeeded }: { userId: string, isStatisticNeeded?: boolean }) {
  return getCall('/api/subscription/getSubscriptionUsageByUserId', { userId, showStatistic: isStatisticNeeded });
};

export function getCustomerSubscriptions({ userId }: { userId: string }) {
  return getCall('/api/subscription/getCustomerSubscriptions', { userId });
};

export function getActiveSubscriptionByUserIdType({ userId, type }: { userId: string, type: TServiceTypes }) {
  return getCall('/api/products/getCustomerSubscriptions', { userId, type });
};