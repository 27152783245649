import './styles.scss';
import React from 'react';
import { saveContactList, saveContacts } from 'services/contacts';
import { generateSelectOptionsByName, getStaticResourceUrl } from 'utils/common';
import { parseCsvFile } from 'utils/csv';
import { Modal, Label, Select, Input, InputFile, Button } from '../../';

export default class ContactListModal extends React.Component {
  static defaultProps = {
    contactLists: [],
  };

  state = {
    file: undefined,
    contacts: undefined,
    newListName: '',
    selectedContactListId: '',
    isSaving: false,
  };

  handleFileChange = (file) => {
    this.setState({ file, contacts: null }, () => {
      parseCsvFile(file).then((contacts) => this.setState({ contacts }));
    });
  };

  handleContactsSubmission = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { newListName, selectedContactListId, contacts } = this.state;
    const { contactLists, onSave, userId } = this.props;

    if (newListName) {
      const newContactList = {
        userId: this.props.userId,
        name: this.state.newListName,
      };

      this.setState({ isSaving: true });
      saveContactList(newContactList)
        .then((createdContactList) => {
          return saveContacts(userId, createdContactList.id, contacts).then(() => {
            this.setState({ isSaving: false }, () => onSave(createdContactList, true));
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isSaving: false });
        });
    } else if (selectedContactListId) {
      const list = contactLists || [];
      const selectedList = list.find((item) => String(item.id) === selectedContactListId);

      this.setState({ isSaving: true });
      saveContacts(userId, selectedContactListId, contacts)
        .then((response) => {
          this.setState({ isSaving: false }, () => onSave(selectedList));
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isSaving: false });
        });
    }
  };

  handlerFileError = (errorMsg) => {
    this.props.onError && this.props.onError(errorMsg);
  };

  render() {
    const { file, contacts, newListName, selectedContactListId, isSaving } = this.state;
    const { contactLists } = this.props;
    const fileName = (file && file.name) || '';
    const hasContactLists = contactLists && contactLists.length ? true : false;
    const hasUploadedContacts = contacts && contacts.length ? true : false;

    return (
      <Modal className="modal-contact-lists" onClose={this.props.onClose}>
        <form onSubmit={this.handleContactsSubmission}>
          <Modal.Header>Add Contacts Here</Modal.Header>

          <Modal.Content>
            <div className="margin-bottom">
              <InputFile
                required
                accept="text/csv"
                label="Upload File"
                disabled={isSaving}
                onError={this.handlerFileError}
                onChange={this.handleFileChange}
              />

              <span className="upload-contacts-file-notice">
                CSV files only.{' '}
                <a href={getStaticResourceUrl('/example-contacts.csv')}>Download a sample file.</a>
              </span>

              {hasUploadedContacts && (
                <div className="parsed-contacts-info">
                  Found <strong>{contacts.length}</strong> contacts in <i>{fileName}</i>
                </div>
              )}
            </div>

            {hasContactLists && (
              <div className="margin-bottom">
                <Label htmlFor="add-to-existing-list">Add to existing list</Label>
                <Select
                  includeEmpty
                  id="add-to-existing-list"
                  options={generateSelectOptionsByName(this.props.contactLists)}
                  required={!newListName}
                  disabled={newListName || isSaving}
                  value={selectedContactListId}
                  onChange={(e) => {
                    this.setState({ selectedContactListId: e.target.value });
                  }}
                />
              </div>
            )}

            <div className="margin-bottom--lg">
              <Label htmlFor="create-new-list">Create a new list</Label>
              <Input
                id="create-new-list"
                placeholder="My awesome clients"
                required={!selectedContactListId}
                disabled={isSaving}
                value={newListName}
                onChange={(e) => {
                  if (selectedContactListId) {
                    this.setState({ selectedContactListId: '' });
                  }

                  this.setState({ newListName: e.target.value });
                }}
              />
            </div>
          </Modal.Content>

          <Modal.Footer>
            <Button
              className="btn-grey margin-right--sm"
              type="button"
              disabled={isSaving}
              onClick={this.props.onClose}
            >
              Cancel
            </Button>

            <Button isPrimary disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
