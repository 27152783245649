import ROUTES from 'constants/routes';
import appConfig from 'app-config';

const { features } = appConfig;

export function nodeTypeToRoute(type: string) {
  switch (type) {
    case 'welcome':
      return ROUTES.STEP_WELCOME;
    case 'splashVideo':
      return ROUTES.STEP_SPLASH_VIDEO;
    case 'optionPicker1':
      return ROUTES.STEP_OPTION_PICKER_1;
    case 'optionPicker2':
      return ROUTES.STEP_OPTION_PICKER_2;
    case 'contactForm':
      return ROUTES.STEP_CONTACT_FORM;
    case 'schedule':
      return ROUTES.STEP_SCHEDULE;
    case 'thankYou':
      return ROUTES.STEP_THANK_YOU;
    case 'sTable':
      if (features.FLOW_TABLE) {
        return ROUTES.STEP_OPTION_SELECTOR;
      }
      return null;
    case 'store':
      if (features.STOREFRONT) {
        return ROUTES.STEP_STORE;
      }
      return null;

    default:
      console.warn(`[panels-admin]: Non implemented route "${type}"`);
      return null;
  }
}
