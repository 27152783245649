import { getCall, postCall, deleteCall, putCall } from "./service";
import { UserProps, IProduct } from "models";

export function getProductList({
  iUser,
  sortingKey,
  direction,
  pageNumber,
  pageSize,
}: {
  sortingKey: string;
  direction: string;
  pageNumber: number;
  pageSize: number;
} & UserProps) {
  return getCall('/api/storeProduct/productList', {
    iUser,
    sortingKey,
    direction,
    pageNumber,
    pageSize,
  });
}

export function getProductListWithStore({
  iUser,
  iStore,
  sortingKey,
  direction,
  pageNumber,
  pageSize,
}: {
  iStore: number;
  sortingKey: string;
  direction: string;
  pageNumber: number;
  pageSize: number;
} & UserProps) {
  return getCall('/api/storeProduct/productListWithStore', {
    iUser,
    iStore,
    sortingKey,
    direction,
    pageNumber,
    pageSize,
  });
}

export function saveProduct({
  product,
  iUser,
}: {
  product: IProduct;
} & UserProps) {
  return postCall('/api/storeProduct/createProduct', { ...product, iUser });
}

export function updateProduct({
  product,
  iUser,
}: {
  product: IProduct;
} & UserProps) {
  return putCall('/api/storeProduct/updateProduct', { ...product, iUser });
}

export function deleteProduct({
  iProduct,
  iUser,
}: {
  iProduct: IProduct['iProduct'],
} & UserProps) {
  return deleteCall('/api/storeProduct/deleteProduct', { iProduct, iUser });
}

export function getProduct({
  iProduct,
  iUser,
}: {
  iProduct: IProduct['iProduct'],
} & UserProps) {
  return getCall('/api/storeProduct/product', { iProduct, iUser });
}

export function getTagForUserByTagName({
  tag,
  iUser,
}: { tag: string } & UserProps) {
  return getCall('/api/storeProduct/getTagForUserByTagName', { tag, iUser });
}

export function searchTagsForUser({
  tag,
  iUser,
}: { tag: string } & UserProps) {
  return getCall('/api/storeProduct/searchTagsForUser', { tag, iUser });
}