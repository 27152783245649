import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export default function InputRange(props) {
  const compClass = classNames(props.className, 'custom-range');

  return (
    <input
      type="range"
      className={compClass}
      id={props.id}
      min={props.min}
      max={props.max}
      step={props.step}
      value={props.value || ''}
      onChange={props.onChange}
    />
  );
}
