import React from 'react';
import classNames from 'classnames';
import { SelectWithLoader  } from '../SelectWithLoader';
import { SignedInUserContext } from 'utils/context';
import { getUserId } from 'utils/user';
import { getStoreListByIUser } from 'services/v2/store';
import { IStore } from 'models';

type TState = {
  storeList: IStore[];
  showModal: boolean,
  isLoadingLists: boolean,
}

type TProps = {
  id: string;
  className?: string;
  required?: boolean;
  value: number;
  onChange: (store: IStore) => void;
}

export function generateSelectOptionsByName(list: IStore[]) {
  if (!list || !list.length) {
    return [];
  }

  return list.map((item) => ({ value: item.iStore, label: item.name }));
}

class InputStoreList extends React.Component<TProps & { iUser: string }, TState> {
  state = {
    storeList: null,
    showModal: false,
    isLoadingLists: false,
  } as TState;

  componentDidMount() {
    this.setState({ isLoadingLists: true });
    getStoreListByIUser(this.props.iUser)
      .then((storeList) => {
        this.setState({ storeList });
      })
      .catch((error) => console.error(error))
      .finally(() => this.setState({ isLoadingLists: false }));
  }

  handleListSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const list = this.state.storeList || [];
    const selectedList = list.find((item) => item.iStore === +event.target.value);

    this.props.onChange(selectedList);
  };

  render() {
    const className = classNames(this.props.className, 'input-media-wrapper');

    return (
      <div className={className}>
        <SelectWithLoader
          includeEmpty
          isLoading={this.state.isLoadingLists}
          disabled={this.state.isLoadingLists}
          options={generateSelectOptionsByName(this.state.storeList)}
          required={this.props.required}
          value={this.props.value}
          onChange={this.handleListSelect}
        />
      </div>
    );
  }
}

export default (props: TProps) => (
  <SignedInUserContext.Consumer>
    {(user) => <InputStoreList {...props} iUser={getUserId(user)} />}
  </SignedInUserContext.Consumer>
);
