import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from '@aws-amplify/core';
import App from './App';
import appConfig from './app-config';

// include general styles
import 'normalize.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import './styles/main.scss';
 
Amplify.configure(appConfig);
ReactDOM.render(<App />, document.getElementById('root'));
