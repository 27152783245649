import React, { useState } from 'react';
import classNames from 'classnames';
import { Switch } from '@blueprintjs/core';
import InputColor from '../InputColor';
import InputRange from '../InputRange';
import { DEFAULT_STORY } from 'constants/story';
import './styles.scss';

export default function BgOverlay({
  className = '',
  color,
  opacity,
  label = 'Background Overlay',
  onChange,
}) {
  const [isEnabled, setIsEnabled] = useState(false);

  const compClass = classNames(className, 'bg-overlay');

  function toggleOverlay() {
    if (isEnabled) {
      setIsEnabled(false);
      onChange({ color: '', opacity: '' });
    }

    if (!isEnabled) {
      setIsEnabled(true);
      onChange({
        color: DEFAULT_STORY.OVERLAY.COLOR,
        opacity: DEFAULT_STORY.OVERLAY.OPACITY,
      });
    }
  }

  return (
    <div className={compClass}>
      <div className="bg-overlay-top">
        <label>{label}</label>
        <Switch large checked={isEnabled} onChange={toggleOverlay} />
      </div>

      {isEnabled && (
        <React.Fragment>
          <InputColor
            value={color}
            onChange={(e) =>
              onChange({
                color: e.target.value,
                opacity: opacity,
              })
            }
          />

          <div className="bg-overlay-range-wrapper">
            <InputRange
              id="bg-overlay-opacity"
              min={0}
              max={1}
              step={0.01}
              value={opacity}
              onChange={(e) =>
                onChange({
                  color: color,
                  opacity: e.target.value,
                })
              }
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
