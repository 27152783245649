import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export default function InputColor(props) {
  const className = classNames(props.className, 'input-color');

  return (
    <input
      className={className}
      id={props.id}
      value={props.value || ''}
      onChange={props.onChange}
      type="color"
    />
  );
}
