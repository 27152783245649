import { postCall, getCall, deleteCall } from './service';
import { parseAndDownloadCsv } from 'utils/csv';
import { DEFAULT_ERROR } from 'constants/notifications';
import { formatDateToServer, formatTimestamp } from 'utils/date';
import { CAMPAIGN_STATUS_NAMES, defaultCampaignTypes } from 'constants/campaign';
import { TAddNotification } from 'models';
import { ICampaign, TCampaignTypes } from 'models/ICampaign';

export function saveCampaign(campaign: ICampaign) {
  return postCall('/api/campaign/save', campaign);
}

export function updateCampaign(campaign: ICampaign) {
  return postCall('/api/campaign/update', campaign);
}


export function getCampaignsByStoryId({
  storyId,
  userId,
  dateRange,
  direction,
  sortingKey,
  pageNumber = 0,
  pageSize = 10,
  types = defaultCampaignTypes,
}: {
  storyId: string;
  userId: string;
  dateRange?: Date[];
  direction?: string;
  sortingKey?: string;
  pageNumber?: number;
  pageSize?: number;
  types?: TCampaignTypes;
}) {
  return getCall('/api/campaign/getCampaignsByStoryId', {
    storyId,
    userId,
    dateRange: dateRange?.map(d => d && formatDateToServer(d)),
    direction,
    sortingKey,
    pageNumber,
    pageSize,
    types,
  });
}

export async function downloadCampaignsList(
  storyId: string,
  userId: string,
  direction: string,
  sortingKey: string,
  addNotification: TAddNotification
) {
  try {
    const result = await getCampaignsByStoryId({ storyId, userId, direction, sortingKey }) as { items: ICampaign[] };

    const contactsList = result.items.map((item, index) => ({
      'No.': ++index,
      Name: item.name,
      Type: item.type,
      Schedule: formatTimestamp(item.dateScheduled, 'MM-DD-YYYY hh:mm'),
      Status: CAMPAIGN_STATUS_NAMES[item.status],
    }));

    parseAndDownloadCsv(contactsList, 'campaigns_report');
  } catch (e) {
    addNotification(DEFAULT_ERROR);
  }
}

export function getCampaignsByUserId(userId: string) {
  return postCall('/api/campaign/getCampaignsByUserId', { userId });
}

export function getCampaignById(campaignId: string) {
  return postCall('/api/campaign/getCampaignById', { campaignId });
}

export function deleteCampaign(campaignId: string, userId: string) {
  return deleteCall('/api/campaign/delete', { campaignId, userId });
}

export function deleteFlowBuilderCampaign(campaignId: string, userId: string) {
  return deleteCall('/api/campaign/deleteFlowBuilderCampaign', { campaignId, userId });
}

export function onRunCampaign(campaignId: string) {
  return postCall('/api/campaign/onRun', { campaignId });
}

export function onStopCampaign(campaignId: string) {
  return postCall('/api/campaign/onStop', { campaignId });
}
