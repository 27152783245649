// TODO: use date-fns instead of moment
import moment from 'moment';

const DATE_UI_SHORT_FORMAT = 'MM-DD-YYYY';
const DATE_ISO_SHORT_FORMAT = 'YYYY-MM-DD';
const DATE_UI_FULL_FORMAT = 'MM-DD-YYYY | hh:mm a';

export function getCurrentDate() {
  return new Date();
}

export function dateInFuture({ year = 0, month = 0, day = 0 }:
  { year?: number, month?: number, day?: number }) {

  const d = new Date();
  const fullYear = d.getFullYear();
  const fullMonth = d.getMonth();
  const fullDay = d.getDate();

  return new Date(fullYear + year, fullMonth + month, fullDay + day);
}

export function formatTimestampToUI(date: number | string, params?: { isShort: boolean }) {
  const { isShort = false } = params || {};

  return formatTimestamp(date, isShort ? DATE_UI_SHORT_FORMAT : DATE_UI_FULL_FORMAT);
}

export function formatTimestamp(date?: number | string, format = DATE_UI_SHORT_FORMAT) {
  if (!date) {
    return null;
  }

  return moment.unix(+date).format(format);
}

export function isDate1BeforeDate2(d1: moment.MomentInput, d2: moment.MomentInput) {
  const date1 = moment(d1);
  const date2 = moment(d2);

  return date1.isBefore(date2);
}

export function formatDateToUI(date?: moment.MomentInput) {
  return formatDate(date, DATE_UI_FULL_FORMAT);
}


export function formatDateToServer(date?: moment.MomentInput) {
  return formatDate(date, DATE_ISO_SHORT_FORMAT);
}


export function formatDate(date?: moment.MomentInput, format = DATE_UI_SHORT_FORMAT) {
  if (!date) {
    return null;
  }

  return moment(date).format(format);
}

export function toDate(date?: moment.MomentInput, format = DATE_UI_SHORT_FORMAT) {
  if (date) {
    return moment(date, format).toDate();
  }
}

export function endOfDay(date: moment.MomentInput) {
  return moment(date).endOf('day');
}

export function startOfMonth(date: moment.MomentInput) {
  return moment(date).startOf('month');
}

export function getTimestamp(date: moment.MomentInput) {
  return moment(date).unix();
}

export function sortByDate(date1: { lastUpdated: number }, date2: { lastUpdated: number }) {
  return date1.lastUpdated <= date2.lastUpdated;
}
