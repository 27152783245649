import { IStoreProducts } from 'models';
import { ProductActionsActionsEnum } from './productActions';

export default function productReducer(state = {} as IStoreProducts, { type, params }:
    { type: ProductActionsActionsEnum, params: any }) {

  switch (type) {
    case ProductActionsActionsEnum.PRODUCTS_SAVE_PRODUCT:
    case ProductActionsActionsEnum.PRODUCTS_LOAD_PRODUCT:
      return {
        ...state,
        isProcessing: true
      }

    case ProductActionsActionsEnum.PRODUCTS_SAVE_PRODUCT_FULFILLED: {
      return {
        ...state,
        isProcessing: false
      }
    }
    case ProductActionsActionsEnum.PRODUCTS_SAVE_PRODUCT_REJECTED: {
      return {
        ...state,
        isProcessing: false
      }
    }

    default:
      return state;
  }
}