import styled from 'styled-components';
import { SPACER_SM, COLOR_PRIMARY } from 'constants/theme';

export const H1 = styled.div`
  margin-bottom: ${SPACER_SM};
  color: ${COLOR_PRIMARY};
  font-size: 34px;
`;

export const H2 = styled.div`
  margin-bottom: ${SPACER_SM};
  color: ${COLOR_PRIMARY};
  font-size: 30px;
`;
