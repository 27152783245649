import './styles.scss';
import * as React from 'react';
import { Select, ButtonIcon, Input, InputColor, Label } from '../';
import { TProductConfiguration } from 'models/Products/TProductConfigurations';
import { ProductConfigurationTypeEnum } from 'models/Products/ProductConfigurationsTypeEnum';

export const ProductConfigurationRow = ({
  index,
  productConfiguration,
  updateProductConfiguration,
  onDeleteProductConfiguration,
}: {
  index: number;
  productConfiguration: TProductConfiguration;
  updateProductConfiguration: (value: Partial<TProductConfiguration>) => void;
  onDeleteProductConfiguration: () => void;
}) => {

  const typeChanged = (type: ProductConfigurationTypeEnum) => {
    if (type === ProductConfigurationTypeEnum.TEXT) {
      productConfiguration.color = undefined;
    }
    if (type === ProductConfigurationTypeEnum.COLOR) {
      productConfiguration.name = undefined;
      productConfiguration.value = undefined;
    }

    updateProductConfiguration({
      ...productConfiguration,
      type,
    });
  }

  return (
    <div className="form-inline padding-top--sm padding-bottom--lg margin--sm">
      <div className="form-cell__wrapper margin-right--sm">
        <Label htmlFor={'size' + index}>Type</Label>
        <Select
          id={'size' + index}
          options={[
            { value: ProductConfigurationTypeEnum.COLOR, label: 'Color' },
            { value: ProductConfigurationTypeEnum.TEXT, label: 'Text' },
          ]}
          value={productConfiguration.type}
          onChange={(e) => typeChanged(e.target.value as ProductConfigurationTypeEnum)} />
      </div>

      {productConfiguration.type === ProductConfigurationTypeEnum.TEXT &&
        <>
          <div className="form-cell__wrapper margin-right--sm">
            <Label htmlFor={'name' + index}>Name</Label>
            <Input
              id={'name' + index}
              value={productConfiguration.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateProductConfiguration({ name: e.target.value });
              }}
            />
          </div>

          <div className="form-cell__wrapper margin-right--sm">
            <Label htmlFor={'description' + index}>Description</Label>
            <Input
              id={'description' + index}
              value={productConfiguration.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateProductConfiguration({ value: e.target.value });
              }}
            />
          </div>
        </>
      }

      {productConfiguration.type === ProductConfigurationTypeEnum.COLOR &&
        <div className="form-cell__wrapper margin-right--sm">
          <Label>Color Picker</Label>
          <InputColor
            className="margin-left--sm"
            value={productConfiguration?.color}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateProductConfiguration({ color: e.target.value });
            }}
          />
        </div>
      }

      <div className="form-cell__wrapper width-100 text-right">
        <ButtonIcon
          className="margin-left--sm"
          icon="delete"
          title="Delete"
          onClick={onDeleteProductConfiguration}
        />
      </div>
    </div>
  );
}