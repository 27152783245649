import './styles.scss';
import React from 'react';
import classNames from 'classnames';

export default function Button(props) {
  const compClass = classNames(props.className, 'btn', {
    'btn-primary': props.isPrimary,
    'btn-secondary': props.isSecondary,
  });

  return (
    <button
      className={compClass}
      style={props.style}
      title={props.title}
      type={props.type || 'submit'}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      data-testid={props.dataTestid}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
