import { useReducer, useEffect } from 'react';
import { makePromiseCancelable } from 'services/service';

export function useFetch(servicePromise) {
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    let cancelableStoriesPromise = makePromiseCancelable(servicePromise);

    dispatch({ type: 'REQUEST' });
    cancelableStoriesPromise.promise
      .then((response) =>
        dispatch({
          type: 'SUCCESS',
          data: response,
        })
      )
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'FAILURE' });
      });

    return () => {
      if (cancelableStoriesPromise) {
        cancelableStoriesPromise.cancel();
      }
    };
  }, [servicePromise]);

  return state;
}

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST':
      return { isLoading: true };
    case 'SUCCESS':
      return {
        isLoading: false,
        data: action.data || null,
      };
    case 'FAILURE':
      return { isLoading: false };
    default:
      throw state;
  }
}
