import './styles.scss';
import React from 'react';
import classNames from 'classnames';

interface IButtonIcon {
  className?: string | null,
  icon?: 'add' | 'edit' | 'copy' | 'delete' | 'eye' | 'report' | 'save' | 'send' | 'tick' | 'unpublish' | 'stripe-connect' | null,
  size?: string | null,
  title?: string | null,
  ariaLabel?: string | null,
  disabled?: boolean | null,
  dataTestid?: string | null,
  type?: "button" | "submit" | "reset",
  children?: React.ReactNode,
  onClick?: () => void | null,
}

export function ButtonIcon({
  className = null,
  icon = 'edit',
  size = '',
  title = null,
  ariaLabel = null,
  type = 'button',
  disabled = null,
  dataTestid = null,
  children = null,
  onClick = null,
}: IButtonIcon) {
  const compClass = classNames(className, 'btn-icon', {
    [`btn-icon-${icon}`]: icon,
    [`btn-icon--${size}`]: size,
  });

  return (
    <button
      className={compClass}
      data-testid={dataTestid}
      type={type}
      title={title}
      disabled={disabled}
      aria-label={ariaLabel || title}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default ButtonIcon;
