import { TServiceTypes } from "models";
import { TCampaignTypes } from "models/ICampaign";

export const CAMPAIGN_TYPE = {
  SMS: 'sms',
  EMAIL: 'email',
};

export enum CampaignTypeEnum {
  SMS = 'sms',
  EMAIL = 'email',
  FLOW_BUILDER = 'flowBuilder',
};
export const defaultCampaignTypes = ['sms', 'email', 'flowBuilder'] as TCampaignTypes;

export const MEDIA_PAGE_SIZES = [
  { label: '9', value: 9 },
  { label: '27', value: 27 },
  { label: '36', value: 36 },
  { label: '66', value: 66 },
];

export const SENDER_TYPE = {
  NOW: 'NOW',
  SCHEDULE: 'SCHEDULE',
};

export enum SenderTypeEnum {
  NOW = 'NOW',
  SCHEDULE = 'SCHEDULE',
};

export enum USAGE_SERVICES {
  story = 'story',
  email = 'email',
  sms = 'sms'
};

export const SERVICE_NAME_MAP = {
  'sms': 'SMS',
  'email': 'Emails',
  'story': 'Stories',
} as {
    [key in TServiceTypes]: string
  };

export enum CampaignStatusEnum {
  IN_PROGRESS = 'InProgress',
  DONE = 'Done',
  PAUSED = 'Paused',
  CANCELLED = 'Cancelled',
}

export const CAMPAIGN_STATUS_NAMES = {
  [CampaignStatusEnum.IN_PROGRESS]: 'In Progress',
  [CampaignStatusEnum.DONE]: 'Done',
  [CampaignStatusEnum.PAUSED]: 'Paused',
  [CampaignStatusEnum.CANCELLED]: 'Cancelled',
}
