import React from 'react';
import classNames from 'classnames';
import { UnsplashUserLink } from '../../UnsplashUserLink';
import { Button } from '@blueprintjs/core';
import { PreviewMediaFile } from '../PreviewFile';
import './styles.scss';

export const MediaTile = ({
  isDeletable = true,
  mediaFile,
  isSelected,
  onSelect,
  onChooseMedia,
  onDelete,
}) => {
  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete();
  };

  return (
    <div className="media-tile">
      <div
        className={classNames('media-tile__inner', {
          'media-tile__inner--selected': isSelected,
        })}
      >
        <button
          className="media-tile__btn-select"
          aria-label={`Select ${mediaFile.name}`}
          onClick={onSelect}
          onDoubleClick={onChooseMedia}
        />
        {isDeletable && (
          <Button
            className="bp3-minimal media-tile__btn-delete"
            icon="trash"
            onClick={handleDelete}
          />
        )}
        {mediaFile?.user && (
          <UnsplashUserLink
            className="media-tile__user-link"
            link={mediaFile.user.links.html}
            userName={mediaFile.user.name}
          />
        )}
      </div>
      <PreviewMediaFile
        className="media-tile__file"
        src={mediaFile.thumbUrl}
        contentType={mediaFile.contentType}
      />
    </div>
  );
};
