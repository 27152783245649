import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import './styles.scss';

export default function ButtonUploadMedia({
  iconName,
  isPrimary,
  iconOnly,
  ariaLabel,
  type,
  onClick,
}) {
  const compClassName = classNames('btn-upload-media', {
    'btn-upload-media--icon-only': iconOnly,
    [`btn-upload-media--${iconName}`]: iconName,
  });

  return (
    <Button
      className={compClassName}
      isPrimary={isPrimary}
      aria-label={ariaLabel}
      type={type || 'button'}
      onClick={onClick}
    />
  );
}
