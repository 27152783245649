import { view as ramdaView, set, complement } from 'ramda';
import storyLens from 'lens/story';

import { DEFAULT_STORY } from 'constants/story';
import { PRIMARY_BTN_VALUE } from 'constants/story';

function view(lens, data, defValue) {
  const value = ramdaView(lens, data);
  if (value === undefined) {
    return defValue;
  }

  return value;
}

function updateNodePorts(activeStory, pageId, isEnabled, typeOfButton) {
  const ports = getLensById({
    lens: storyLens.chartRawDetails.nodesPorts,
    data: activeStory,
    nodeId: pageId,
  });
  const links = getLensById({
    lens: storyLens.chartRawDetails.links,
    data: activeStory,
    nodeId: pageId,
  });

  const newProps = {};
  const newLinks = {};
  let changedNodeId = null;
  let changedPortId = null;

  Object.keys(ports).forEach((portKey) => {
    const port = { ...ports[portKey] };

    if (port?.properties?.value === typeOfButton) {
      port.properties.isDisabled = isEnabled;
      changedNodeId = port.nodeId;
      changedPortId = port.id;
    }

    newProps[portKey] = port;
  });

  Object.keys(links).forEach((linkKey) => {
    const link = links[linkKey];

    if (
      changedNodeId &&
      changedPortId &&
      link?.from?.nodeId === changedNodeId &&
      link?.from?.portId === changedPortId
    ) {
      return;
    }

    newLinks[linkKey] = link;
  });

  return {
    ports: newProps,
    links: newLinks,
  };
}

function prepareUpdateButtonInStory({
  activeStory,
  pageId,
  isEnabled,
  typeOfButton,
  storyStepLens,
  btnText,
}) {
  const { ports: newPorts, links: newLinks } = updateNodePorts(
    activeStory,
    pageId,
    isEnabled,
    typeOfButton
  );
  const isPrimary = typeOfButton === PRIMARY_BTN_VALUE;

  if (isPrimary) {
    return [
      { value: btnText, lens: storyStepLens.btnPrimary.text },
      { value: isEnabled, lens: storyStepLens.btnPrimary.isExternal },
      { value: newPorts, lens: storyLens.chartRawDetails.nodesPorts },
      { value: newLinks, lens: storyLens.chartRawDetails.links },
    ];
  }

  return [
    { value: btnText, lens: storyStepLens.btnSecondary.text },
    { value: isEnabled, lens: storyStepLens.btnSecondary.isExternal },
    { value: newPorts, lens: storyLens.chartRawDetails.nodesPorts },
    { value: newLinks, lens: storyLens.chartRawDetails.links },
  ];
}

function getLensById({ lens, data, defaultValue = '', nodeId = '' }) {
  const lensPath = nodeId && typeof lens === 'function' ? lens(nodeId) : lens;
  return view(lensPath, data, defaultValue);
}

function getBaseStoryProps(activeStory) {
  const storyId = getLensById({ lens: storyLens.id, data: activeStory });
  const storyName = getLensById({ lens: storyLens.name, data: activeStory });
  const fontFamily = getLensById({
    lens: storyLens.fontFamily,
    data: activeStory,
    defaultValue: DEFAULT_STORY.FONT_FAMILY,
  });

  const btnBorderRadius = getLensById({
    lens: storyLens.btn.borderRadius,
    data: activeStory,
    defaultValue: DEFAULT_STORY.BTN.BORDER_RADIUS,
  });
  const btnPrimaryColor = getLensById({
    lens: storyLens.btn.colorPrimary,
    data: activeStory,
    defaultValue: DEFAULT_STORY.BTN.COLOR_PRIMARY,
  });
  const btnSecondaryColor = getLensById({
    lens: storyLens.btn.colorSecondary,
    data: activeStory,
    defaultValue: DEFAULT_STORY.BTN.COLOR_SECONDARY,
  });

  const dateUpdated = view(storyLens.dateUpdated, activeStory);

  return {
    storyId,
    storyName,
    fontFamily,
    btnBorderRadius,
    btnPrimaryColor,
    btnSecondaryColor,
    dateUpdated,
  };
}

function getBackgroundProps(activeStory, pageId, bgBaseLensPath) {
  if (!activeStory || !bgBaseLensPath) {
    console.error('Undefined background lens, pageId or active story');
    return {};
  }

  const bgName = getLensById({
    lens: bgBaseLensPath.bg.name,
    data: activeStory,
    nodeId: pageId,
  });
  const verticalPosition = getLensById({
    lens: bgBaseLensPath.bg.verticalPosition,
    data: activeStory,
    nodeId: pageId,
    defaultValue: DEFAULT_STORY.BACKGROUND.VERTICAL_POSITION,
  });
  const horizontalPosition = getLensById({
    lens: bgBaseLensPath.bg.horizontalPosition,
    data: activeStory,
    nodeId: pageId,
    defaultValue: DEFAULT_STORY.BACKGROUND.HORIZONTAL_POSITION,
  });
  const containerFit = getLensById({
    lens: bgBaseLensPath.bg.containerFit,
    data: activeStory,
    nodeId: pageId,
    defaultValue: DEFAULT_STORY.BACKGROUND.CONTAINER_FIT,
  });
  const bgUrl = getLensById({
    lens: bgBaseLensPath.bg.url,
    data: activeStory,
    nodeId: pageId,
  });
  const unsplashFileBg = getLensById({
    lens: bgBaseLensPath.bg.unsplashFile,
    data: activeStory,
    nodeId: pageId,
  });
  const bgContentType = getLensById({
    lens: bgBaseLensPath.bg.contentType,
    data: activeStory,
    nodeId: pageId,
  });

  const bgPosterName = getLensById({
    lens: bgBaseLensPath.bg.poster.name,
    data: activeStory,
    nodeId: pageId,
  });
  const bgPosterUrl = getLensById({
    lens: bgBaseLensPath.bg.poster.url,
    data: activeStory,
    nodeId: pageId,
  });
  const bgPosterUnsplashFile = getLensById({
    lens: bgBaseLensPath.bg.poster.unsplashFile,
    data: activeStory,
    nodeId: pageId,
  });

  return {
    bgName,
    bgUrl,
    unsplashFileBg,
    bgContentType,
    bgPosterName,
    bgPosterUrl,
    bgPosterUnsplashFile,
    verticalPosition,
    horizontalPosition,
    containerFit,
  };
}

function getOverlayProps(activeStory, pageId, baseLensPath) {
  if (!activeStory || !pageId || !baseLensPath) {
    console.error('Undefined background lens, pageId or active story');
    return {};
  }

  const overlayColor = getLensById({
    lens: baseLensPath.overlay.color,
    data: activeStory,
    nodeId: pageId,
  });
  const overlayOpacity = getLensById({
    lens: baseLensPath.overlay.opacity,
    data: activeStory,
    nodeId: pageId,
  });

  return {
    overlayColor,
    overlayOpacity,
  };
}

function getButtonProps(activeStory, pageId, baseLensPath, defaultValue) {
  if (!activeStory || !pageId || !baseLensPath) {
    console.error('Undefined button lens, pageId or active story');
    return {};
  }

  const text = getLensById({
    lens: baseLensPath.text,
    data: activeStory,
    nodeId: pageId,
    defaultValue: defaultValue.text,
  });

  const isExternal = getLensById({
    lens: baseLensPath.isExternal,
    data: activeStory,
    nodeId: pageId,
    defaultValue: defaultValue.isExternal,
  });
  const url = getLensById({
    lens: baseLensPath.url,
    data: activeStory,
    nodeId: pageId,
    defaultValue: defaultValue.url,
  });

  return {
    text,
    isExternal,
    url,
  };
}

function getStoryButtons(activeStory, pageId, baseLensPath, stepDefaults) {
  const btnPrimary = getButtonProps(
    activeStory,
    pageId,
    baseLensPath.btnPrimary,
    stepDefaults?.BTN_PRIMARY
  );

  const btnSecondary = getButtonProps(
    activeStory,
    pageId,
    baseLensPath.btnSecondary,
    stepDefaults?.BTN_SECONDARY
  );

  return {
    btnPrimary,
    btnSecondary,
  };
}

export {
  view,
  getLensById,
  set,
  complement,
  getBaseStoryProps,
  getBackgroundProps,
  getButtonProps,
  getStoryButtons,
  getOverlayProps,
  prepareUpdateButtonInStory,
  updateNodePorts,
};
