import './styles.scss';

import React from 'react';
import classNames from 'classnames';
import { Spinner } from 'components/_common';

export const TemplateWithOverlay = ({
  head,
  isSpinnerShown = false,
  className = '',
  children,
}: {
  head: string;
  isSpinnerShown?: boolean;
  className?: string;
  children: any;
}) => {
  const componentClassName = classNames('template-with-overlay__wrapper margin-top--lg margin-left--lg', className, {
    'template-with-overlay__mask': isSpinnerShown,
  });

  return (
    <div className={componentClassName}>
      { isSpinnerShown && <Spinner className="template-with-overlay__spinner"></Spinner> }
      <h3 className="template-with-overlay__title padding-left--lg">
        { head }
      </h3>

      <div className="template-with-overlay__body margin--lg">
        { children }
      </div>
    </div>
  )
}