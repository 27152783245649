import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export default function Alert({ className, children, type = 'success', onDismiss }) {
  const compClass = classNames(className, 'alert', {
    'alert-danger': type === 'error',
    'alert-success': type === 'success',
    'alert-info': type === 'info',
    'alert-warn': type === 'warn',
    'alert-dismissible': onDismiss,
  });

  return (
    <div className={compClass} role="alert">
      {children}
      {onDismiss && (
        <button
          className="alert__btn-dismiss btn-close"
          onClick={onDismiss}
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
}
