import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  getTemplatesByUserId,
  deleteTemplateFromDB,
  saveTemplate as saveTemplateService,
  updateTemplate as updateTemplateService,
} from 'services/template';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR, DEFAULT_SAVE_SUCCESS } from 'constants/notifications';
import { sortByDate } from 'utils/date';

export const useTemplates = ({ userId }) => {
  const isDetached = useRef(false);
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const onErrorHandler = useCallback(
    (error) => {
      console.error(error);
      if (isDetached.current) return;

      setIsLoading(false);
      dispatch(addNotification(DEFAULT_ERROR));
    },
    [isDetached, dispatch]
  );

  const loadTemplates = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await getTemplatesByUserId({ userId });
      if (isDetached.current) return;

      const templates = res.templates;
      setTemplates(templates.sort(sortByDate));

      setIsLoading(false);
      setIsLoaded(true);
    } catch (error) {
      onErrorHandler(error);
    }
  }, [userId, onErrorHandler]);

  const deleteTemplate = useCallback(
    async ({ templateId }) => {
      setIsLoading(true);
      deleteTemplateFromDB({ userId, templateId })
        .then(() => {
          if (isDetached.current) return;
          loadTemplates();

          dispatch(
            addNotification({
              type: 'success',
              message: 'Template deleted successfully.',
            })
          );
        })
        .catch((error) => onErrorHandler(error));
    },
    [userId, loadTemplates, onErrorHandler, dispatch]
  );

  const saveTemplate = useCallback(
    async ({ name, type, template, description }) => {
      setIsLoading(true);

      saveTemplateService({
        userId,
        name,
        type,
        template,
        description,
      })
        .then(() => {
          if (isDetached.current) return;

          dispatch(addNotification(DEFAULT_SAVE_SUCCESS));
          loadTemplates();
        })
        .catch((error) => onErrorHandler(error));
    },
    [userId, loadTemplates, onErrorHandler, dispatch]
  );

  const updateTemplate = useCallback(
    async ({ templateId, template, description }) => {
      setIsLoading(true);
      updateTemplateService({
        userId,
        templateId,
        template,
        description,
      })
        .then(() => {
          if (isDetached.current) return;

          dispatch(addNotification(DEFAULT_SAVE_SUCCESS));
          loadTemplates();
        })
        .catch((error) => onErrorHandler(error));
    },
    [userId, loadTemplates, onErrorHandler, dispatch]
  );

  useEffect(() => {
    loadTemplates();
    return () => {
      isDetached.current = true;
    };
  }, [loadTemplates, userId]);

  return {
    isLoading,
    isLoaded,
    templates,
    saveTemplate,
    updateTemplate,
    deleteTemplate,
  };
};
