import ReactGA from 'react-ga';

export const GA_EVENT_CATEGORY = {
  LOGIN: 'Login',
  SIGNUP: 'Signup',
};

export function gaInitialize() {
  const trackingId = getTrackingId();

  if (!trackingId) {
    return;
  }

  // https://github.com/react-ga/react-ga/issues/379
  /* eslint-disable */
  return new Promise((resolve) => {
    (function (i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      a.addEventListener('load', resolve);
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    window.ga('create', trackingId, 'auto');
    window.ga('send', 'pageview');
    /* eslint-enable */
  }).then(() => {
    ReactGA.initialize(trackingId, {
      standardImplementation: true,
    });
  });
}

export function gaSet(fieldsObject) {
  if (!isGaEnabled()) {
    return;
  }

  ReactGA.set(fieldsObject);
}

// https://github.com/react-ga/react-ga#reactgaeventargs
export function gaEvent(eventData) {
  if (!isGaEnabled()) {
    return;
  }

  ReactGA.event(eventData);
}

export function gaPageView(path) {
  if (!isGaEnabled()) {
    return;
  }

  ReactGA.pageview(path);
}

export function isGaEnabled() {
  return getTrackingId() ? true : false;
}

function getTrackingId() {
  return process.env.REACT_APP_GA_TRACKING_ID;
}
