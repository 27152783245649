import { IProduct, IUserRecord } from "models"

export enum ProductActionsActionsEnum {
  PRODUCTS_SAVE_PRODUCT = "PRODUCTS/CREATE_PRODUCT",
  PRODUCTS_SAVE_PRODUCT_FULFILLED = "PRODUCTS/CREATE_PRODUCT_FULFILLED",
  PRODUCTS_SAVE_PRODUCT_REJECTED = "PRODUCTS/CREATE_PRODUCT_REJECTED",


  PRODUCTS_LOAD_PRODUCT = "PRODUCTS/LOAD_PRODUCT",
  PRODUCTS_LOAD_PRODUCT_FULFILLED = "PRODUCTS/LOAD_PRODUCT_FULFILLED",
  PRODUCTS_LOAD_PRODUCT_REJECTED = "PRODUCTS/LOAD_PRODUCT_REJECTED",
}

export interface ICustomAction<T, P> {
  (params: P): { type: T, params: P };
};

export const saveProduct: ICustomAction<ProductActionsActionsEnum, {
  iUser: IUserRecord['userId'];
  product: IProduct;
}> = ({ iUser, product }) => {
  return {
    type: ProductActionsActionsEnum.PRODUCTS_SAVE_PRODUCT,
    params: {
      iUser,
      product
    }
  }
}

export const loadProduct: ICustomAction<ProductActionsActionsEnum, {
  iUser: IUserRecord['userId'];
  iProduct: IProduct;
}> = ({ iUser, iProduct }) => {
  return {
    type: ProductActionsActionsEnum.PRODUCTS_SAVE_PRODUCT,
    params: {
      iUser,
      iProduct
    }
  }
}