import template from 'lodash.template';
import templateSettings from 'lodash.templatesettings';


// https://lodash.com/docs/4.17.11#template
const CUSTOM_VARS_REGEXP = /{{([\s\S]+?)}}/g;
templateSettings.interpolate = CUSTOM_VARS_REGEXP;

export default function populateCustomVariables(str: string, variablesMap: Record<string, string>) {
  if (!str) {
    return null;
  }

  // https://stackoverflow.com/a/15283850
  const upperCasedVars = str.replace(CUSTOM_VARS_REGEXP, (variable) => {
    // removing spaces
    let tmp = sanitizeString(variable);
    tmp = tmp.toUpperCase();

    return insertSubstr(tmp, 2, 'CUSTOM_VARS.');
  });
  const compiled = template(upperCasedVars);

  return compiled({
    CUSTOM_VARS: variablesMap,
  });
}

function insertSubstr(str: string, index:number, value: string) {
  return str.substr(0, index) + value + str.substr(index);
}

function sanitizeString(str: string) {
  if (!str) {
    return '';
  }

  return str.replace(/\s/g, '').replace(/%20/g, '');
}

export const USER_VARIABLES_REG = /{{\s*(contact_first_name|contact_last_name)\s*}}/gi;
export const STORY_URL_VARIABLE_REG = /{{\s*(story_url)\s*}}/gi;

export function isValidTemplate(str = '') {
  if (!str) {
    return false;
  }

  const removeVars = str.replace(USER_VARIABLES_REG, ''); // after replace, check if some tags are present
  return removeVars.search(/{{|}}/gi) === -1;
}
