import { all, debounce, put, select, call, takeEvery } from 'redux-saga/effects';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_SAVE_SUCCESS, DEFAULT_ERROR } from 'constants/notifications';
import { putStory } from 'services/story';
import storyLens from 'lens/story';
import { getTimestamp } from 'utils/date';
import { getCampaignFlowByCampaignId } from 'services/flowBuilder';
import { getCampaignById } from 'services/campaign';
import { campaignLoad } from 'store/actions/campaign';
import {
  campaignFlowUpdateChart,
  campaignFlowUpdateTemplates,
} from 'store/actions/campaignFlow/campaignFlowActions';
import { updateStore } from 'services/v2/store';
import { productsSagas } from '../actions/products/saga';

const getActiveStory = (state) => state.activeStory;

function* onStorySave() {
  const dateUpdated = getTimestamp();
  yield put({
    type: 'STORY/UPDATE_SILENTLY',
    params: [
      {
        value: dateUpdated,
        lens: storyLens.dateUpdated,
      },
    ],
  });
}

function* saveStory() {
  const activeStory = yield select(getActiveStory);
  try {
    yield call(putStory, activeStory);
    yield onStorySave();
    yield put(addNotification(DEFAULT_SAVE_SUCCESS));
  } catch (error) {
    console.log(error);
    yield put(addNotification(DEFAULT_ERROR));
  }
}

function* saveStoryAsync() {
  yield debounce(500, 'STORY/UPDATE', saveStory);
}

const getCampaignFlow = async (campaignId) => {
  const [campaign, campaignFlow] = await Promise.all([
    getCampaignById(campaignId),
    getCampaignFlowByCampaignId(campaignId),
  ]);

  return { campaign, campaignFlow };
};

function* fetchFlow(action) {
  try {
    const campaignId = action.params.campaignId;
    const { campaign, campaignFlow } = yield call(getCampaignFlow, campaignId);

    yield put(campaignLoad(campaign));
    yield put(
      campaignFlowUpdateChart({
        campaignId,
        links: campaignFlow.chart?.links,
        nodes: campaignFlow.chart?.nodes,
        campaignFlowId: campaignFlow?.campaignFlowId,
        isLoading: false,
      })
    );
    yield put(
      campaignFlowUpdateTemplates({
        campaignId,
        templates: campaignFlow.templates ?? {},
      })
    );
  } catch (e) {
    console.log(e);
    yield put(addNotification(DEFAULT_ERROR));
  }
}

function* initCampaignFlow() {
  yield takeEvery('CAMPAIGN_FLOW/INIT_FLOW', fetchFlow);
}

const getActiveStore = (state) => state.currentStore?.store;

function* saveStore() {
  const activeStore = yield select(getActiveStore);
  try {
    yield call(updateStore, activeStore.iUser, activeStore);
    yield put(addNotification(DEFAULT_SAVE_SUCCESS));
  } catch (error) {
    console.log(error);
    yield put(addNotification(DEFAULT_ERROR));
  }
}

function* saveStoreAsync() {
  yield debounce(500, 'CURRENT_STORE/UPDATE_CURRENT_STORE', saveStore);
}

export default function* rootSaga() {
  yield all([saveStoryAsync(), initCampaignFlow(), saveStoreAsync(), productsSagas()]);
}
