import React from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

export const PhoneNumber = ({ className, id, name, placeholder, value = '', onChange }) => {
  return (
    <MaskedInput
      className={classNames('form-control', className)}
      guide={false}
      id={id}
      name={name}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};
