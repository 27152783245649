import React from 'react';
import classNames from 'classnames';
import { Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Button from '../Button';
import './styles.scss';
 
export default function Collapse({ className, btnClassName = '', isOpen, isCollapsible = true, title, children, onChange }:
  {
    className?: string,
    btnClassName?: string,
    isOpen: boolean,
    isCollapsible?: boolean,
    title: string,
    children?: React.ReactNode,
    onChange: (isOpen: boolean) => any
  }) { 
  const compClass = classNames('collapse', className, {
    'collapse--open': isOpen,
  });

  return (
    <div className={compClass}>
      <Button
        className={`collapse__btn btn-square ${btnClassName}`}
        type="button"
        aria-label="Toggle collapse"
        onClick={() => onChange(!isOpen)}
      >
        {title}

       {isCollapsible && 
        <Icon
          className="collapse__icon"
          icon={isOpen ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          iconSize={Icon.SIZE_LARGE}
          intent={Intent.PRIMARY}
        />
       }
      </Button>

      {isOpen ? <div className="collapse__body">{children}</div> : null}
    </div>
  );
}
