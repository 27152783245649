import React from 'react';
import { DateRangeInput, IDateRangeInputProps } from '@blueprintjs/datetime';
import classNames from 'classnames';
import { formatDate, toDate, endOfDay } from 'utils/date';
import './styles.scss';

export default function DateRange({ className, onChange, ...restProps }: Partial<IDateRangeInputProps>) {
  const compClass = classNames('date-range-input', className);

  return (
    <div className={compClass}>
      <DateRangeInput
        {...restProps}
        formatDate={formatDate}
        parseDate={toDate}
        onChange={(dates) => {
          const from = (dates && dates[0]) || null;
          const to = dates && dates[1] ? toDate(endOfDay(dates && dates[1])) : null;

          onChange([from, to]);
        }}
      />
    </div>
  );
}
