import './styles.scss';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonIcon, LazyImage, MediaModal } from 'components/_common';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { IMedia } from 'models/IMedia';
import { IProductImage } from 'models';

export const ImageSelector = ({  className, image, onSave, onDelete }: {
  className?: string;
  image?: IProductImage;
  onSave: (file: IMedia) => void;
  onDelete: () => void;
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const onSelect = useCallback(() => setShowModal(true), []);
  const onError = useCallback((error: string) => {
    console.log(error)
    dispatch(addNotification(DEFAULT_ERROR));
  }, [dispatch]);

  const saveHandler = useCallback((mediaFile: any) => {
    setShowModal(false);
    onSave(mediaFile as IMedia);
  }, [onSave]);

  const renderImageBlock = () => {
    return (
      <>
        <div className="image-selector__wrapper"></div>
        <LazyImage
          className="image-selector__img"
          src={image?.imageUrl}
          alt="Product Image Preview"
        />
        <ButtonIcon
          className="image-selector__btn image-selector__btn--del"
          icon="delete"
          title="Delete"
          onClick={onDelete}
        />
      </>
    );
  };

  return (
    <div className={`image-selector  ${className ?? ''}`}>
      {
        image?.imageUrl ?
          renderImageBlock()
          : (
            <ButtonIcon
              className="image-selector__btn image-selector__btn--add"
              icon="add"
              title="Select"
              onClick={onSelect}
            />
          )
      }
      {showModal && (
        <MediaModal
          accept="image/*"
          onError={onError}
          onSave={saveHandler}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
}