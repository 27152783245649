import './styles.scss';
import React from 'react';
import { ButtonIcon } from 'components/_common';

const emptyFn = (itemId?: any) => { };

export const PublishingItemToolbar = ({
  isPublished = false,
  itemId,
  previewUrl = '#',
  onEdit = emptyFn,
  onPublish = emptyFn,
  onUnpublish = emptyFn,
  onDelete = emptyFn,
}: {
  isPublished?: boolean;
  itemId: any;
  previewUrl?: string;
  onEdit?: () => void;
  onPublish?: (itemId?: any) => void;
  onUnpublish?: (itemId?: any) => void;
  onDelete?: (itemId?: any) => void;
}) => {

  return (
    <div className="publishing-item-toolbar">
      {/* eslint-disable */}
      {/* jsx-a11y/anchor-has-content */}
      <a
        className="btn-icon btn-icon-eye"
        href={previewUrl}
        title="Preview"
        target="_blank"
        rel="noopener" />
      {/* eslint-enable */}

      <ButtonIcon
        className="margin-left--sm"
        icon="edit"
        title="Edit"
        onClick={() => onEdit && onEdit()} />

      {isPublished && (
        <>
          <ButtonIcon
            className="margin-left--sm"
            dataTestid="btn-send"
            icon="send"
            title="publish"
            onClick={() => onPublish && onPublish(itemId)} />

          <ButtonIcon
            className="margin-left--sm"
            dataTestid="btn-unpublish"
            icon="unpublish"
            title="Unpublish"
            onClick={() => onUnpublish && onUnpublish(itemId)} />
        </>
      )}

      <ButtonIcon
        className="margin-left--sm"
        dataTestid="btn-delete"
        icon="delete"
        title="Delete"
        onClick={() => onDelete && onDelete(itemId)} />
    </div>
  )
}