import { IStore } from "models"

export enum CurrentStoreActionsEnum {
  UPDATE_CURRENT_STORE = "CURRENT_STORE/UPDATE_CURRENT_STORE",
}

export interface ICustomAction<T, P> {
  (params: P): { type: T, params: P };
};

export const updateCurrentStore: ICustomAction<CurrentStoreActionsEnum, {
  store: IStore,
}> = ({ store }) => {
  return {
    type: CurrentStoreActionsEnum.UPDATE_CURRENT_STORE,
    params: {
      currentStoreId: store?.iStore,
      store,
    }
  }
}