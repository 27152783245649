import './styles.scss';
import React from 'react';
import { IconName } from '@blueprintjs/icons';
import { IconButton } from 'components/_common';

interface IIconButtonGroupProps<T = string> {
  buttonList: Array<{value: T, icon: IconName}>;
  value: T;
  onSelect: (value: T) => void;
}

export const IconButtonGroup =  ({ buttonList, value, onSelect}: IIconButtonGroupProps) => {
  return (
   <>
   {
     buttonList.map((btnObj, indx) => {
       return (
        <IconButton
          key={indx}
          iconType={btnObj.icon}
          value={btnObj.value}
          isSelected={btnObj.value === value}
          onSelect={onSelect}
        />
       )
     })
   }
   </>
  )
}
