import React from 'react';
import { TableHeader } from './Header';
import { Body } from './Body';
import classNames from 'classnames';
import './styles.scss';

export default function Table({ className, children }: {
  className?: string;
  children?: React.ReactNode,
}) {
  const compClass = classNames('table-common', className);

  return <table className={compClass}>{children}</table>;
}

Table.Header = TableHeader;
Table.Body = Body;
