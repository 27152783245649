import {  put, call, takeLatest } from 'redux-saga/effects';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_SAVE_SUCCESS, DEFAULT_ERROR } from 'constants/notifications';
import { saveProduct, getProduct } from 'services/products';
import { IProduct } from 'models';
import {ProductActionsActionsEnum} from './productActions';

function* createProductSaga(action: any) {
  const iUser = action.params.iUser;
  const product = action.params.product as IProduct;

    try {
      const result: unknown = yield call(
        saveProduct, {
          iUser,
          product: {
            ...product,
            tags: product.tags.map(tag => ({ ...tag, iUser })),
          }
        }) as any;

      console.log({result});

      yield put(addNotification(DEFAULT_SAVE_SUCCESS));
      // yield put();
    } catch (error) {
      console.log(error);
      yield put(addNotification(DEFAULT_ERROR));
    }
}

function* loadProductSaga (action: any) {
  const iUser = action.params.iUser;
  const iProduct = action.params.iProduct;

  try {
    const result: unknown = yield call(
      getProduct, {
        iUser,
        iProduct
      }) as any;

    console.log({result});

    yield put(addNotification(DEFAULT_SAVE_SUCCESS));
    // yield put();
  } catch (error) {
    console.log(error);
    yield put(addNotification(DEFAULT_ERROR));
  }
}

export function* productsSagas() {
  yield takeLatest(ProductActionsActionsEnum.PRODUCTS_SAVE_PRODUCT, createProductSaga);
  yield takeLatest(ProductActionsActionsEnum.PRODUCTS_LOAD_PRODUCT, loadProductSaga);
}