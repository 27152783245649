import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export default function Textarea(props) {
  const compClass = classNames(props.className, 'form-control', {
    'form-control--rounded': props.isRounded,
  });

  return (
    <textarea
      className={compClass}
      id={props.id}
      name={props.name}
      style={props.style}
      rows={props.row}
      maxLength={props.maxLength}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      title={props.title}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      value={props.value || ''}
      onChange={props.onChange}
    />
  );
}
