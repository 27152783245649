import React from 'react';
import Img from 'react-image';
import { Spinner } from '../Spinner';

export const LazyImage = ({
  src,
  style = {},
  className = '',
  alt = '',
}: {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
}) => {
  return (
    <Img
      style={style}
      className={className}
      src={src}
      alt={alt}
      loader={
        <Spinner className={className || ''} />
      }
    />
  );
};
