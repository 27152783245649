import { IStripeProduct, IStripeAccount } from 'models';
import { getCall, postCall } from '../service';

type UserIdProps = { userId: string };

export function getProductsPrices() {
  return getCall('/api/products/listWithPrices').then((res) => {
    return res.products;
  });
}

export function getPortalRedirectUrl({ userId }: UserIdProps) {
  return postCall('/api/stripe/portalUrl', { userId });
}

export function createCheckoutSession({ userId, products }: UserIdProps & { products: IStripeProduct[] }) {
  const priceIds = products?.map((prd) => prd?.price?.id);

  return postCall('/api/stripe/createCheckoutSession', { userId, priceIds })
    .then((res) => {
      return res.session;
    });
}

export function getPromoCodeByUserId({ userId }: UserIdProps) {
  return getCall('/api/stripe/getPromoCodeByUserId', { userId })
    .then((res) => {
      return res.promoCode;
    });
}

export function getPromoCodeInfoByCode({ userId, promoCode }: UserIdProps & { promoCode: string }) {
  return getCall('/api/stripe/getPromoCodeInfoByCode', { userId, promoCode })
    .then((res) => {
      return res.promoCode;
    });
}

export function getFullCouponUsage({ userId }: UserIdProps) {
  return getCall('/api/stripe/getFullCouponUsage', { userId })
    .then((res) => {
      return res.promoCodeInfo;
    });
}

export function getAccountLoginUrl({ userId }: UserIdProps): Promise<{redirectUrl:string}> {
  return postCall('/api/stripe/getAccountLoginUrl', { userId });
}

export function getAccountInfo({ userId }: UserIdProps): Promise<IStripeAccount> {
  return getCall('/api/stripe/getAccountInfo', { userId })
    .then((res) => res?.accountInfo);
}