import React from 'react';
import _debounce from 'lodash.debounce';
import { getStockImagesByKeyword } from 'services/unsplash';
import { Paging, Spinner, Modal, Button, Input } from 'components/_common';
import { MediaTile } from '../MediaTile';
import { MEDIA_PAGE_SIZES } from 'constants/campaign';

export class StockImages extends React.Component {
  state = {
    mediaFiles: [],
    selectedFile: undefined,
    pageSize: MEDIA_PAGE_SIZES[0].value,
    currentPage: 0,
    totalCount: 0,
    isLoading: false,
    searchText: 'Mountains',
  };

  getMediaFiles = () => {
    const { searchText, pageSize, currentPage } = this.state;
    this.setState({ isLoading: true });

    getStockImagesByKeyword({
      keyword: searchText,
      page: currentPage,
      limit: pageSize,
    })
      .then(this.setMediaFiles)
      .catch((error) => console.error(error))
      .finally(() => this.setState({ isLoading: false }));
  };

  handleSearch = _debounce(this.getMediaFiles, 600);

  setMediaFiles = (resp) => {
    const files = resp?.results;
    const totalCount = resp?.total;
    const thumbnails =
      files &&
      files.map((file) => ({
        ...file,
        thumbUrl: file.urls.thumb,
      }));

    this.setState({
      mediaFiles: thumbnails,
      totalCount,
    });
  };

  onNextClickHandler = () => {
    const { currentPage } = this.state;
    const newCurrentPage = currentPage + 1;

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      this.getMediaFiles
    );
  };

  onPrevClickHandler = () => {
    const { currentPage } = this.state;
    const newCurrentPage = Math.max(currentPage - 1, 0);

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      this.getMediaFiles
    );
  };

  onPageSizeChangeHandler = (pageSize) => {
    this.setState(
      {
        pageSize: parseInt(pageSize, 0),
        currentPage: 0,
        isLoading: true,
      },
      this.getMediaFiles
    );
  };

  componentDidMount() {
    this.getMediaFiles();
  }

  saveImage() {
    const { selectedFile } = this.state;

    this.props.onSave({
      userId: this.props.userId,
      name: selectedFile.description || 'Unsplash Image',
      url: selectedFile.urls.full,
      s3Key: selectedFile.id,
      contentType: 'image/jpg',
      altText: selectedFile.alt_desctiption,
      unsplashFile: {
        id: selectedFile.id,
        links: { ...selectedFile.links },
        user: { ...selectedFile.user },
      },
    });
  }

  render() {
    const {
      isLoading,
      mediaFiles = [],
      currentPage,
      totalCount,
      pageSize,
      selectedFile,
      searchText,
    } = this.state;

    return (
      <div>
        <Input
          isRounded
          required
          className="margin-bottom--lg"
          placeholder="Search images by keyword"
          value={searchText}
          onChange={(e) => this.setState({ searchText: e.target.value }, this.handleSearch)}
        />

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="media-modal__body">
              {!mediaFiles.length ? (
                <div className="text-center stretch-block">No media files</div>
              ) : (
                mediaFiles.map((mediaFile) => (
                  <MediaTile
                    isDeletable={false}
                    key={mediaFile.id}
                    mediaFile={mediaFile}
                    isSelected={selectedFile?.id === mediaFile.id}
                    onSelect={() => this.setState({ selectedFile: mediaFile })}
                    onChooseMedia={() =>
                      this.setState({ selectedFile: mediaFile }, () => this.saveImage())
                    }
                    onDelete={() => this.onDeleteHandler(mediaFile)}
                  />
                ))
              )}
            </div>

            <Modal.Footer className="media-modal__footer">
              <Paging
                className="media-modal__paging"
                labelText="Files per page:"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                options={MEDIA_PAGE_SIZES}
                onPageSizeChange={this.onPageSizeChangeHandler}
                onPrevClick={this.onPrevClickHandler}
                onNextClick={this.onNextClickHandler}
              />
              <div>
                <Button
                  className="btn-grey margin-right--sm"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>

                <Button
                  isPrimary
                  type="button"
                  onClick={() => {
                    if (selectedFile) {
                      this.saveImage();
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </div>
    );
  }
}
