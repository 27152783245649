import { IStoreState } from 'models/IStoreState';
import { CurrentStoreActionsEnum } from './currentStoreActions';

export default function currentStore(state = {} as IStoreState, { type, params }: { type: CurrentStoreActionsEnum, params: any }) {

  switch (type) {
    case CurrentStoreActionsEnum.UPDATE_CURRENT_STORE:
      return {
        ...state,
        store: params.store,
        currentStoreId: params.currentStoreId,
      };

    default:
      return state;
  }
}