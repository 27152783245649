import styled, { keyframes } from 'styled-components';
import { COLOR_PRIMARY } from 'constants/theme';

const changeWidth = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const LoadingTopBar = styled.div`
  &:before,
  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  &:before {
    z-index: 999;
    background-color: ${COLOR_PRIMARY};
    animation-name: ${changeWidth};
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.98, 0.04, 0.58, 1);
  }

  &:after {
    z-index: 998;
    background-color: #e9e9f0;
  }
`;
