import { IStore } from 'models/IStore';
import { postCall, getCall } from '../service';

export function getStoreListByIUser(iUser: string): Promise<IStore[]> {
  return getCall('/api/store/getStoreListByIUser', { iUser })
    .then((res) => res.list || [])
    .then((list) => list.map((i: any) => ({
      ...i,
      config: i.config || {},
      unsplashUserInfo: i.unsplashUserInfo || {},
    })));
}

export function getStoreByIds(iUser: string, iStore: number) {
  return getCall('/api/store/getStoreByIds', { iUser, iStore })
    .then((res) => res.list || []);
}

export function createStore(iUser: string, store: Partial<IStore> & { name: IStore["name"] }) {
  return postCall('/api/store/createStore', { iUser, store })
    .then((response: any) => response?.result as IStore);
}

export function updateStore(iUser: string, store: IStore) {
  return postCall('/api/store/updateStore', {
    iUser,
    store: {
      ...store,
      config: JSON.stringify(store.config),
      unsplashUserInfo: JSON.stringify(store.unsplashUserInfo),
    }
  });
}

export function deleteStore(iUser: string, iStore: IStore['iStore']) {
  return postCall('/api/store/deleteStore', { iUser, iStore });
}

export function publishStore(iUser: string, iStore: IStore['iStore']) {
  return postCall('/api/store/publishStore', { iUser, iStore, isPublised: true });
}

export function unpublishStore(iUser: string, iStore: IStore['iStore']) {

  return postCall('/api/store/unpublishStore', { iUser, iStore, isPublised: false });
}

export function addProductToStore({
  iUser,
  iProduct,
  iStore,
}: {
  iUser: string;
  iProduct: number;
  iStore: number;
}) {
  return postCall('/api/store/addProductToStore', { iUser, iProduct, iStore })
}

export function deleteProductFromStore({
  iUser,
  iProduct,
  iStore,
}: {
  iUser: string;
  iProduct: number;
  iStore: number;
}) {
  return postCall('/api/store/deleteProductFromStore', { iUser, iProduct, iStore })
}