import { SENDER_TYPE } from 'constants/campaign';
import { CAMPAIGN_TYPE } from 'constants/campaign';
import { DEAFULT_SMS_PARAMS_WITH_STORY } from 'constants/sms';

const SENDER_UPDATE = 'SENDER/UPDATE';
const SENDER_UPDATE_EMAIL_TEMPLATE_ID = 'SENDER/UPDATE_EMAIL_TEMPLATE_ID';
const SENDER_UPDATE_EMAIL_PARAMS = 'SENDER/UPDATE_EMAIL_PARAMS';
const SENDER_UPDATE_SMS_PARAMS = 'SENDER/UPDATE_SMS_PARAMS';
const SENDER_UPDATE_SCHEDULE_TYPE = 'SENDER_UPDATE_SCHEDULE_TYPE';
const SENDER_UPDATE_TYPE = 'SENDER_UPDATE_TYPE';
const SENDER_UPDATE_SCHEDULE_DATE = 'SENDER_UPDATE_SCHEDULE_DATE';
const SENDER_MARK_AS_DONE = 'SENDER_MARK_AS_DONE';

const DEFAULT_SENDER = {
  smsParams: {
    name: 'Summer Campaign',
    message: DEAFULT_SMS_PARAMS_WITH_STORY.message,
  },
  emailParams: {
    name: 'Summer Campaign',
    subject: 'Greetings from Panels!',
  },
};

export function markSenderAsDone() {
  return {
    type: SENDER_MARK_AS_DONE,
  };
}

export function senderUpdateEmailTemplateId({ templateId, type }) {
  return {
    type: SENDER_UPDATE_EMAIL_TEMPLATE_ID,
    params: { templateId, templateType: type },
  };
}

export function senderUpdateSmsParams(data) {
  return {
    type: SENDER_UPDATE_SMS_PARAMS,
    params: data,
  };
}
export function senderUpdateEmailParams(data) {
  return {
    type: SENDER_UPDATE_EMAIL_PARAMS,
    params: data,
  };
}

export function senderUpdateTemplate({ template }) {
  return {
    type: SENDER_UPDATE,
    params: { template },
  };
}

export function senderUpdateScheduledType({ scheduleType }) {
  return {
    type: SENDER_UPDATE_SCHEDULE_TYPE,
    params: { scheduleType },
  };
}

export function senderUpdateScheduledDate({ date }) {
  return {
    type: SENDER_UPDATE_SCHEDULE_DATE,
    params: { date },
  };
}

export function senderUpdateType({ senderType }) {
  return {
    type: SENDER_UPDATE_TYPE,
    params: { senderType },
  };
}

const initState = {
  isDone: false,
  template: null,
  senderType: CAMPAIGN_TYPE.SMS,
  smsParams: { ...DEFAULT_SENDER.smsParams },
  emailParams: null,
  scheduleType: SENDER_TYPE.SCHEDULE,
  date: undefined,
};

export default function story(state = initState, action) {
  switch (action.type) {
    case SENDER_UPDATE_EMAIL_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.params?.templateId,
        templateType: action.params?.templateType,
      };

    case SENDER_UPDATE_EMAIL_PARAMS:
      return {
        ...state,
        emailParams: {
          ...state.emailParams,
          ...action.params,
        },
      };
    case SENDER_UPDATE_SMS_PARAMS:
      return {
        ...state,
        smsParams: {
          ...state.smsParams,
          ...action.params,
        },
      };

    case SENDER_UPDATE_SCHEDULE_DATE:
      return {
        ...state,
        date: action.params.date,
      };

    case SENDER_UPDATE_TYPE:
      const senderType = action.params.senderType;

      if (senderType === CAMPAIGN_TYPE.SMS) {
        return {
          ...state,
          isDone: false,
          senderType,
          emailParams: null,
          smsParams: { ...DEFAULT_SENDER.smsParams },
        };
      }

      return {
        ...state,
        isDone: false,
        senderType: action.params.senderType,
        smsParams: null,
        emailParams: { ...DEFAULT_SENDER.emailParams },
      };

    case SENDER_UPDATE:
      return {
        ...state,
        template: action.params.template,
      };

    case SENDER_UPDATE_SCHEDULE_TYPE:
      return {
        ...state,
        scheduleType: action.params.scheduleType,
      };
    case SENDER_MARK_AS_DONE:
      return {
        isDone: true,
      };

    default:
      return state;
  }
}
