
import { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useUserId } from 'hooks';
import { getCampaignsByStoryId } from 'services/campaign';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { makePromiseCancelable, TReturnMakePromiseCancelable } from 'services/service';

type TFilter = {
  selectedStoryId?: string | undefined;
  dateRange: Date[];
}

export const useCampaignReport = ({
  initPage = 0,
  initPageSize = 10,
  initSelectedStoryId = undefined,
  initSortingKey = '',
}: {
  initPage?: number;
  initPageSize?: number;
  initSelectedStoryId?: string | undefined;
  initSortingKey?: string;
}) => {
  const dispatch = useDispatch();
  let getCampaignsByStoryIdCancelable = useRef<null | TReturnMakePromiseCancelable>(null);

  const userId = useUserId();
  const [pageNumber, setPageNumber] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [filter, setFilter] = useState<TFilter>({
    selectedStoryId: initSelectedStoryId,
    dateRange: [moment().add(-1, 'month').toDate(), moment().toDate()],
  });
  const [sortingOptions, setSortingOptions] = useState({
    direction: 'asc',
    sortingKey: initSortingKey,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState(null);
  const [total, setTotal] = useState(0);

  const loadCampaigns = useCallback(async () => {
    setIsLoading(true);

    try {
      getCampaignsByStoryIdCancelable.current = makePromiseCancelable(getCampaignsByStoryId({
        storyId: filter.selectedStoryId,
        dateRange: filter.dateRange,
        userId,
        direction: sortingOptions.direction,
        sortingKey: sortingOptions.sortingKey,
        pageNumber,
        pageSize,
      }));

      const promiseRes = await getCampaignsByStoryIdCancelable.current;
      const res = await promiseRes.promise;

      setCampaigns(res?.items || []);
      setTotal(res?.total || 0);
      setIsLoading(false);

    } catch (err) {
      console.error('useCampaignReport', err);
      dispatch(addNotification(DEFAULT_ERROR));
      setIsLoading(false);

    }
  }, [userId, pageSize, pageNumber, filter, sortingOptions, dispatch]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    loadCampaigns();

    return () => {
      if (getCampaignsByStoryIdCancelable.current?.cancel) {
        getCampaignsByStoryIdCancelable.current.cancel()
      }
    };
  }, [loadCampaigns, userId]);

  return {
    isLoading,
    campaigns,
    total,
    filter,
    sortingOptions,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    setFilter,
    setSortingOptions,
    loadCampaigns,
  };
};
