import { view, set } from 'lens/utils';
import storyLens from 'lens/story';
import { normalizeChart } from 'utils/aws';
import { getTimestamp } from 'utils/date';
import { postCall } from './service';

export function putStory(storyRaw, params) {
  const storyWithDateUpdated = set(storyLens.dateUpdated, getTimestamp(), storyRaw);
  const story = normalizeStory(storyWithDateUpdated);

  return postSaveStory(story, params);
}

export function getStoryById(id, userId) {
  console.log('storyId', id);
  return postCall('/api/story/retrieve', { id, userId });
}

export function deleteStory(story) {
  const deletedStory = set(storyLens.dateDeleted, getTimestamp(), story);

  return postCall('/api/story/delete', { story: deletedStory });
}

export function getUserStories(userId) {
  return postCall('/api/story/list', { userId });
}

function postSaveStory(story, params) {
  return postCall('/api/story/save', { story, params });
}

function normalizeStory(story) {
  const chart = view(storyLens.chartRaw, story);
  const safeChart = normalizeChart(chart);

  return set(storyLens.chartRaw, safeChart, story);
}

export function publishStory(story) {
  const datePublished = view(storyLens.datePublished, story);
  // if there is `datePublished`, do not reset it
  if (!datePublished) {
    const timestamp = getTimestamp();
    story = set(storyLens.datePublished, timestamp, story);
  }

  const isNewPublish = !datePublished;
  return putStory(story, { isNewPublish });
}

export function unpublishStory(story) {
  const unpublishedStory = set(storyLens.datePublished, null, story);
  const isUnpublishing = true;

  return putStory(unpublishedStory, { isUnpublishing });
}
