import Papa from 'papaparse';

export function parseCsvFile(file: File) {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject('Missing input data');
    }

    Papa.parse(file, {
      comments: '#',
      header: true,
      skipEmptyLines: 'greedy',
      complete: (response: any) => resolve((response?.data) || undefined),
      error: (error: unknown) => reject(error),
      transform: (value: any) => (value ? String(value).trim() : value),
    });
  });
}

export function parseAndDownloadCsv(data: any, fileName: string) {
  const csv = Papa.unparse(data);

  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${fileName}.csv`;
  hiddenElement.click();
}
