import _isEmpty from 'lodash.isempty';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { DateRange as DateRangeType } from '@blueprintjs/datetime/lib/esm/common/dateRange';

export function filterDeletedItems(list: any[] = [], deletedIds: any[] = []) {
  if (!deletedIds?.length) {
    return list;
  }

  return list.filter((item) => deletedIds.indexOf(item.id) === -1);
}

export function addCurlyBraces(str?: string) {
  if (!str) {
    return '{{ }}';
  }

  return `{{ ${str} }}`;
}

export function removeEmptyValuesFromObject(obj: any) {
  const result = {} as Record<string, any>;

  // why does it happen? :|
  // eslint-disable-next-line
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      result[key] = obj[key];
    }
  }

  return result;
}

export function stringify(obj: any) {
  if (!obj || _isEmpty(obj)) {
    return undefined;
  }

  return JSON.stringify(obj);
}

export function generateSelectOptionsByName(list: Array<{id?: string, name?: string}> ) {
  if (!list || !list.length) {
    return [];
  }

  return list.map((item) => ({ value: item.id, label: item.name }));
}

export function getStoryName(stories: any[], storyId: string) {
  const story = stories.find((i) => i.value === storyId);
  return story?.label;
}

export function getErrorMessage(err: {message: string}) {
  return (err && err.message) || '';
}

export function getStaticResourceUrl(path: string) {
  return process.env.PUBLIC_URL + path;
}

export function getOffsetLimitByPage(pageSize: number, pageNumber = 0) {
  return {
    offset: pageSize * pageNumber,
    limit: pageSize,
  };
}

export function isValidPhoneNumber(phoneNumber: string) {
  console.log({
    us: parsePhoneNumberFromString(phoneNumber, 'US').isPossible(),
    pl: parsePhoneNumberFromString(phoneNumber, 'PL').isPossible(),
  });
  const usNumber = phoneNumber && parsePhoneNumberFromString(phoneNumber, 'US');
  const plNumber = phoneNumber && parsePhoneNumberFromString(phoneNumber, 'PL');
  return usNumber && usNumber.isPossible()
    ? usNumber
    : plNumber && plNumber.isPossible()
    ? plNumber
    : false;
}

export function delay(t = 2000, v: any) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

export function isValidUrl(url = '') {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
}

export function isDateRangeValid(dateRange: DateRangeType) {
  return !dateRange[0] === !dateRange[1];
}

export function isValidPromoCode(code: string) {
  return code && code.length > 2 && code !== 'null';
}

export function getBgUrl(src: string) {
  return src ? `url(${src})` : '';
}
