import { postCall, getCall, deleteCall } from './service';
import { getTimestamp } from 'utils/date';

export function saveTemplate({ userId, name, type = 'email', template, description }) {
  return postCall('/api/template/save', {
    userId,
    name,
    type,
    template,
    description,
    lastUpdated: getTimestamp(),
  });
}

export function updateTemplate({ userId, templateId, template, description }) {
  return postCall('/api/template/update', {
    userId,
    templateId,
    template,
    description,
    lastUpdated: getTimestamp(),
  });
}

export function getTemplatesByUserId({ userId }) {
  return getCall('/api/template/getTemplatesByUserId', { userId });
}

export function deleteTemplateFromDB({ userId, templateId }) {
  return deleteCall('/api/template/deleteTemplateFromDB', { userId, templateId });
}
