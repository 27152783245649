import { REPORTS_UPDATE_STORY_ID } from './reportsActions';

const initialState = {
  storyId: undefined,
};

export default function reports(state = initialState, { type, params }) {
  switch (type) {
    case REPORTS_UPDATE_STORY_ID:
      return {
        ...state,
        storyId: params.storyId,
      };
    default:
      return state;
  }
}
