import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isActiveStory } from 'utils/story';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getUserStories, deleteStory, getStoryById, unpublishStory } from 'services/story';
import { IStory } from 'models';

export const useStories = () => {
  const isDetached = useRef(false);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [stories, setStories] = useState<IStory[]>([]);
  const dispatch = useDispatch();

  const stopLoading = useCallback(() => {
    if (isDetached.current) return;
    setIsLoading(false);
  }, []);

  const onErrorHandler = useCallback((error) => {
    console.error('[useStories]', error);
    if (isDetached.current) return;

    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [dispatch]);

  const loadStories = useCallback(() => {
    setIsLoading(true);
    getUserStories(userId)
      .then((storyList) => {
        if (isDetached.current) return null;

        const activeStories = filterOutDeleted(storyList);
        setStories(activeStories);
      })
      .catch((error) => console.error(error))
      .finally(stopLoading);
  }, [userId, stopLoading]);

  const deleteStoryHandler = useCallback(
    (story, onError, onSuccess) => {
      setIsLoading(true);
      deleteStory(story)
        .then(() => {
          if (isDetached.current) return null;

          loadStories();
          onSuccess && onSuccess();
        })
        .catch((error) => {
          onErrorHandler(error);
          onError && onError(error);
        });
    },
    [loadStories, onErrorHandler]
  );

  const unpublishStoryHandler = useCallback(
    (story, onError, onSuccess) => {
      setIsLoading(true);
      unpublishStory(story)
        .then(() => {
          if (isDetached.current) return null;

          loadStories();
          onSuccess && onSuccess();
        })
        .catch((error) => {
          onErrorHandler(error);
          onError && onError(error);
        });
    },
    [loadStories, onErrorHandler]
  );

  const getStoryByIdHandler = useCallback(
    (storyId, onError, onStoryLoad) => {
      setIsLoading(true);
      getStoryById(storyId, userId)
        .then((story) => {
          if (isDetached.current) return null;

          onStoryLoad && onStoryLoad(story);
        })
        .catch((error) => {
          onErrorHandler(error);
          onError && onError(error);
        })
        .finally(stopLoading);
    },
    [userId, onErrorHandler, stopLoading]
  );

  useEffect(() => {
    if (!isDetached.current) {
      loadStories();
    }

    return () => {
      isDetached.current = true;
    };
  }, [userId, loadStories]);

  return {
    stories,
    isLoading,
    loadStories,
    deleteStory: deleteStoryHandler,
    getStoryById: getStoryByIdHandler,
    unpublishStory: unpublishStoryHandler,
  };
};

function filterOutDeleted(list: IStory[]) {
  if (list) {
    return list.filter(isActiveStory);
  }
}
