import { CampaignTypeEnum } from 'constants/campaign';
import { postCall, deleteCall, getCall } from './service';
import { TCampaignFlowTemplate } from 'models/CampaignFlowBuilder/TCampaignFlowTemplate';

export function getCampaignFlowByCampaignId(campaignId: string) {
  return getCall('/api/flowBuilder/getCampaignFlowByCampaignId', { campaignId })
    .then(res => {

      return {
        campaignFlowId: res?.id,
        campaignId: res?.campaignId,
        chart: res && res.chart ? JSON.parse(res.chart) : undefined,
        templates: res && res.templates ? JSON.parse(res.templates) : undefined,
      };
    });
}

export function saveCampaignFlowForStory(data: {
  userId: string,
  type: CampaignTypeEnum,
  contactListId: number,
  storyId: string,
  name: string,
  dateScheduled: number,
  chart: any,
  templates: any,
  doRunForNewContacts: boolean,
}) {

  return postCall('/api/flowBuilder/saveFlowBuilderCampaign', data);
};

export function updateCampaignFlow(data: {
  campaignId: string,
  userId: string,
  storyId: string,
  type: CampaignTypeEnum,
  contactListId: number,
  name: string,
  campaignFlowId: number,
  chart: any,
  templates: any,
  doRunForNewContacts: boolean,
}) {

  return postCall('/api/flowBuilder/updateFlowBuilderCampaign', data);
};

export function getDeliveredMessages({
  campaignId,
  nodeId,
  type,
}: {
  campaignId: number,
  nodeId: string,
  type: 'sms' | 'email',
}) {
  return postCall('/api/flowBuilder/getDeliveredMessages', {
    campaignId,
    nodeId,
    type,
  });
}

export function getFailedMessages({
  campaignId,
  nodeId,
  type,
}: {
  campaignId: number,
  nodeId: string,
  type: 'sms' | 'email',
}) {
  return postCall('/api/flowBuilder/getFailedMessages', {
    campaignId,
    nodeId,
    type
  });
}

export function getContacts({
  contactIds
}: {
  contactIds: number[],
}) {
  return postCall('/api/flowBuilder/getContactsInfo', {
    contactIds
  });
}

export function getTemplatesByUserId({
  iUser,
}: {
  iUser: string,
}): Promise<{ templates: TCampaignFlowTemplate[] }> {
  return getCall('/api/flowBuilder/getTemplatesByUserId', { iUser })
    .then((res: any[]) => ({
      templates: res.map(i => ({
        ...i,
        chart: JSON.parse(i.chart),
        templates: JSON.parse(i.templates),
      })),
    }));
}

export function deleteCampaignFlowTemplate({
  iCampaignFlowTemplate,
}: {
  iCampaignFlowTemplate: string,
}) {
  return deleteCall('/api/flowBuilder/deleteCampaignFlowTemplate', { iCampaignFlowTemplate });
}

export function saveFlowTemplate({
  iUser,
  label,
  description,
  chart,
  templates,
}: Omit<TCampaignFlowTemplate, 'iCampaignFlowTemplate'>) {
  return postCall('/api/flowBuilder/saveFlowTemplate', {
    iUser,
    label,
    description,
    chart: JSON.stringify(chart),
    templates: JSON.stringify(templates),
  });
}
