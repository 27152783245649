import React, { useState } from 'react';
import classNames from 'classnames';
import ButtonIcon from '../ButtonIcon';
import Input from '../Input';
import './styles.scss';

export const TextEditable = ({ value, className, isTitleEditable = true, onSave }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const clsNames = classNames(className, 'text-editable', {
    'text-editable--view': !isEdit,
    'text-editable--edit': isEdit,
  });

  return (
    <div className={clsNames}>
      {!isEdit && (
        <>
          <span className="text-editable__text-value">{value}</span>
          {isTitleEditable && (
            <ButtonIcon
              className="margin-left--sm"
              icon="edit"
              title="Edit name"
              onClick={() => setIsEdit(true)}
            />
          )}
        </>
      )}
      {isEdit && (
        <>
          <Input
            className="text-editable__input"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />

          <ButtonIcon
            className="margin-left--sm"
            icon="save"
            title="Update name"
            onClick={() => {
              setIsEdit(false);
              onSave(newValue);
            }}
          />
        </>
      )}
    </div>
  );
};

export default TextEditable;
