import { useCallback, useState, useEffect, useRef } from 'react';
import _debounce from 'lodash.debounce';

import { isVideo, getImageThumbnail, IMAGE_SIZES, getImagePath } from 'utils/media';
import { delay } from 'utils/common';
import Storage from '@aws-amplify/storage';
import { getFileKey } from 'utils/media';
import {
  getUserMediaFiles,
  updateMediaFile,
  deleteMediaFileFromDB,
  deleteMediaFileFromS3,
  saveMediaFile,
} from 'services/media';
import { getOffsetLimitByPage } from 'utils/common';

export const useMediaFiles = ({ userId, initPage = 0, initPageSize = 9, initTextSearch = '' }) => {
  const isDetached = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);

  const [currentPage, setCurrentPage] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [filter, setFilter] = useState(initTextSearch);

  const [files, setFiles] = useState([]);

  const setFilterQuery = useCallback(
    _debounce((filterText) => setFilter(filterText), 500),
    []
  );

  const stopLoading = useCallback(() => {
    // if (isDetached.current) return;
    setIsLoading(false);
    setIsUploading(false);
  }, []);

  const loadFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getUserMediaFiles({
        userId,
        ...getOffsetLimitByPage(pageSize, currentPage),
        filter,
      });

      // if (isDetached.current) return;

      const files = Array.isArray(res.files)
        ? res.files.map((file) => ({
            ...file,
            thumbUrl: isVideo(file.contentType)
              ? file.url
              : getImageThumbnail(file.url, IMAGE_SIZES.M),
          }))
        : [];

      stopLoading();
      setFiles(files);
      setTotalFiles(res.total);
    } catch (err) {
      console.error('SOME ERROR', err);
    }
  }, [userId, pageSize, currentPage, filter, stopLoading]);

  const deleteFile = useCallback(
    async (file) => {
      setIsLoading(true);
      deleteMediaFileFromDB({ userId, file })
        .then(() => deleteMediaFileFromS3(file))
        .then(() => {
          // if (isDetached.current) return;
          loadFiles();
        })
        .catch((error) => console.error(error))
        .finally(stopLoading);
    },
    [userId, loadFiles, stopLoading]
  );

  const updateFile = useCallback(
    async (file) => {
      setIsLoading(true);
      return updateMediaFile({ userId, fileId: file.id, name: file.name, altText: file.altText })
        .then(() => {
          if (isDetached.current) return;
          loadFiles();
        })
        .catch((error) => console.error(error))
        .finally(stopLoading);
    },
    [userId, loadFiles, stopLoading]
  );

  const uploadFile = useCallback(
    async (file) => {
      const { type: contentType, name } = file;
      const s3Key = getFileKey(userId, name);

      setIsUploading(true);
      Storage.put(s3Key, file, { contentType })
        .then((response) => {
          if (isDetached.current) return;
          const mediaFile = {
            name,
            contentType,
            altText: undefined,
            url: getImagePath(response.key),
            s3Key: response.key,
            userId: userId,
          };

          return saveMediaFile(mediaFile);
        })
        // Add small timeout for image processing
        .then(delay)
        .then(loadFiles)
        .catch((error) => console.error(error))
        .finally(stopLoading);
    },
    [userId, loadFiles, stopLoading]
  );

  useEffect(() => {
    loadFiles();

    return () => {
      // TODO: fix issue with detaching
      // isDetached.current = true;
    };
  }, [loadFiles, userId, pageSize, currentPage, filter]);

  return {
    isLoading,
    isUploading,
    totalFiles,
    pageSize,
    currentPage,

    files,

    setPageSize,
    setCurrentPage,
    setFilterQuery,
    updateFile,
    deleteFile,
    uploadFile,
  };
};
