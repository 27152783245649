import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './styles.scss';

// _NOTICE_ Use <Dialog /> instead.
// <Modal /> is deprecated
// (we dont want to maintain this custom written component)

const modalRoot = document.getElementById('modal-root');
const openClassName = 'modal-open';

export default class Modal extends React.Component {
  static Header = Header;
  static Content = Content;
  static Footer = Footer;

  constructor(props) {
    super(props);

    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.classList.add(openClassName);
    modalRoot.appendChild(this.el);
    // track keydowns to find ESC to close the modal
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    modalRoot.classList.remove(openClassName);
    modalRoot.removeChild(this.el);
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.closeHandler();
    }
  };

  closeHandler = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const className = classNames(this.props.className, 'modal');

    return ReactDOM.createPortal(
      <div className={className}>
        <div className="modal__back" onClick={this.closeHandler} />
        <div className="modal__body">{this.props.children}</div>
      </div>,
      this.el
    );
  }
}

function Header({ className, children }) {
  const compClass = classNames(className, 'modal__header');

  return <div className={compClass}>{children}</div>;
}

function Content({ className, children }) {
  const compClass = classNames(className, 'modal__content');

  return <div className={compClass}>{children}</div>;
}

function Footer({ className, children }) {
  const compClass = classNames(className, 'modal__footer');

  return <div className={compClass}>{children}</div>;
}
