import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getAccountInfo } from 'services/v2/stripe';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { useUserId } from './useUserId';

export const useStripeAccount = () => {
  const isDetached = useRef(false);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState<any>(null);

  const dispatch = useDispatch();
  const onErrorHandler = useCallback(() => {
    if (isDetached.current) return;
    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [isDetached, dispatch]);

  const loadAccountInfo = useCallback(async () => {
    setIsLoading(true);

    try {
      if (!userId) {
        console.log('useStripeAccount loadAccountInfo::user not defined')
        return;
      }

      const info = await getAccountInfo({ userId });
      if (isDetached.current) return;

      setIsLoading(false);
      setAccountInfo(info);
    } catch (err) {
      setIsLoading(false);
      console.error('useStripeAccounts', err);
      if (err !== 'User Not Found') {
        onErrorHandler();
      }
    }
  }, [userId, isDetached, onErrorHandler]);

  useEffect(() => {
    loadAccountInfo();

    return () => { isDetached.current = true; };
  }, [userId, loadAccountInfo]);

  return {
    isLoading,
    accountInfo
  };
};
