import { CampaignFlowActionsEnum } from './campaignFlowActions';
import { TNode } from 'components/CampaignFlowBuilder/constants';

export type TEmailTemplate = {
  design: any;
  html: string;
}

export type TEmailParams = {
  name: string;
  contactListId: number;
  source: string;
  medium: string;
  subject: string;
  fromName: string;
  fromEmail: string;
}

export type TTemplate = {
  template?: TEmailTemplate;
  templateId?: string;
  templateType?: string;
  params?: TEmailParams;
}

export type TCampaignFlowEmailTemplates = {
  [nodeId: string]: TTemplate,
}

export type TCampaignFlowState = {
  links: any;
  nodes: Record<string, TNode>;
  campaignFlowId: number;
  isLoading: boolean;
  nodeId: string;
  templates: TCampaignFlowEmailTemplates;
}

export type TCampaignFlows = {
  [campaignId: string]: TCampaignFlowState,
}

export class CampaignFlowState {
  campaignFlows: TCampaignFlows = {};
};

export default function campaignFlow(state = new CampaignFlowState(), { type, params }: { type: CampaignFlowActionsEnum, params: any }) {

  switch (type) {
    case CampaignFlowActionsEnum.UPDATE_CHART:
      return {
        ...state,
        campaignFlows: {
          ...state.campaignFlows,
          [params.campaignId]: {
            ...state.campaignFlows[params.campaignId],
            links: params.links,
            nodes: params.nodes,
            campaignFlowId: params.campaignFlowId ? params.campaignFlowId : state.campaignFlows[params.campaignId]?.campaignFlowId,
            isLoading: params.isLoading,
          },
        },
      };
    case CampaignFlowActionsEnum.UPDATE_TEMPLATE:
      return {
        ...state,
        campaignFlows: {
          ...state.campaignFlows,
          [params.campaignId]: {
            ...state.campaignFlows[params.campaignId],
            templates: {
              ...state.campaignFlows[params.campaignId].templates,
              [params.nodeId]: params.template,
            }
          }
        },
      };
    case CampaignFlowActionsEnum.UPDATE_TEMPLATES:
      return {
        ...state,
        campaignFlows: {
          ...state.campaignFlows,
          [params.campaignId]: {
            ...state.campaignFlows[params.campaignId],
            templates: params.templates,
          }
        },
      };
    case CampaignFlowActionsEnum.REMOVE_CACHE:
      return {
        ...state,
        campaignFlows: {
          ...state.campaignFlows,
          [params.campaignId]: undefined,
        },
      };
    default:
      return state;
  }
}
