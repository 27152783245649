import { postCall } from './service';
import { parseAndDownloadCsv } from 'utils/csv';
import { formatDate } from 'utils/date';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getStoryName } from 'utils/common';

export function getViewsByStoryId({
  storyId,
  dateRange,
  direction,
  sortingKey,
  pageNumber,
  pageSize,
}) {
  return postCall('/api/clientSession/getViewsByStoryId', {
    storyId,
    dateRange,
    direction,
    sortingKey,
    pageNumber,
    pageSize,
  });
}

export function getViewsByCampaignId({
  storyId,
  campaignId,
  dateRange,
  direction,
  sortingKey,
  pageNumber,
  pageSize,
}) {
  return postCall('/api/clientSession/getViewsByCampaignId', {
    storyId,
    campaignId,
    dateRange,
    direction,
    sortingKey,
    pageNumber,
    pageSize,
  });
}

const getContactFullName = (item) => {
  const { contactFirstName, contactLastName } = item || {};

  if (contactFirstName && contactLastName) {
    return `${contactFirstName} ${contactLastName}`;
  }

  return contactFirstName || contactLastName || '';
};

export async function downloadUniqueUserSessions({
  storyId,
  campaignId,
  dateRange,
  direction,
  sortingKey,
  addNotification,
}) {
  try {
    const result = campaignId
      ? await getViewsByCampaignId({ storyId, campaignId, dateRange, direction, sortingKey })
      : await getViewsByStoryId({ storyId, dateRange, direction, sortingKey });

    const contactsList = result.items.map((item, index) => ({
      'No.': ++index,
      Date: formatDate(item.dateCreated, 'MM-DD-YYYY'),
      Time: formatDate(item.dateCreated, 'hh:mm:ss a'),
      'Campaign Name': item.campaignName,
      Name: item.submissionName || getContactFullName(item),
      'Phone Number': item.submissionEmail || item.contactEmail,
      'Email Address': item.submissionPhone || item.contactPhone,
      Source: item.source,
      Medium: item.medium,
      Campaign: item.campaignName,
      Converted: item.isConverted ? 'Yes' : 'No',
    }));

    parseAndDownloadCsv(contactsList, 'unique_session_report');
  } catch (e) {
    addNotification(DEFAULT_ERROR);
  }
}

export function getStatsByStoryId(storyId, dateRange) {
  return postCall('/api/clientSession/getStatsByStoryId', { storyId, dateRange });
}

export function getStatsByCampaignId(storyId, campaignId, dateRange) {
  return postCall('/api/clientSession/getStatsByCampaignId', { storyId, campaignId, dateRange });
}

const convertContentItem = (item) => ({
  ...item,
  params: JSON.parse(item.params),
});

export function getContactsByStoryId({
  storyId,
  dateRange,
  direction,
  sortingKey,
  pageNumber,
  pageSize,
}) {
  return postCall('/api/clientSession/getContactsByStoryId', {
    storyId,
    dateRange,
    direction,
    sortingKey,
    pageNumber,
    pageSize,
  }).then((res) => ({
    items: (res?.items || []).map(convertContentItem),
    total: res.total,
  }));
}

export function getContactsByUserId({
  userId,
  dateRange,
  direction,
  sortingKey,
  pageNumber,
  pageSize,
}) {
  return postCall('/api/clientSession/getContactsByUserId', {
    userId,
    dateRange,
    direction,
    sortingKey,
    pageNumber,
    pageSize,
  }).then((res) => ({
    items: (res?.items || []).map(convertContentItem),
    total: res.total,
  }));
}

export async function downloadContactsList({
  storiesOptions,
  userId,
  storyId,
  dateRange,
  direction,
  sortingKey,
  addNotification,
}) {
  try {
    const result =
      storyId === 'all'
        ? await getContactsByUserId({
            userId,
            dateRange,
            direction,
            sortingKey,
          })
        : await getContactsByStoryId({
            storyId,
            dateRange,
            direction,
            sortingKey,
          });

    const contactsList = result.items.map((item, index) => ({
      'No.': ++index,
      Date: formatDate(item.dateCreated, 'MM-DD-YYYY'),
      Time: formatDate(item.dateCreated, 'hh:mm:ss a'),
      Name: item.name,
      'Phone Number': item.phoneNumber,
      'Email Address': item.email,
      Source: item.params?.source,
      Medium: item.params?.medium,
      Campaign: item.campaignName,
      Story: getStoryName(storiesOptions, item.storyId),
    }));

    parseAndDownloadCsv(contactsList, 'contacts_report');
  } catch (e) {
    addNotification(DEFAULT_ERROR);
  }
}

export function getEmailStatsByCampaignId(campaignId, dateRange) {
  return postCall('/api/clientSession/getEmailStatsByCampaignId', { campaignId, dateRange });
}

export function getSentLettersByCampaignId({
  campaignId,
  dateRange,
  direction,
  sortingKey,
  pageNumber,
  pageSize,
}) {
  return postCall('/api/clientSession/getSentLettersByCampaignId', {
    campaignId,
    dateRange,
    direction,
    sortingKey,
    pageNumber,
    pageSize,
  }).then((res) => ({
    items: (res?.items || []).map(convertContentItem),
    total: res?.total,
  }));
}

export async function downloadSentLettersList({
  campaignId,
  dateRange,
  direction,
  sortingKey,
  addNotification,
}) {
  try {
    const result = await getSentLettersByCampaignId({
      campaignId,
      dateRange,
      direction,
      sortingKey,
    });

    const contactsList = result.items.map((item, index) => ({
      'No.': ++index,
      Date: formatDate(item.dateCreated, 'MM-DD-YYYY'),
      Time: formatDate(item.dateCreated, 'hh:mm:ss a'),
      Name: item.firstName + ' ' + item.lastName,
      'Phone Number': item.phoneNumber,
      'Email Address': item.email,
      Source: item.params?.source,
      Medium: item.params?.medium,
    }));

    parseAndDownloadCsv(contactsList, 'sent_letters_report');
  } catch (e) {
    addNotification(DEFAULT_ERROR);
  }
}
