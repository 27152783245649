import { getTimestamp } from 'utils/date';
import { getStaticResourceUrl } from 'utils/common';
import appConfig from '../app-config';

// contentType = 'video/mp4'
export function isVideo(contentType) {
  if (!contentType) {
    return false;
  }

  const parts = contentType.split('/');

  return parts[0] === 'video';
}

export function getPreviewFileSrc(file) {
  return new Promise((resolve, reject) => {
    if (typeof FileReader === 'undefined') {
      reject(new Error('Not supported'));
    } else {
      const reader = new FileReader();

      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    }
  });
}

export function getFileKey(iUser, name) {
  const prefix = appConfig.isProdEnv ? 'prod' : 'dev';

  return `${prefix}/${iUser}/${getSafeName(name)}`;
}

export function getSafeName(name) {
  const timestamp = getTimestamp();
  // allow only safe symbols
  // https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
  const escapedName = name.replace(/[^0-9a-zA-Z-_]/g, '');

  return `${escapedName}-${timestamp}`;
}

export function getFileName(file) {
  if (!file || !file.name) {
    return '';
  }

  const fileName = file.name;
  // lets cut off extension
  const indexOfExtension = fileName.lastIndexOf('.');

  if (indexOfExtension === -1) {
    return '';
  }

  return fileName.substring(0, indexOfExtension);
}

export function getImagePath(s3Key) {
  return `https://media.panels.ai/public/${s3Key}`;
}

export const IMAGE_SIZES = {
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  ORIGINAL: 'ORIGINAL',
};

export const IMAGE_SIZES_MAP = {
  S: '420',
  M: '640',
  L: '1200',
  XL: '2048',
  ORIGINAL: '000',
};

const MB_IN_B = 1024 * 1024;
const MAX_IMAGE_FILE_SIZE = 25 * MB_IN_B;
const MAX_VIDEO_FILE_SIZE = 100 * MB_IN_B;

export function isUnsplashImage(path) {
  return /images\.unsplash\.com/.test(path);
}

export function isInternalResource(path) {
  return path.startsWith('/images/') || path.startsWith(getStaticResourceUrl());
}

export function getImageThumbnail(path, size = 'L') {
  if (!path) {
    return path;
  }

  if (isUnsplashImage(path) || isInternalResource(path)) {
    return path;
  }

  const resolution = IMAGE_SIZES_MAP[size] || IMAGE_SIZES_MAP.L;
  return `${path}_thumbnail_${resolution}`;
}

function isImageType(contentType) {
  return /image/.test(contentType);
}

export function isVideoType(contentType) {
  return /video/.test(contentType);
}

export function isValidFileType(file, supportedContentTypes) {
  if (!file || !file.type) return false;

  const fileType = file.type;

  const splitedTypes = fileType.split('/'); // image/png => image text/cvs => text
  const groupType = splitedTypes && splitedTypes[0];

  return groupType && supportedContentTypes.includes(groupType);
}

export function isValidMediaFileSizes(file) {
  if (!file || !file.type || !file.size) {
    return false;
  }

  if (isImageType(file.type)) {
    return file.size < MAX_IMAGE_FILE_SIZE;
  } else if (isVideoType(file.type)) {
    return file.size < MAX_VIDEO_FILE_SIZE;
  }

  return file.size < MAX_VIDEO_FILE_SIZE;
}

export const ALL_MEDIA_TYPES = 'image/*,video/mp4,video/x-m4v,video/quicktime,video/*';
