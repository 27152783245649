import React from 'react';
import { IUser } from 'models';


export const SignedInUserContext = React.createContext<IUser>({
  username: '',
  attributes: {
    sub: '',
    email: '',
  },
});
