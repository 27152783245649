import { COLOR_PRIMARY, COLOR_SECONDARY } from './theme';

export const FONT_FAMILIES = [
  { value: "'Roboto', sans-serif", label: 'Roboto' },
  { value: "'Merriweather', serif", label: 'Merriweather' },
  { value: "'Raleway', sans-serif", label: 'Raleway' },
  { value: "'Playfair Display', serif", label: 'Playfair Display' },
];

export const BTN_STYLES = [
  { value: '0', label: 'Square' },
  { value: '30px', label: 'Rounded' },
];

export const BTN_COLORS = {
  PRIMARY: COLOR_PRIMARY,
  SECONDARY: COLOR_SECONDARY,
};
