import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from './useUserId';
import { getSubscriptionUsageByUserId } from 'services/v2/subscriptions';
import { ISubscriptionSummary } from 'models';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';

const defaultSummary = {
  story: { usedQuantity: 0, quantityToUse: 0 },
  email: { usedQuantity: 0, quantityToUse: 0 },
  sms: { usedQuantity: 0, quantityToUse: 0 },
}
export function useSubscriptionUsage(props?: { showStatistic?: boolean }) {
  const isStatisticNeeded = props?.showStatistic;
  const isDetached = useRef(false);
  const [summary, setSummary] = useState<ISubscriptionSummary>(defaultSummary as ISubscriptionSummary);
  const [isLoading, setIsLoading] = useState(false);

  const updateSummary = (sumObj: ISubscriptionSummary) => {
    setSummary(Object.assign({ ...defaultSummary }, sumObj));
  };

  const userId = useUserId();
  const dispatch = useDispatch();

  const onErrorHandler = useCallback((error) => {
    console.error(error);
    if (isDetached.current) return;

    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [isDetached, dispatch]);

  const loadSubscriptions = useCallback(
    () => {
      setIsLoading(true);
      getSubscriptionUsageByUserId({ userId, isStatisticNeeded })
        .then((response: ISubscriptionSummary) => {
          if (isDetached.current) {
            return;
          }

          setIsLoading(false);
          updateSummary(response);
        })
        .catch((error) => onErrorHandler(error));
    }, [userId, isStatisticNeeded, isDetached, onErrorHandler]
  )


  useEffect(() => {
    if (userId) {
      loadSubscriptions();
    }

    return () => {
      isDetached.current = true;
    }
  }, [userId, loadSubscriptions]);

  return { summary, isLoading };
}
