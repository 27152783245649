import React, { ButtonHTMLAttributes } from 'react';
import { Button } from 'components/_common';
import classNames from 'classnames';

import './styles.scss';

interface IDownloadButton {
  className?: string;
  disabled?: boolean;
  onClick?: Function;
}

export function DownloadButton({ className, disabled, onClick }: ButtonHTMLAttributes<HTMLButtonElement>) {
  const compClass = classNames(className, 'margin-left', 'download-button');

  return (
    <Button className={compClass} type="button" isPrimary disabled={disabled} onClick={onClick}>
      Download
    </Button>
  );
}
