import { useDispatch } from 'react-redux';
import { useCallback, useState, useEffect, useRef } from 'react';
import { useUserId } from 'hooks';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { deleteCampaignFlowTemplate, getTemplatesByUserId } from 'services/flowBuilder';
import { TCampaignFlowTemplate } from 'models/CampaignFlowBuilder/TCampaignFlowTemplate';

export const useCampaignFlowTemplates = () => {

  const isDetached = useRef(false);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<TCampaignFlowTemplate[]>([]);
  const dispatch = useDispatch();

  const loadTemplates = useCallback(async () => {
    if (isDetached.current) return;

    setIsLoading(true);

    try {
      const res = await getTemplatesByUserId({ iUser: userId });
      setTemplates(res.templates);
    }
    catch (error) {
      dispatch(addNotification(DEFAULT_ERROR));
    }
    finally {
      setIsLoading(false);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates, userId, isDetached]);

  const deleteTemplate = useCallback(
    async ({ iCampaignFlowTemplate }: { iCampaignFlowTemplate: string }) => {
      if (isDetached.current) return;

      try {
        setIsLoading(true);
        await deleteCampaignFlowTemplate({ iCampaignFlowTemplate });
        await loadTemplates();

        dispatch(
          addNotification({
            type: 'success',
            message: 'Template deleted successfully.',
          })
        );
      }
      catch (error) {
        dispatch(addNotification(DEFAULT_ERROR));
      }
      finally {
        setIsLoading(false);
      }
    },
    [loadTemplates, dispatch, isDetached],
  )

  return {
    isLoading,
    templates,
    deleteTemplate,
  }
}