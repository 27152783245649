import React from 'react';
import classNames from 'classnames';
import { isValidFileType, isValidMediaFileSizes } from 'utils/media';
import './styles.scss';

export default class InputFile extends React.Component {
  static defaultProps = {
    id: 'file-upload',
    label: 'Upload Media',
  };

  handleFileChange = (event) => {
    const files = event.target.files;
    const isValidType = isValidFileType(files[0], this.props.accept);
    const isValidSize = isValidMediaFileSizes(files[0]);

    // TODO: validate file with this.props.accept attribute
    if (isValidType && isValidSize) {
      this.props.onChange((files && files[0]) || undefined);
    } else {
      event.target.value = null;
      const errorMsg = !isValidType
        ? 'This file type is not supported. Please select a different one'
        : 'This file is too big. Image file should be up to 25 MB. Video file is up to 100 MB';
      this.props.onError && this.props.onError(errorMsg);
    }
  };

  resetFileInput = (event) => {
    // https://stackoverflow.com/a/40429197/1022726
    event.target.value = null;
  };

  render() {
    const className = classNames(
      this.props.className,
      'btn btn-primary btn-upload-media input-file-label',
      {
        disabled: this.props.disabled,
      }
    );

    return (
      <React.Fragment>
        <input
          className="input-file-input"
          type="file"
          id={this.props.id}
          accept={this.props.accept}
          required={this.props.required}
          disabled={this.props.disabled}
          onClick={this.resetFileInput}
          onChange={this.handleFileChange}
        />
        <label htmlFor={this.props.id} className={className}>
          {this.props.label}
        </label>
      </React.Fragment>
    );
  }
}
