import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export type TSelectOption = {
  value: string | number;
  label: string;
}

export type TSelectProps<T> = {
  options?: T[];
  includeEmpty?: boolean;
  hideArrow?: boolean;
} & React.SelectHTMLAttributes<HTMLSelectElement>;


export const Select = <T extends TSelectOption>({
  className,
  hideArrow,

  includeEmpty = false,
  options = [],
  ...props
}: TSelectProps<T>) => {

  const compClass = classNames(className, 'custom-select', {
    'custom-select--no-arrow': hideArrow,
  });

  return (
    <select
      className={compClass}
      {...props}
    >
      {includeEmpty && <option value="">Select</option>}

      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
