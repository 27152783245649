import { useLocation } from 'react-router-dom';

// Usage example
// const query = useQuery();
// query.get("name")
export function useQueryParams() {
  const location = useLocation();

  return new URLSearchParams(location?.search);
}
