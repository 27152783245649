import './styles.scss';
import React from 'react';
import { IconName, IconNames } from '@blueprintjs/icons';
import { Label, InputMedia, IconButtonGroup } from 'components/_common';
import { IStoryMediaFile } from 'models';
import { IBackground } from 'models';
import { VERTICAL_POSITION, HORIZONTAL_POSITION, COVER_SETTINGS } from 'constants/story';
import { isVideoType } from 'utils/media';

const horizontalPositions = [
  { value: HORIZONTAL_POSITION.LEFT, icon: IconNames.ALIGNMENT_LEFT as IconName },
  { value: HORIZONTAL_POSITION.CENTER, icon: IconNames.ALIGNMENT_VERTICAL_CENTER as IconName },
  { value: HORIZONTAL_POSITION.RIGHT, icon: IconNames.ALIGNMENT_RIGHT as IconName },
];
const verticalPositions = [
  { value: VERTICAL_POSITION.TOP, icon: IconNames.ALIGNMENT_TOP as IconName },
  { value: VERTICAL_POSITION.CENTER, icon: IconNames.ALIGNMENT_HORIZONTAL_CENTER as IconName },
  { value: VERTICAL_POSITION.BOTTOM, icon: IconNames.ALIGNMENT_BOTTOM as IconName },
];
const containerTypes = [
  { value: COVER_SETTINGS.COVER, icon: IconNames.FULLSCREEN as IconName },
  { value: COVER_SETTINGS.CONTAIN, icon: IconNames.MINIMIZE as IconName },
];

export const Background = ({
  background,
  onError,
  onFileChange,
  onBackgroundPropChange,
  onRemove,
  onPreloadImageChange,
  onPreloadImageRemove,
}: {
  background: IBackground,
  mediaFile?: IStoryMediaFile;
  onError: (error: string) => void;
  onFileChange: (file: IStoryMediaFile) => void;
  onPreloadImageChange: (file: IStoryMediaFile) => void;
  onBackgroundPropChange: (vertical: string, horizontal: string, container: string) => void;
  onRemove: () => void;
  onPreloadImageRemove: () => void;
}) => {
  const {
    bgContentType = '',
    bgPosterName = ''
  } = background;

  const { bgName = '', verticalPosition, horizontalPosition, containerFit } = background || {};

  const isVideoBackground = isVideoType(bgContentType);

  const onHorizontalSelected = (value: string) => {
    onBackgroundPropChange(verticalPosition, value, containerFit);
  }
  const onVerticalSelected = (value: string) => {
    onBackgroundPropChange(value, horizontalPosition, containerFit);
  }
  const onContainerSelected = (value: string) => {
    onBackgroundPropChange(verticalPosition, horizontalPosition, value);
  }



  return (
    <>
      <div className="block-group-highlighted padding-top padding-bottom padding-left-right--sm margin-bottom">
        <div className="margin-bottom">
          <Label htmlFor="step1-background">Background</Label>
          <InputMedia
            id="step1-background"
            accept="image/*,video/mp4,video/x-m4v,video/quicktime,video/*"
            value={bgName}
            onError={onError}
            onChange={onFileChange}
            onRemove={onRemove}
          />
        </div>

        <div className="background__toolbar padding-top--xs display-flex">
          <IconButtonGroup
            buttonList={horizontalPositions}
            value={horizontalPosition || HORIZONTAL_POSITION.CENTER}
            onSelect={onHorizontalSelected}
          />
          <div className="inline-divider" />
          <IconButtonGroup
            buttonList={verticalPositions}
            value={verticalPosition || VERTICAL_POSITION.CENTER}
            onSelect={onVerticalSelected}
          />
          <div className="inline-divider" />
          <IconButtonGroup
            buttonList={containerTypes}
            value={containerFit || COVER_SETTINGS.COVER}
            onSelect={onContainerSelected}
          />
        </div>
      </div>

      {isVideoBackground && (
    <div className="margin-bottom">
      <Label htmlFor="step2-background-poster">Video Preload Image</Label>

      <InputMedia
        id="step2-background-poster"
        accept="image/*"
        value={bgPosterName}
        onError={onError}
        onChange={onPreloadImageChange}
        onRemove={onPreloadImageRemove}
      />
    </div>
  )}
    </>
  )
}