export type TNotificationData = {
  id?: string,
  type?: 'error' | 'warn' | 'success' | 'info',
  message: string,
};

export type NotificationAction = {
  type: string;
  id?: string,
  nType?: 'error' | 'warn' | 'success' | 'info',
  message: string,
};

export type DispatchNotification = (args: TNotificationData) => NotificationAction

const initState = {
}

type NotificationState = typeof initState | TNotificationData;


export const addNotification: DispatchNotification = (data: TNotificationData) => {
  return {
    type: 'NOTIFICATION/ADD',
    id: data.id,
    nType: data.type,
    message: data.message,
  };
}

export type AddNotification = typeof addNotification;

export function removeNotification() {
  return {
    type: 'NOTIFICATION/REMOVE',
  };
}

export default function notification(state: NotificationState = initState, action: ReturnType<DispatchNotification>) {
  switch (action.type) {
    case 'NOTIFICATION/ADD':
      return {
        id: action.id,
        type: action.nType,
        message: action.message,
      };

    case 'NOTIFICATION/REMOVE':
      return {};

    default:
      return state;
  }
}
