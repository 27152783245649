import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getUserInfo } from 'services/user';
import { IUserRecord } from 'models';

type Status = 'init' | 'loading' | 'loaded' | 'error';

export const useUserInfo = () => {
  const isDetached = useRef(false); // unmounted
  const userId = useUserId();

  const [status, setStatus] = useState<Status>('init');
  const [userInfo, setUserInfo] = useState<IUserRecord | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId || isDetached.current) {
      return;
    }

    setStatus('loading');
    getUserInfo(userId)
      .then((user: IUserRecord) => {
        if (!isDetached.current) {
          setStatus('loaded');
          setUserInfo(user);
        }
      })
      .catch((error) => {
        if (!isDetached.current) {
          console.error('[useUserInfo]', error);
          setStatus('error');
          dispatch(addNotification(DEFAULT_ERROR));
        }
      });

    return () => {
      isDetached.current = true;
    };
  }, [userId, dispatch]);

  return {
    userInfo,
    status,
  };
};
