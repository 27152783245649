import React from 'react';
import { Spinner as CoreSpinner, Intent } from '@blueprintjs/core';

export function Spinner({
  className = '',
  size = 50,
}: {
  className?: string;
  size?: number;
}) {
  return <CoreSpinner className={className} intent={Intent.PRIMARY} size={size}/>;
}
