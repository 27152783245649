import React from 'react';
import { getUserMediaFiles, deleteMediaFileFromDB, deleteMediaFileFromS3 } from 'services/media';
import { isVideo, getImageThumbnail, IMAGE_SIZES } from 'utils/media';
import { getOffsetLimitByPage } from 'utils/common';
import { Paging, Section, Modal, Button } from 'components/_common';
import { MediaTile } from '../MediaTile';
import { MEDIA_PAGE_SIZES } from 'constants/campaign';

export class ExistingMedia extends React.Component {
  static defaultProps = {
    accept: '',
  };

  state = {
    mediaFiles: [],
    selectedFile: undefined,
    pageSize: MEDIA_PAGE_SIZES[0].value,
    currentPage: 0,
    totalCount: 0,
    isLoading: false,
  };

  getMediaFiles = () => {
    const { pageSize, currentPage } = this.state;

    this.setState({ isLoading: true });

    getUserMediaFiles({
      userId: this.props.userId,
      ...getOffsetLimitByPage(pageSize, currentPage),
    })
      .then(this.setMediaFiles)
      .catch((error) => console.error(error))
      .finally(() => this.setState({ isLoading: false }));
  };

  setMediaFiles = ({ total, files: mediaFiles }) => {
    const { accept } = this.props;
    const files = filterMediaByAcceptType(mediaFiles, accept);
    const thumbnails = files.map((file) => ({
      ...file,
      thumbUrl: isVideo(file.contentType) ? file.url : getImageThumbnail(file.url, IMAGE_SIZES.S),
    }));

    this.setState({
      mediaFiles: thumbnails,
      totalCount: total,
    });
  };

  onNextClickHandler = () => {
    const { currentPage } = this.state;
    const newCurrentPage = currentPage + 1;

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      this.getMediaFiles
    );
  };

  onPrevClickHandler = () => {
    const { currentPage } = this.state;
    const newCurrentPage = Math.max(currentPage - 1, 0);

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      this.getMediaFiles
    );
  };

  onPageSizeChangeHandler = (pageSize) => {
    this.setState(
      {
        pageSize: parseInt(pageSize, 0),
        currentPage: 0,
        isLoading: true,
      },
      this.getMediaFiles
    );
  };

  onDeleteHandler = (file) => {
    const isOk = window.confirm('Are you sure?');
    if (!isOk) return;

    this.setState({ isLoading: true }, () => {
      deleteMediaFileFromDB({
        userId: this.props.userId,
        file,
      })
        .then(() => deleteMediaFileFromS3(file))
        .catch((error) => console.error(error))
        .finally(this.getMediaFiles);
    });
  };

  componentDidMount() {
    this.getMediaFiles();
  }

  render() {
    const {
      isLoading,
      mediaFiles = [],
      currentPage,
      totalCount,
      pageSize,
      selectedFile,
    } = this.state;

    return (
      <Section isLoading={isLoading}>
        <div className="media-modal__body">
          {!mediaFiles.length ? (
            <div className="text-center stretch-block">No media files</div>
          ) : (
            mediaFiles.map((mediaFile) => (
              <MediaTile
                key={mediaFile.id}
                mediaFile={mediaFile}
                isSelected={selectedFile?.id === mediaFile.id}
                onSelect={() => this.setState({ selectedFile: mediaFile })}
                onChooseMedia={() => this.props.onSave(mediaFile)}
                onDelete={() => this.onDeleteHandler(mediaFile)}
              />
            ))
          )}
        </div>

        <Modal.Footer className="media-modal__footer">
          <Paging
            className="media-modal__paging"
            labelText="Files per page:"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            options={MEDIA_PAGE_SIZES}
            onPageSizeChange={this.onPageSizeChangeHandler}
            onPrevClick={this.onPrevClickHandler}
            onNextClick={this.onNextClickHandler}
          />
          <div>
            <Button
              className="btn-grey margin-right--sm"
              type="button"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>

            <Button
              isPrimary
              type="button"
              onClick={() => {
                if (selectedFile) {
                  this.props.onSave(selectedFile);
                }
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Section>
    );
  }
}

function filterMediaByAcceptType(files, accept) {
  if (!Array.isArray(files)) {
    return [];
  }

  if (!accept) {
    return files;
  }

  return files.filter((file) => {
    if (!file || !file.contentType) {
      return false;
    }

    // e.g. accept = image/*,video/mp4,video/x-m4v,video/quicktime,video/*
    // file.contentType = video/mp4 | image/gif

    const type = isVideo(file.contentType) ? 'video' : 'image';

    return accept.indexOf(type) !== -1;
  });
}
