import React from 'react';

export default function Video({ className, style, src, poster }) {
  return (
    <video
      className={className}
      style={style}
      src={src}
      poster={poster}
      playsInline
      autoPlay
      muted
      loop
    />
  );
}
