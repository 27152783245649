import { STRIPE_TYPES } from '../constants';
import { IStripeProduct, IStripeSubscription } from 'models';

export function getDefaultPaymentMethod(customer: any) {
  return customer?.invoice_settings?.default_payment_method || null;
}

// Return first subscription.
// For now each customer has 1 subscription
export function getSubscriptionId(customer: any) {
  return customer?.subscriptions?.data?.[0]?.id;
}

export function getSubscriptionItems(subscription: IStripeSubscription) {
  return subscription?.items?.data;
}

export function getLatestPaymentIntent(subscription: IStripeSubscription) {
  if (!subscription) {
    return false;
  }

  if (subscription.status === 'active') {
    return false;
  }

  return subscription?.latest_invoice?.payment_intent;
}

export const sortProductsByTypes = (prdA: IStripeProduct, prdB: IStripeProduct) => {
  if (prdA?.metadata.type === STRIPE_TYPES.PRODUCT && !prdB?.metadata.type) {
    return -1;
  }

  if (!prdA?.metadata.type && prdB?.metadata.type === STRIPE_TYPES.PRODUCT) {
    return 1;
  }

  if (prdA?.metadata.story > prdB?.metadata.story) {
    return -1;
  }

  if (prdA?.metadata.story < prdB?.metadata.story) {
    return 1;
  }

  if (prdA?.updated > prdB?.updated) {
    return -1;
  }
  if (prdA?.updated < prdB?.updated) {
    return 1;
  }

  return 0;
};

export const sortProductsByPrice = (prdA: IStripeProduct, prdB: IStripeProduct) => {
  return prdB?.price.unit_amount - prdA?.price.unit_amount;
};

export const sortProductsByOrderAndPrice = (prdA: IStripeProduct, prdB: IStripeProduct) => {

  if (prdA?.metadata.order < prdB?.metadata.order) {
    return -1;
  }
  if (prdA?.metadata.order > prdB?.metadata.order) {
    return 1;
  }

  return prdB?.price.unit_amount - prdA?.price.unit_amount;
};

export const checkIsProduct = (product: IStripeProduct) => product?.metadata.type === STRIPE_TYPES.PRODUCT;
export const checkIsAddon = (product: IStripeProduct) => product?.metadata.type !== STRIPE_TYPES.PRODUCT;
