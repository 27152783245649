import { useMemo } from 'react';
import { getStoryById } from 'services/story';
import { useUserId } from './useUserId';
import { useFetch } from './useFetch';

export function useStory(storyId) {
  const userId = useUserId();

  const memoizedPromise = useMemo(() => getStoryById(storyId, userId), [storyId, userId]);
  const { data, isLoading } = useFetch(memoizedPromise);

  return { story: data, isLoading };
}
