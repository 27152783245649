import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { LoadingTopBar } from 'components/_common';
import ROUTES from 'constants/routes';
import { gaSet, gaPageView } from 'utils/googleAnalytics';
import { SignedInUserContext } from 'utils/context';
import { useAuthUser } from 'hooks';

const Dashboard = React.lazy(() => import('components/Dashboard'));

// Create Flow
const StoryList = React.lazy(() => import('components/CreateFlow/StoryList/container'));
const Profile = React.lazy(() => import('components/Profile'));
const PickFont = React.lazy(() => import('components/CreateFlow/PickFont'));
const ChooseTemplate = React.lazy(() => import('components/CreateFlow/ChooseTemplate/container'));
const FlowBuilder = React.lazy(() => import('components/CreateFlow/FlowBuilder'));
const StepWelcome = React.lazy(() => import('components/CreateFlow/StepWelcome'));
const StepSplashVideo = React.lazy(() => import('components/CreateFlow/StepSplashVideo'));
const StepOptionPicker1 = React.lazy(() => import('components/CreateFlow/StepOptionPicker1'));
const StepOptionPicker2 = React.lazy(() => import('components/CreateFlow/StepOptionPicker2'));
const StepContactForm = React.lazy(() => import('components/CreateFlow/StepContactForm'));
const StepSchedule = React.lazy(() => import('components/CreateFlow/StepSchedule'));
const StepThankYou = React.lazy(() => import('components/CreateFlow/StepThankYou'));

const StepTable = React.lazy(() => import('components/CreateFlow/StepTable'));
const StepStore = React.lazy(() => import('components/CreateFlow/StepStore'));
const MetaBuilder = React.lazy(() => import('components/CreateFlow/MetaBuilder'));

// Campaign Flow
const ScheduleSuccess = React.lazy(() => import('components/Campaign/ScheduleSuccess'));
const Schedule = React.lazy(() => import('components/Campaign/Schedule/container'));
const ScheduleDelivery = React.lazy(() => import('components/Campaign/ScheduleDelivery'));
const SmsCampaign = React.lazy(() => import('components/Campaign/SmsCampaign'));
const EmailTemplate = React.lazy(() => import('components/Campaign/EmailTemplate/container'));
const EmailBuilder = React.lazy(() => import('components/Campaign/EmailBuilder/container'));
const EmailParams = React.lazy(() => import('components/Campaign/EmailParams/container'));
const SenderChannel = React.lazy(() => import('components/Campaign/Channel'));
const CampaignFlowBuilder = React.lazy(() => import('components/Campaign/FlowBuilder/container'));
const CampaignFlowBuilderEmailTemplates = React.lazy(() => import('components/Campaign/FlowBuilderEmailTemplate/container'));
const CampaignFlowBuilderEmailBuilder = React.lazy(() => import('components/Campaign/FlowBuilderEmailBuilder/container'));
const CampaignFlowBuilderEmailParams = React.lazy(() => import('components/Campaign/FlowBuilderEmailParams/container'));
const CampaignFlowBuilderSms = React.lazy(() => import('components/Campaign/FlowBuilderSms/container'));
const CampaignFlowBuilderSuccess = React.lazy(() => import('components/Campaign/FlowBuilderSuccess'));
const CampaignFlowBuilderTemplates = React.lazy(() => import('components/Campaign/FlowBuilderTemplates/container'));

// Campaign Edit Flow
const EditCampaignScheduleSuccess = React.lazy(() =>
  import('components/EditCampaign/EditCampaignScheduleSuccess')
);
const EditCampaignSchedule = React.lazy(() =>
  import('components/EditCampaign/EditCampaignSchedule/container')
);
const EditCampaignScheduleDelivery = React.lazy(() =>
  import('components/EditCampaign/EditCampaignScheduleDelivery')
);
const EditCampaignSmsCampaign = React.lazy(() =>
  import('components/EditCampaign/EditCampaignSmsCampaign')
);
const EditCampaignEmailTemplate = React.lazy(() =>
  import('components/EditCampaign/EditCampaignEmailTemplate/container')
);
const EditCampaignEmailBuilder = React.lazy(() =>
  import('components/EditCampaign/EditCampaignEmailBuilder/container')
);
const EditCampaignEmailParams = React.lazy(() =>
  import('components/EditCampaign/EditCampaignEmailParams/container')
);
const EditCampaignSenderChannel = React.lazy(() =>
  import('components/EditCampaign/EditCampaignChannel')
);
const EditCampaignFlowBuilder = React.lazy(() => import('components/EditCampaign/EditFlowBuilder/container'));
const EditCampaignFlowBuilderEmailTemplates = React.lazy(() => import('components/EditCampaign/EditFlowBuilderEmailTemplate/container'));
const EditCampaignFlowBuilderEmailBuilder = React.lazy(() => import('components/EditCampaign/EditFlowBuilderEmailBuilder/container'));
const EditCampaignFlowBuilderEmailParams = React.lazy(() => import('components/EditCampaign/EditFlowBuilderEmailParams/container'));
const EditCampaignFlowBuilderSms = React.lazy(() => import('components/EditCampaign/EditFlowBuilderSms/container'));
const EditCampaignFlowBuilderSuccess = React.lazy(() => import('components/EditCampaign/EditFlowBuilderSuccess'));
const ShowCampaignFlowBuilder = React.lazy(() => import('components/EditCampaign/ShowCampaignFlowBuilder/container'));

// Reports Flow
const ViewsReport = React.lazy(() => import('components/Reports/ViewsReport/container'));
const ContactsReport = React.lazy(() => import('components/Reports/ContactsReport/container'));
const EmailReport = React.lazy(() => import('components/Reports/EmailReport/container'));
const CampaignsReport = React.lazy(() => import('components/Reports/CampaignsReport/container'));
const Reports = React.lazy(() => import('components/Reports/container'));

// Contact List
const ContactListInfo = React.lazy(() => import('components/Contacts/ContactListInfo/container'));
const ContactLists = React.lazy(() => import('components/Contacts/ContactLists/container'));

// Login
const Login = React.lazy(() => import('components/LoginFlow/Login'));
const FederationLogin = React.lazy(() => import('components/LoginFlow/FederationLogin'));

// Sender
const MediaLib = React.lazy(() => import('components/MediaLib'));
const SenderType = React.lazy(() => import('components/SenderFlow/SenderType/container'));
const EmailTemplateSender = React.lazy(() => import('components/SenderFlow/EmailTemplateSender'));
const EmailBuilderSender = React.lazy(() => import('components/SenderFlow/EmailBuilderSender'));
const EmailParamsSender = React.lazy(() => import('components/SenderFlow/EmailParamsSender'));
const ScheduleSender = React.lazy(() => import('components/SenderFlow/ScheduleSender/container'));

const SmsParamsSender = React.lazy(() => import('components/SenderFlow/SmsParamsSender'));
const ScheduleDeliveryCalendar = React.lazy(() =>
  import('components/SenderFlow/ScheduleDeliveryCalendar/container')
);
const SenderFlowBuilder = React.lazy(() => import('components/SenderFlow/SenderFlowBuilder/container'));
const SenderFlowBuilderEmailTemplates = React.lazy(() => import('components/SenderFlow/SenderFlowBuilderEmailTemplate/container'));
const SenderFlowBuilderEmailBuilder = React.lazy(() => import('components/SenderFlow/SenderFlowBuilderEmailBuilder/container'));
const SenderFlowBuilderEmailParams = React.lazy(() => import('components/SenderFlow/SenderFlowBuilderEmailParams/container'));
const SenderFlowBuilderSms = React.lazy(() => import('components/SenderFlow/SenderFlowBuilderSms/container'));
const SenderFlowBuilderSuccess = React.lazy(() => import('components/SenderFlow/SenderFlowBuilderSuccess'));
const ScheduleDone = React.lazy(() => import('components/SenderFlow/ScheduleDone'));
const SenderFlowBuilderTemplates = React.lazy(() => import('components/SenderFlow/SenderFlowBuilderTemplates/container'));

const Storefront = React.lazy(() => import('components/Storefront'));
const Products = React.lazy(() => import('components/Storefront/Products'));
const Stores = React.lazy(() => import('components/Storefront/Stores'));
const StorePickFont = React.lazy(() => import('components/Storefront/Stores/StorePickFont'));
const StoreProducts = React.lazy(() => import('components/Storefront/Stores/StoreProducts'));
const StoreMetaBuilder = React.lazy(() => import('components/Storefront/Stores/StoreMetaBuilder'));
const ProductEdit = React.lazy(() => import('components/Storefront/Products/ProductEdit'));
const ProductNew = React.lazy(() => import('components/Storefront/Products/ProductNew'));
const Stats = React.lazy(() => import('components/Storefront/Stats'));

const NoMatch = React.lazy(() => import('components/NoMatch'));

const history = createBrowserHistory();

history.listen((location) => {
  gaSet({ page: location.pathname }); // Update the user's current page
  gaPageView(location.pathname); // Record a pageview for the given page
});

export default function AppRouter() {
  return (
    <Router history={history}>
      <Suspense fallback={<LoadingTopBar />}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Redirect
                to={{
                  pathname: ROUTES.DASHBOARD,
                  search: props?.location?.search,
                }}
              />
            )}
          />

          <PrivateRoute path={ROUTES.DASHBOARD} component={Dashboard} />

          {/* CREATE flow */}
          <PrivateRoute path={ROUTES.HOME} component={StoryList} />
          <PrivateRoute path={ROUTES.USER_PROFILE} component={Profile} />
          <PrivateRoute path={ROUTES.PICK_FONT} component={PickFont} />
          <PrivateRoute path={ROUTES.CHOOSE_TEMPLATE} component={ChooseTemplate} />
          <PrivateRoute path={ROUTES.FLOW_BUILDER} component={FlowBuilder} />
          <PrivateRoute path={ROUTES.STEP_WELCOME} component={StepWelcome} />
          <PrivateRoute path={ROUTES.STEP_SPLASH_VIDEO} component={StepSplashVideo} />
          <PrivateRoute path={ROUTES.STEP_OPTION_PICKER_1} component={StepOptionPicker1} />
          <PrivateRoute path={ROUTES.STEP_OPTION_PICKER_2} component={StepOptionPicker2} />
          <PrivateRoute path={ROUTES.STEP_CONTACT_FORM} component={StepContactForm} />
          <PrivateRoute path={ROUTES.STEP_SCHEDULE} component={StepSchedule} />
          <PrivateRoute path={ROUTES.STEP_THANK_YOU} component={StepThankYou} />
          <PrivateRoute path={ROUTES.STEP_OPTION_SELECTOR} component={StepTable} />
          <PrivateRoute path={ROUTES.STEP_STORE} component={StepStore} />
          <PrivateRoute path={ROUTES.META_BUILDER} component={MetaBuilder} />

          {/* Campaign Edit flow */}
          <PrivateRoute path={ROUTES.CAMPAIGN_EDIT_DONE} component={EditCampaignScheduleSuccess} />
          <PrivateRoute path={ROUTES.CAMPAIGN_EDIT_SCHEDULE} component={EditCampaignSchedule} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_SCHEDULE_DELIVERY}
            component={EditCampaignScheduleDelivery}
          />
          <PrivateRoute path={ROUTES.CAMPAIGN_EDIT_SMS} component={EditCampaignSmsCampaign} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_EMAIL_TEMPLATE}
            component={EditCampaignEmailTemplate}
          />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_EMAIL_BUILDER}
            component={EditCampaignEmailBuilder}
          />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_EMAIL_PARAMS}
            component={EditCampaignEmailParams}
          />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_FLOW_BUILDER_SMS}
            component={EditCampaignFlowBuilderSms} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_FLOW_BUILDER_EMAIL_TEMPLATE}
            component={EditCampaignFlowBuilderEmailTemplates} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_FLOW_BUILDER_EMAIL_BUILDER}
            component={EditCampaignFlowBuilderEmailBuilder} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_FLOW_BUILDER_EMAIL_PARAMS}
            component={EditCampaignFlowBuilderEmailParams} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_FLOW_BUILDER_SUCCESS}
            component={EditCampaignFlowBuilderSuccess} />
          <PrivateRoute
            path={ROUTES.SHOW_CAMPAIGN_FLOW_BUILDER}
            component={ShowCampaignFlowBuilder} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_FLOW_BUILDER}
            component={EditCampaignFlowBuilder} />
          <PrivateRoute
            path={ROUTES.CAMPAIGN_EDIT_CHANNEL}
            component={EditCampaignSenderChannel} />

          {/* Campaign flow */}
          <PrivateRoute path={ROUTES.CAMPAIGN_DONE} component={ScheduleSuccess} />
          <PrivateRoute path={ROUTES.CAMPAIGN_SCHEDULE} component={Schedule} />
          <PrivateRoute path={ROUTES.CAMPAIGN_SCHEDULE_DELIVERY} component={ScheduleDelivery} />
          <PrivateRoute path={ROUTES.CAMPAIGN_SMS} component={SmsCampaign} />
          <PrivateRoute path={ROUTES.CAMPAIGN_EMAIL_TEMPLATE} component={EmailTemplate} />
          <PrivateRoute path={ROUTES.CAMPAIGN_EMAIL_BUILDER} component={EmailBuilder} />
          <PrivateRoute path={ROUTES.CAMPAIGN_EMAIL_PARAMS} component={EmailParams} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_BUILDER_SMS} component={CampaignFlowBuilderSms} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_BUILDER_EMAIL_TEMPLATE} component={CampaignFlowBuilderEmailTemplates} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_BUILDER_EMAIL_BUILDER} component={CampaignFlowBuilderEmailBuilder} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_BUILDER_EMAIL_PARAMS} component={CampaignFlowBuilderEmailParams} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_BUILDER_SUCCESS} component={CampaignFlowBuilderSuccess} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_BUILDER} component={CampaignFlowBuilder} />
          <PrivateRoute path={ROUTES.CAMPAIGN_FLOW_TEMPLATES} component={CampaignFlowBuilderTemplates} />
          <PrivateRoute path={ROUTES.CAMPAIGN_CHANNEL} component={SenderChannel} />

          {/* Reports flow */}
          <PrivateRoute path={ROUTES.VIEWS_REPORT} component={ViewsReport} />
          <PrivateRoute path={ROUTES.CONTACTS_REPORT} component={ContactsReport} />
          <PrivateRoute path={ROUTES.EMAIL_REPORT} component={EmailReport} />
          <PrivateRoute path={ROUTES.CAMPAIGNS_REPORT} component={CampaignsReport} />
          <PrivateRoute path={ROUTES.REPORTS} component={Reports} />

          {/* Contact List */}
          <PrivateRoute path={ROUTES.CONTACT_LIST_INFO} component={ContactListInfo} />
          <PrivateRoute path={ROUTES.CONTACT_LISTS} component={ContactLists} />

          <RegularRoute path={ROUTES.LOGIN} component={Login} />
          <RegularRoute path={ROUTES.FEDERATION_LOGIN} component={FederationLogin} />

          {/* Sender */}
          <PrivateRoute path={ROUTES.MEDIA_LIB} component={MediaLib} />
          <PrivateRoute path={ROUTES.SENDER} component={SenderType} />
          <PrivateRoute path={ROUTES.SENDER_EMAIL_TEMPLATE} component={EmailTemplateSender} />
          <PrivateRoute path={ROUTES.SENDER_EMAIL_BUILDER} component={EmailBuilderSender} />
          <PrivateRoute path={ROUTES.SENDER_EMAIL_PARAMS} component={EmailParamsSender} />
          <PrivateRoute path={ROUTES.SENDER_SCHEDULER} component={ScheduleSender} />

          <PrivateRoute path={ROUTES.SENDER_SMS_PARAMS} component={SmsParamsSender} />
          <PrivateRoute path={ROUTES.SENDER_DELIVERY} component={ScheduleDeliveryCalendar} />
          <PrivateRoute path={ROUTES.SENDER_DONE} component={ScheduleDone} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER_SMS} component={SenderFlowBuilderSms} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER_EMAIL_TEMPLATE} component={SenderFlowBuilderEmailTemplates} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER_EMAIL_BUILDER} component={SenderFlowBuilderEmailBuilder} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER_EMAIL_PARAMS} component={SenderFlowBuilderEmailParams} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER_SUCCESS} component={SenderFlowBuilderSuccess} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER_TEMPLATES} component={SenderFlowBuilderTemplates} />
          <PrivateRoute path={ROUTES.SENDER_FLOW_BUILDER} component={SenderFlowBuilder} />

          <PrivateRoute path={ROUTES.STOREFRONT_PRODUCTS_NEW} component={ProductNew} />
          <PrivateRoute path={ROUTES.STOREFRONT_PRODUCTS_EDIT} component={ProductEdit} />
          <PrivateRoute path={ROUTES.STOREFRONT_PRODUCTS} component={Products} />
          <PrivateRoute path={ROUTES.STOREFRONT_STORES_DESIGN} component={StorePickFont} />
          <PrivateRoute path={ROUTES.STOREFRONT_STORES_PRODUCTS} component={StoreProducts} />
          <PrivateRoute path={ROUTES.STOREFRONT_STORES_META_BUILDER} component={StoreMetaBuilder} />
          <PrivateRoute path={ROUTES.STOREFRONT_STORES} component={Stores} />
          <PrivateRoute path={ROUTES.STOREFRONT_STATS} component={Stats} />
          <PrivateRoute path={ROUTES.STOREFRONT} component={Storefront} />

          <Route component={NoMatch} />
        </Switch>
      </Suspense>
    </Router>
  );
}

function RegularRoute({ component: Component, ...rest }: any) {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function PrivateRoute({ component: Component, ...rest }: any) {
  const authUser = useAuthUser();
  const isLoggedIn = authUser !== null;

  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;

        if (isLoggedIn) {
          return (
            <SignedInUserContext.Provider value={authUser as any}>
              <Component {...props} />
            </SignedInUserContext.Provider>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: ROUTES.LOGIN,
                state: { from: location },
                search: location.search,
              }}
            />
          );
        }
      }}
    />
  );
}
