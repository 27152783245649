import Storage from '@aws-amplify/storage';
import { postCall, deleteCall, getCall } from './service';
import { isVideo, getImageThumbnail, IMAGE_SIZES } from 'utils/media';

export function saveMediaFile(mediaItem) {
  return postCall('/api/media/save', mediaItem);
}

export function updateMediaFile({ userId, fileId, name, altText }) {
  return postCall('/api/media/updateMediaFile', { userId, fileId, name, altText });
}

export function getUserMediaFiles({ userId, offset, limit, filter = '' }) {
  return getCall('/api/media/getUserMediaFiles', { userId, offset, limit, filter });
}

export function deleteMediaFileFromDB({ userId, file }) {
  return deleteCall('/api/media/deleteMediaFileFromDB', {
    userId,
    fileId: file.id,
  });
}

export function deleteMediaFileFromS3(file) {
  const fileKey = file.s3Key;
  const filesToRemove = isVideo(file.contentType)
    ? []
    : Object.values(IMAGE_SIZES).map((size) => getImageThumbnail(fileKey, size));
  filesToRemove.push(fileKey);

  return Promise.all(filesToRemove.map((fileKey) => Storage.remove(fileKey)));
}
