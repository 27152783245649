import { applyChanges } from './utils';

export function storyUpdate(params) {
  return {
    type: 'STORY/UPDATE',
    params,
  };
}
export function prepareChartData(params) {
  return {
    type: 'STORY/UPDATE_SILENTLY',
    params,
  };
}

export function storyLoad(data) {
  return {
    type: 'STORY/LOAD',
    data,
  };
}

export default function story(state = {}, action) {
  switch (action.type) {
    case 'STORY/UPDATE_SILENTLY':
    case 'STORY/UPDATE':
      return applyChanges(state, action.params);
    case 'STORY/LOAD':
      return action.data || null;
    default:
      return state;
  }
}
