import { useCallback, useState, useEffect } from 'react';
import { getSentLettersByCampaignId, getEmailStatsByCampaignId } from 'services/clientSession';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { useUserId } from 'hooks';
import { getCampaignsByStoryId } from 'services/campaign';

function groupCards(reportCardArray, report) {
  return reportCardArray.map((i) => {
    const value = report[i.property];
    const percentage = i.showPercentage ? report[i.property + 'Percentage'] : null;

    return { ...i, value, percentage };
  });
}

export const useEmailReport = ({
  initPage = 0,
  initPageSize = 10,
  initSortingKey = '',
  reportCardArray,
}) => {
  const dispatch = useDispatch();

  const userId = useUserId();
  const [pageNumber, setPageNumber] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [filter, setFilter] = useState({});
  const [sortingOptions, setSortingOptions] = useState({
    direction: 'asc',
    sortingKey: initSortingKey,
  });

  const [isSentLettersLoading, setSentLettersIsLoading] = useState(false);
  const [sentLetters, setSentLetters] = useState(null);
  const [total, setTotal] = useState(null);

  const [isReportCardsLoading, setReportCardsLoading] = useState(false);
  const [reportCards, setReportCards] = useState(null);

  const [campaigns, setCampaigns] = useState(null);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false);

  const loadCampaigns = useCallback(async () => {
    setIsLoadingCampaigns(true);

    try {
      const result = await getCampaignsByStoryId({
        storyId: filter.selectedStoryId,
        userId,
        types: ['email'],
      });

      setCampaigns(result.items);
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setIsLoadingCampaigns(false);
    }
  }, [userId, filter.selectedStoryId, dispatch]);

  useEffect(() => {
    let cancelableCampaignsPromise;

    if (filter.selectedStoryId) {
      loadCampaigns(cancelableCampaignsPromise);
    }

    return () => {
      if (cancelableCampaignsPromise) {
        cancelableCampaignsPromise.cancel();
      }
    };
  }, [filter.selectedStoryId, loadCampaigns]);

  const loadSentLetters = useCallback(async () => {
    if (!filter.selectedCampaignId) return;

    setSentLettersIsLoading(true);

    try {
      const res = await getSentLettersByCampaignId({
        campaignId: filter.selectedCampaignId,
        dateRange: filter.dateRange,
        direction: sortingOptions.direction,
        sortingKey: sortingOptions.sortingKey,
        pageNumber,
        pageSize,
      });

      setSentLetters(res?.items);
      setTotal(res?.total);
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setSentLettersIsLoading(false);
    }
  }, [pageSize, pageNumber, filter, sortingOptions, dispatch]);

  useEffect(() => {
    loadSentLetters();
  }, [loadSentLetters, pageSize, pageNumber, filter, sortingOptions]);

  const loadReportCards = useCallback(async () => {
    if (!filter.selectedCampaignId) return;

    setReportCardsLoading(true);

    try {
      const report = await getEmailStatsByCampaignId(filter.selectedCampaignId);

      setReportCards(groupCards(reportCardArray, report));
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setReportCardsLoading(false);
    }
  }, [filter, reportCardArray, dispatch]);

  useEffect(() => {
    loadReportCards();
  }, [loadReportCards, pageSize, pageNumber, filter, sortingOptions]);

  return {
    isSentLettersLoading,
    sentLetters,
    total,
    filter,
    sortingOptions,
    pageNumber,
    campaigns,
    isLoadingCampaigns,
    pageSize,
    isReportCardsLoading,
    reportCards,
    setCampaigns,
    setPageNumber,
    setPageSize,
    setFilter,
    setSortingOptions,
  };
};
