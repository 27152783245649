import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from './useUserId';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { getFullCouponUsage } from 'services/v2/stripe';
import { IPromoCodeUsage } from 'models';

export const usePromoCodeStatistic = () => {
  const isDetached = useRef(false);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatistic] = useState<IPromoCodeUsage[] | null>(null);
  const dispatch = useDispatch();

  const onErrorHandler = useCallback((error) => {
    console.error('[usePromoCodeStatistic]', error);
    if (isDetached.current) return;

    setIsLoading(false);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [dispatch]);


  const getPromoCodeStatisticHandler = useCallback(() => {
    if (!userId) return;
    setIsLoading(true);

    getFullCouponUsage({ userId })
      .then((statistic: IPromoCodeUsage[]) => {
        if (isDetached.current) return null;

        setStatistic(statistic);
      })
      .catch(onErrorHandler)
      .finally(() => {
        if (isDetached.current) return;
        setIsLoading(false);
      });
  },
    [userId, onErrorHandler]
  );


  useEffect(() => {
    if (!isDetached.current) {
      getPromoCodeStatisticHandler();
    }


    return () => {
      isDetached.current = true;
    };
  }, [userId, getPromoCodeStatisticHandler]);

  return {
    isLoading,
    statistic,
  };
};
